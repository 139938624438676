export type PermissionTypes = "geolocation";

interface PermissionStatus {
  name?: string;
  state: "granted" | "prompt" | "denied";
}

export const checkPermission = (name: PermissionTypes) => {
  return new Promise(async (resolve, reject) => {
    try {
      const status: PermissionStatus = await navigator.permissions.query({
        name,
      });
      resolve(status.state === "granted");
    } catch (error) {
      resolve(false);
    }
  });
};

export const askPermission = (name: PermissionTypes) => {
  return new Promise(async (resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (r) => {
        resolve(true);
      },
      () => {
        resolve(false);
      }
    );
  });
};
