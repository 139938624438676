import {
  Center,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { collectNftGallery } from "../../shared/services/nft.service";
import { useToast } from "native-base";
import { translate } from "../../shared/i18n/translate";
import NftHomeImage from "./nft-home-image";
import { auth } from "../../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import Images from "../../assets/images";

interface NFTGallery {
  id: string;
  userId: string;
  network: string;
  address: string;
  status: string;
  created_at: string;
  updated_at: string;
  nftId: {
    id: string;
    imagePath: string;
    name: string;
    numberOfCopies: number;
    token: string;
    startDate: string;
    userId: string;
    locationLat: string;
    locationLong: string;
    description: string;
    numberOfCopiesToAvailable: number;
    numberOfCopiesToRequest: number;
    created_at: string;
    updated_at: string;
  };
}

const HomeCollect = () => {
  const [nfts, setNfts] = useState<NFTGallery[]>([]);
  const container = useRef();
  const navigate = useNavigate();
  const [height, setHeight] = useState<string>(`${300 * 1.4}px`);
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (auth.currentUser) {
      getNfts();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        getNfts();
      }
    });
    return unsubscriber();
  }, []);

  useEffect(() => {
    if (!container.current) return;
    const el: HTMLElement = (container.current as any).children[0];
    setHeight(`${el.getBoundingClientRect().width * 1.4}px`);
  }, [container.current]);

  const getNfts = async () => {
    try {
      setLoading(true);
      const response: any = await collectNftGallery(1000, 0);
      setNfts(response.data.usersNfts.nftList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.show({ title: translate.alerts.getNftsCollectedError });
    }
  };

  return loading ? (
    <ScrollView w="100%" h="calc(100vh - 110px)" marginTop="10px">
      <HStack
        flexGrow={1}
        flexWrap="wrap"
        paddingX="10px"
        justifyContent="center"
      >
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
      </HStack>
    </ScrollView>
  ) : nfts.length ? (
    <ScrollView w="100%" h="calc(100vh - 110px)" marginTop="10px">
      <HStack
        flexGrow={1}
        flexWrap="wrap"
        paddingX="10px"
        justifyContent="center"
        ref={container}
      >
        {nfts.map((nft, indexImg) => (
          <Pressable
            key={`img${indexImg}`}
            onPress={() => {
              navigate(`/nftpage/${nft.nftId.token}`);
            }}
            w="min(50%, 300px)"
            h="min(75vw, 400px)"
            p="5px"
          >
            <NftHomeImage
              image={nft.nftId.imagePath}
              name={nft.nftId.name}
              collected={nft?.nftId.numberOfCopiesToRequest}
              total={nft?.nftId.numberOfCopies}
              date={nft.created_at}
            />
          </Pressable>
        ))}
      </HStack>
    </ScrollView>
  ) : (
    <VStack
      h="calc(100vh - 350px)"
      justifyContent="center"
      space="70px"
      alignItems="center"
    >
      {/* <VStack>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.swipe1}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.swipe2}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.swipe3}
        </Text>
      </VStack>
      <Image
        source={{ uri: Images.arrowRight }}
        h="10px"
        w="80vw"
        maxW="300px"
      /> */}
      <Spacer />
      <VStack>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.line1}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.line2}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionCollect.line3}
        </Text>
      </VStack>
    </VStack>
  );
};

export default HomeCollect;
