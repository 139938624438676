import { onAuthStateChanged, updateProfile } from "firebase/auth";
import {
  Avatar,
  Box,
  Center,
  Divider,
  HStack,
  Image,
  Pressable,
  Text,
  VStack,
  Modal,
  Button,
  Spacer,
  Spinner,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import { auth, storage } from "../../firebase.config";
import ImagePicker from "../../shared/components/ImagePicker";
import SuppAndHelp from "../../shared/components/SuppAndHelp";
import { translate } from "../../shared/i18n/translate";
import { toFile, uploadCloudStorage } from "../../shared/services/file.service";
import {
  deleteToken,
  generateCryptoAddress,
  isCompletedKyc,
  savePhone,
  saveUserInfo,
} from "../../shared/services/user.service";
import { useToast } from "native-base";
import Header from "../../shared/components/Header";
import copyToClipboard from "../../shared/services/copyToClipboard.service";
import { UserPreferencesContext } from "../../contexts/user-preferences";

const Profile = () => {
  const useTranslate = translate.pages.profile;
  const navigate = useNavigate();
  const userPreferencesContext = useContext(UserPreferencesContext);
  const translateComponents = translate.components;
  const { state } = useLocation();
  const toast = useToast();
  const [incomplete, setIncomplete] = useState<boolean>(true);

  useEffect(() => {
    if (state && state.savePhone) {
      _savePhone();
    }
  }, [state]);

  const _savePhone = async () => {
    if (!auth.currentUser) return;
    savePhone(auth.currentUser.phoneNumber as string);
  };

  const editUser = () => {
    navigate("/personalinfo");
  };

  const getName = () => {
    if (
      userPreferencesContext.preferences &&
      userPreferencesContext.preferences.nickname
    ) {
      return userPreferencesContext.preferences.nickname;
    } else if (auth.currentUser?.displayName) {
      return auth.currentUser?.displayName;
    } else {
      return useTranslate.userName;
    }
  };

  return (
    <VStack
      h="100vh"
      justifyContent="space-between"
      alignItems="center"
      space="10px"
      maxH="700px"
    >
      <Header backAction={() => navigate("/")} />

      {/* AVATAR */}
      <Center w="100px" h="100px">
        <Avatar
          source={{
            uri: auth.currentUser?.photoURL
              ? auth.currentUser?.photoURL
              : Images.user,
          }}
          w="100px"
          h="100px"
        />
      </Center>

      {/* PROFILE INFO */}
      <VStack alignItems="center">
        <Text fontSize="22px" fontWeight="bold">
          {getName()}
        </Text>
        {/* <Text fontSize="14px" opacity="0.5">
          {auth.currentUser?.phoneNumber}
        </Text>
        <Text fontSize="14px">{auth.currentUser?.email}</Text> */}
      </VStack>

      <Spacer />

      {/* MENU */}
      <VStack
        w="calc(100vw - 50px)"
        maxW="500px"
        background="white"
        borderColor="#D9D9D9"
        borderWidth="1px"
        borderRadius="12px"
        paddingY="10px"
        paddingX="15px"
        space="8px"
      >
        {/* ACCOUNT */}
        <Pressable onPress={editUser}>
          <HStack alignItems="center">
            <Image
              source={{ uri: Images.settings }}
              alt={useTranslate.account}
              size="18px"
              marginRight="15px"
              alignItems="center"
            />
            <Text
              color={
                auth.currentUser?.phoneNumber === null ||
                auth.currentUser?.phoneNumber === undefined
                  ? "gray.500"
                  : "black"
              }
              fontSize="14px"
              lineHeight="14px"
              display="flex"
              alignItems="center"
            >
              {useTranslate.account}
            </Text>
            <Spacer />
            <Image source={{ uri: Images.chevronRight }} w="7px" h="10px" />
          </HStack>
        </Pressable>

        <Divider />

        {/* HELP */}
        <Pressable onPress={() => navigate("/help")}>
          <HStack alignItems="center">
            <Image
              source={{ uri: Images.faqIcon }}
              alt={useTranslate.help}
              size="18px"
              marginRight="15px"
            />
            <Text
              color="black"
              fontSize="14px"
              lineHeight="14px"
              display="flex"
              alignItems="center"
            >
              {useTranslate.help}
            </Text>
            <Spacer />
            <Image source={{ uri: Images.chevronRight }} w="7px" h="10px" />
          </HStack>
        </Pressable>

        <Divider />

        {/* PRIVACY */}
        <Pressable onPress={() => navigate("/privacypage")}>
          <HStack alignItems="center">
            <Image
              source={{ uri: Images.privacy }}
              alt={useTranslate.privacy}
              size="18px"
              marginRight="15px"
            />
            <Text
              color="black"
              fontSize="14px"
              lineHeight="14px"
              display="flex"
              alignItems="center"
            >
              {useTranslate.privacy}
            </Text>
            <Spacer />
            <Image source={{ uri: Images.chevronRight }} w="7px" h="10px" />
          </HStack>
        </Pressable>

        <Divider />

        {/* TERMS */}
        <Pressable onPress={() => window.open("/termsandcondition", "_blank")}>
          <HStack alignItems="center">
            <Image
              source={{ uri: Images.terms }}
              alt={useTranslate.termsAndCond}
              w="16px"
              h="18px"
              marginRight="15px"
            />
            <Text
              color="black"
              fontSize="14px"
              lineHeight="14px"
              display="flex"
              alignItems="center"
            >
              {useTranslate.termsAndCond}
            </Text>
            <Spacer />
            <Image source={{ uri: Images.chevronRight }} w="7px" h="10px" />
          </HStack>
        </Pressable>
      </VStack>

      <Spacer />

      {/* LOGOUT */}
      <Button
        rounded="50px"
        w="calc(100vw - 50px)"
        maxW="500px"
        variant="outline"
        onPress={() =>
          auth.signOut().then(() => {
            deleteToken();
            navigate("/");
          })
        }
      >
        <HStack space="10px" alignItems="center">
          <Text fontSize="16px" bold>
            {translate.pages.settings.logout}
          </Text>
          <Image source={{ uri: Images.logout }} w="20px" h="18px" />
        </HStack>
      </Button>

      {/* <Input
        type="text"
        w="100%"
        maxW="300px"
        size="md"
        placeholder={auth.currentUser?.displayName ?? useTranslate.name}
        accessibilityLabel={useTranslate.name}
        value={userName}
        onChangeText={(text) => setUserName(text)}
        isDisabled={!editingName}
        InputRightElement={
          editingName ? (
            <Pressable onPress={() => saveName()} paddingX={5}>
              <Text>{translate.components.save}</Text>
            </Pressable>
          ) : loadingName ? (
            <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
          ) : (
            <Pressable onPress={() => setEditingName(true)} paddingX={5}>
              <Image size="20px" source={{ uri: Images.edit }} />
            </Pressable>
          )
        }
      />
      <Input
        type="text"
        w="100%"
        maxW="300px"
        size="md"
        placeholder={auth.currentUser?.phoneNumber ?? useTranslate.phoneNumber}
        isDisabled={!editingPhone}
        value={userPhone}
        onChangeText={(text) => setUserPhone(text)}
        InputRightElement={
          editingPhone ? (
            <Pressable onPress={() => saveUserPhone()} paddingX={5}>
              <Text>{translate.components.save}</Text>
            </Pressable>
          ) : loadingPhone ? (
            <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
          ) : (
            <Pressable onPress={() => setEditingPhone(true)} paddingX={5}>
              <Image size="20px" source={{ uri: Images.edit }} />
            </Pressable>
          )
        }
      />
      <Input
        type="text"
        w="100%"
        maxW="300px"
        size="md"
        placeholder={auth.currentUser?.email ?? useTranslate.email}
        isDisabled={!editingEmail}
        value={userEmail}
        onChangeText={(text) => setUserEmail(text)}
        InputRightElement={
          editingEmail ? (
            <Pressable onPress={() => saveEmail()} paddingX={5}>
              <Text>{translate.components.save}</Text>
            </Pressable>
          ) : loadingEmail ? (
            <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
          ) : (
            <Pressable onPress={() => setEditingEmail(true)} paddingX={5}>
              <Image size="20px" source={{ uri: Images.edit }} />
            </Pressable>
          )
        }
      />
      <Input
        type="text"
        w="100%"
        maxW="300px"
        size="md"
        placeholder={useTranslate.wallet}
        isDisabled={true}
        value={userWallet}
      />
      <Divider marginY="10px" maxW="400px" w="90vw" /> */}

      {/* <SuppAndHelp /> */}
      <Center h="10px" />
    </VStack>
  );
};

export default Profile;
