import {
  Box,
  FlatList,
  HStack,
  Image,
  Input,
  Popover,
  Pressable,
  ScrollView,
  Text,
} from "native-base";
import { useEffect, useState } from "react";
import Images from "../../../assets/images";
import countryCodes, {
  ICountryCode,
} from "../../../shared/constants/countryCodes";
export interface CountrySelectorProps {
  country: ICountryCode;
  setCountry: (country: ICountryCode) => void;
}

const CountrySelector = ({ country, setCountry }: CountrySelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const [filteredCountries, setFilteredCountries] = useState<ICountryCode[]>(
    []
  );

  useEffect(() => {
    const filtered =
      filter.length > 0
        ? countryCodes.filter((c) =>
            c.name.toLowerCase().includes(filter.toLowerCase())
          )
        : countryCodes;
    setFilteredCountries(filtered);
  }, [filter]);

  return (
    <>
      <Popover
        placement={undefined}
        trigger={(triggerProps) => {
          return (
            <Pressable
              onPress={() => {
                setFilter("");
                setIsOpen(true);
              }}
            >
              <HStack alignItems="center" space="10px" {...triggerProps}>
                <Image
                  source={{
                    uri: `/countries/${country.code.toLowerCase()}.svg`,
                  }}
                  size="30px"
                  resizeMode="contain"
                  alt=""
                />
                <Image source={{ uri: Images.triangleDown }} size="15px" alt="" />
              </HStack>
            </Pressable>
          );
        }}
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <Popover.Content w="56">
          <Popover.Arrow />
          <Popover.Header>
            <Input
              placeholder="Search for a country"
              w="100%" variant="underlined"
              value={filter}
              onChangeText={(text) => setFilter(text)}
            />
          </Popover.Header>
          <Popover.Body>
            <ScrollView maxH="200px">
              <FlatList
                data={filteredCountries}
                renderItem={({ item }) => (
                  <Pressable
                    onPress={() => {
                      setCountry(item);
                      setIsOpen(false);
                    }}
                  >
                    <HStack alignItems="center" space="10px">
                      <Box w="30px" h="30xp">
                        <Image
                          source={{
                            uri: `/countries/${item.code.toLowerCase()}.svg`,
                          }}
                          size="30px"
                          resizeMode="contain"
                          alt=""
                        />
                      </Box>
                      <Text>{item.name}</Text>
                    </HStack>
                  </Pressable>
                )}
                keyExtractor={(item) => item.code}
              />
            </ScrollView>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </>
  );
};

export default CountrySelector;
