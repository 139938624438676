import { onAuthStateChanged } from "firebase/auth";
import { HStack, Pressable, Image, Center, Box, Text } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../../assets/images";
import { CollectMintContext } from "../../../contexts/collect-mint";
import { UserPreferencesContext } from "../../../contexts/user-preferences";
import { auth } from "../../../firebase.config";
import { COOKIE_TERMS_LABEL } from "../../constants/cookie";
import { getCookie } from "../../services/cookie.service";
import { islogged, userPreferences } from "../../services/user.service";

type HeaderProps = {
  backAction?: () => void;
  avatar?: boolean;
  backWhite?: boolean;
  title?: string;
  rightText?: string;
  rightTextAction?: () => void;
  fixed?: boolean;
};

const Header = ({
  backAction,
  avatar,
  backWhite,
  title,
  rightText,
  rightTextAction,
  fixed = false,
}: HeaderProps) => {
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState<string>("");
  const userPreferencesContext = useContext(UserPreferencesContext);

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user && user.photoURL) {
        {
          setProfileImage(user.photoURL);
        }
      }
    });
    return unsubscriber();
  }, []);

  const profile = async () => {
    try {
      if (islogged(auth.currentUser)) {
        if (
          userPreferencesContext.preferences === null ||
          userPreferencesContext.preferences.acceptTerms
        ) {
          navigate("/profile");
        } else {
          navigate("/terms");
        }
      } else {
        navigate("/inputphone", {
          state: {
            from: "PROFILE",
          },
        });
      }
    } catch (error) {}
  };

  return (
    <HStack
      w="100%"
      paddingY="10px"
      paddingX="10px"
      justifyContent="space-between"
      alignItems="center"
      position={fixed ? "fixed" : "unset"}
      zIndex={1}
      backgroundColor={fixed ? "white" : ""}
    >
      {backAction ? (
        <Pressable
          onPress={() => {
            backAction();
          }}
        >
          <Center w="40px">
            <Image
              source={{ uri: backWhite ? Images.backWhite : Images.back }}
              w="30px"
              h="30px"
              alt=""
            />
          </Center>
        </Pressable>
      ) : (
        <Center w="40px" />
      )}
      {title ? (
        <Text fontFamily="LibreBaskerville" fontSize="25px">
          {title.toUpperCase()}
        </Text>
      ) : (
        <></>
      )}
      {rightText ? (
        <Text onPress={rightTextAction}>{rightText}</Text>
      ) : avatar ? (
        <Pressable onPress={() => profile()}>
          <Image
            source={{ uri: profileImage ? profileImage : Images.user }}
            w="40px"
            h="40px"
            rounded="100%"
            alt=""
          />
        </Pressable>
      ) : (
        <Center w="40px" />
      )}
    </HStack>
  );
};

export default Header;
