/* eslint-disable react/react-in-jsx-scope */
import { createContext, ReactNode, useEffect } from "react";

export interface ICollectMintContext {
  active: "MINT" | "COLLECT" | null;
  setActive: any;
  children?: any;
}

export const INITIAL_COLLECT_MINT_STATE: ICollectMintContext = {
  active: "COLLECT",
  setActive: null,
};

export const CollectMintContext = createContext<ICollectMintContext>(
  INITIAL_COLLECT_MINT_STATE
);

const CollectMintContextProvider = ({
  active,
  setActive,
  children,
}: ICollectMintContext) => {

  return (
    <CollectMintContext.Provider value={{ active, setActive }}>
      {children}
    </CollectMintContext.Provider>
  );
};

export default CollectMintContextProvider;
