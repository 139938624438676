import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  Pressable,
  Text,
  VStack,
} from "native-base";
import Images from "../../../assets/images";
import { uploadImage } from "../../services/file.service";
import { text } from "stream/consumers";
import { Camera } from "react-camera-pro";
import { isMobile } from "react-device-detect";
import { ref } from "firebase/storage";

interface ImagePickerProps {
  profileImage: string;
  setProfileImage: (base64: string) => void;
}

const ImagePicker = React.forwardRef(
  ({ profileImage, setProfileImage }: ImagePickerProps, ref) => {
    const [showCamera, setShowCamera] = useState(false);
    const camera = useRef<any>(null);

    useImperativeHandle<any, any>(ref, () => ({
      getShowCamera: () => showCamera,
      hideCamera: () => {
        setShowCamera(false)
      },
    }));

    const collectImageFromGallery = async () => {
      try {
        const b64: any = await uploadImage();
        setProfileImage(b64[0]);
      } catch (error) {}
    };

    const getImageFromCamera = async () => {
      setShowCamera(true);
    };

    const takePicture = () => {
      if (!camera.current) return;
      setProfileImage(camera.current.takePhoto());
      setShowCamera(false);
    };

    return (
      <Center height={"100p%"}>
        {showCamera ? (
          <VStack h="50vh" maxH="200px" w="100%">
            <Camera ref={camera} errorMessages={{}} />
            <Box position="absolute" bottom="10px" left="calc(50% - 20px)">
              <Pressable onPress={() => takePicture()}>
                <Box
                  backgroundColor="black"
                  w="40px"
                  h="40px"
                  borderRadius="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Image
                    size={"20px"}
                    source={{ uri: Images.camera }}
                    alt="camera"
                  />
                </Box>
              </Pressable>
            </Box>
          </VStack>
        ) : (
          <>
            {profileImage ? (
              <Avatar
                w="180px"
                h="180px"
                source={{ uri: profileImage }}
                marginTop={5}
              />
            ) : (
              <></>
            )}
            <HStack
              marginTop={"20px"}
              borderRadius="20px"
              backgroundColor={"gray.500"}
            >
              <IconButton
                onPress={() => collectImageFromGallery()}
                backgroundColor={"gray.500"}
                borderWidth={8}
                borderRadius="20px"
                borderColor={"gray.300"}
                icon={
                  <Image
                    size={"24px"}
                    source={{ uri: Images.gallery }}
                    alt="gallery"
                  />
                }
              />
              {isMobile ? (
                <></>
              ) : (
                <IconButton
                  onPress={() => getImageFromCamera()}
                  backgroundColor={"gray.500"}
                  borderWidth={8}
                  borderRightRadius="20px"
                  borderColor={"gray.500"}
                  icon={
                    <Image
                      size={"25px"}
                      source={{ uri: Images.camera }}
                      alt="camera"
                    />
                  }
                />
              )}
            </HStack>
          </>
        )}
      </Center>
    );
  }
);

export default ImagePicker;
