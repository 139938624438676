import {
  VStack,
  HStack,
  Center,
  Pressable,
  Spacer,
  Divider,
  Box,
  IconButton,
  AddIcon,
  MinusIcon,
  Button,
  Image,
  Text,
  ArrowBackIcon,
  useToast,
  Radio,
  Switch,
  Spinner,
} from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import { auth } from "../../firebase.config";
import Header from "../../shared/components/Header";
import SuppAndHelp from "../../shared/components/SuppAndHelp";
import { COOKIE_LANGUAGE_LABEL } from "../../shared/constants/cookie";
import { currentLanguage, translate } from "../../shared/i18n/translate";
import {
  askPermission,
  checkPermission,
  PermissionTypes,
} from "../../shared/services/permissions.service";
import {
  deleteToken,
  updatePreferences,
  userPreferences,
} from "../../shared/services/user.service";

const Settings = () => {
  const useTranslate = translate.pages.settings;
  const navigate = useNavigate();
  const toast = useToast();

  const [collapsable, setCollapsable] = React.useState<boolean>(true);

  return (
    <VStack
      h="100vh"
      maxH="700px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Header
        backAction={() => navigate("/profile")}
        avatar={true}
        title={useTranslate.title}
      />

      {/* PRIVACY OPTIONS */}
      <HStack w="calc(100vw - 50px)" maxW="500px" justifyContent="flex-start">
        <Text bold fontSize="16px">
          {translate.pages.settings.privacyOptions}
        </Text>
      </HStack>

      {/* ACCOUNT OPTIONS */}
      <HStack w="calc(100vw - 50px)" maxW="500px" justifyContent="flex-start">
        <Text bold fontSize="16px">
          {translate.pages.settings.accountOptions}
        </Text>
      </HStack>

      <VStack
        w="calc(100vw - 50px)"
        maxW="500px"
        space="15px"
        marginBottom="15px"
      >
        <Button
          rounded="50px"
          w="100%"
          variant="outline"
          onPress={() =>
            auth.signOut().then(() => {
              deleteToken();
              navigate("/");
            })
          }
        >
          {useTranslate.logout}
        </Button>
        <Button
          rounded="50px"
          w="100%"
          variant="outline"
          onPress={() =>
            auth.signOut().then(() => {
              deleteToken();
              navigate("/");
            })
          }
        >
          {useTranslate.deleteAccount}
        </Button>
      </VStack>

      {/* <VStack h="70%" width={"100vw"} maxW="350px" paddingX={"32px"}>
        <VStack justifyContent={"flex-start"} paddingTop={"26px"}>
          <Pressable
            onPress={() => {
              if (!hasGeolocationPermission) {
                requestGeolocation();
              }
            }}
          >
            <HStack paddingBottom={"20px"} space="5px" alignItems="center">
              {hasGeolocationPermission ? (
                <Image source={{ uri: Images.success }} size="20px" />
              ) : (
                <></>
              )}
              <Text fontSize={16}>{useTranslate.shareLocalization}</Text>
              <Spacer />
              <Pressable
                onPress={() => {
                  toast.show({ title: useTranslate.locationPersonalInfo });
                }}
              >
                <Image
                  source={{ uri: Images.info }}
                  alt={"info"}
                  height={"24px"}
                  width={"24px"}
                  marginRight={"12px"}
                />
              </Pressable>
            </HStack>
          </Pressable>
          <Pressable onPress={() => {}}>
            <HStack paddingBottom={"10px"}>
              {hasPersonalPermission ? (
                <Image source={{ uri: Images.success }} size="20px" />
              ) : (
                <></>
              )}
              <Text fontSize={16}>{useTranslate.sharePersonalInfo}</Text>
              <Spacer />
              <Pressable
                onPress={() => {
                  toast.show({ title: useTranslate.locationPersonalInfo });
                }}
              >
                <Image
                  source={{ uri: Images.info }}
                  alt={"info"}
                  height={"24px"}
                  width={"24px"}
                  marginRight={"12px"}
                />
              </Pressable>
            </HStack>
          </Pressable>
        </VStack>
        <Divider marginY={"20px"} />
        <Center>
          <Text fontSize={24} marginBottom={"14px"}>
            {useTranslate.security}
          </Text>
        </Center>
        <Box padding={"14px"} borderColor="coolGray.300" borderWidth="1">
          <HStack>
            <Text fontSize={16}>{useTranslate.deleteAccount}</Text>
            <Spacer />
            <IconButton
              onPress={() => setCollapsable(!collapsable)}
              size={"24px"}
              icon={
                collapsable ? (
                  <AddIcon color={"black"} />
                ) : (
                  <MinusIcon color={"black"} />
                )
              }
            />
          </HStack>
          {!collapsable && (
            <VStack>
              <Text paddingY={"10px"}>
                {useTranslate.deleteAccountDescription}
              </Text>
              <HStack>
                <Spacer />
                <Button marginRight={"14px"} variant="outline">
                  {useTranslate.cancel}
                </Button>
                <Button colorScheme="warning">{useTranslate.delete}</Button>
              </HStack>
            </VStack>
          )}
        </Box>
        <Spacer />
        <Button
          rounded={50}
          borderWidth={"0.5px"}
          borderColor={"black"}
          marginTop="20px"
          onPress={() =>
            auth.signOut().then(() => {
              deleteToken();
              navigate("/");
            })
          }
        >
          {useTranslate.logout}
        </Button>
        <Spacer />
        <Divider margin={"20px"} width={"80%"} />
        <SuppAndHelp />
      </VStack> */}
    </VStack>
  );
};

export default Settings;
