import {
  Center,
  Divider,
  HStack,
  Image,
  Pressable,
  Radio,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import Header from "../../shared/components/Header";
import SuppAndHelp from "../../shared/components/SuppAndHelp";
import { COOKIE_LANGUAGE_LABEL } from "../../shared/constants/cookie";
import { currentLanguage, translate } from "../../shared/i18n/translate";

const Help = () => {
  const navigate = useNavigate();
  const [language, setLanguage] = useState<string>(currentLanguage);

  const handleLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    localStorage.setItem(COOKIE_LANGUAGE_LABEL, newLanguage);
    window.location.reload();
  };

  return (
    <VStack
      h="100vh"
      maxH="700px"
      justifyContent="flex-start"
      alignItems="center"
      space="20px"
    >
      <Header backAction={() => navigate("/profile")} />

      <Spacer />

      {/* TITLE */}
      <Center>
        <Text bold fontSize="22px">
          {translate.pages.profile.help}
        </Text>
      </Center>

      <Spacer />

      {/* LANGUAGE */}
      <VStack space="10px">
        <HStack w="calc(100vw - 50px)" maxW="500px" justifyContent="flex-start">
          <Text bold fontSize="16px">
            {translate.pages.languages.title}
          </Text>
        </HStack>
        <VStack
          w="calc(100vw - 50px)"
          maxW="500px"
          background="white"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderRadius="12px"
          paddingY="10px"
          paddingX="15px"
        >
          <Radio.Group
            name="languages"
            colorScheme={"black"}
            value={language}
            onChange={handleLanguage}
          >
            <Radio value="en" marginBottom="10px" color="black" w="100%">
              <Text fontSize="15px" paddingBottom="5px">
                {translate.pages.languages.enUs}
              </Text>
            </Radio>
            <Divider />
            <Radio value="pt-BR" my="10px">
              <Text fontSize="15px" paddingTop="4px">
                {translate.pages.languages.ptBr}
              </Text>
            </Radio>
            <Divider />
            <Radio value="es" marginTop="10px">
              <Text fontSize="15px" paddingTop="11px">
                {translate.pages.languages.esp}
              </Text>
            </Radio>
          </Radio.Group>
        </VStack>
      </VStack>

      {/* FAQ */}
      <Pressable onPress={() => navigate('/faq')}>
      <HStack
          w="calc(100vw - 50px)"
          maxW="500px"
          background="white"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderRadius="12px"
          paddingY="10px"
          paddingX="15px"
          space="10px"
          alignItems="center"
        >
          <Image source={{ uri: Images.faq2 }} size="25px" />
          <Text lineHeight="12px">FAQ</Text>
        </HStack>
      </Pressable>

      <Spacer />
      <Spacer />

      <SuppAndHelp />

      <Center h="30px" />
    </VStack>
  );
};

export default Help;
