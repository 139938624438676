import {
  Box,
  Button,
  Center,
  CheckIcon,
  HStack,
  Image,
  Link,
  Pressable,
  Select,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "native-base";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import Images from "../../../assets/images";
import { Dimensions, StyleSheet } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential,
} from "firebase/auth";
import { auth } from "../../../firebase.config";
import ReactCodeInput from "react-code-input";
import { COOKIE_TERMS_LABEL } from "../../../shared/constants/cookie";
import { getCookie } from "../../../shared/services/cookie.service";
import {
  savePhone,
  userPreferences,
} from "../../../shared/services/user.service";
import { useToast } from "native-base";
import { translate } from "../../../shared/i18n/translate";
import { clearCode } from "../../../shared/services/inputCode.service";
import Header from "../../../shared/components/Header";
import { CollectMintContext } from "../../../contexts/collect-mint";

interface InputPhoneCodeProps {}

const InputPhoneCode = ({}: InputPhoneCodeProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phone, setPhone] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [confirmCode, setConfirmCode] = useState<any | null>(null);
  const [loadingCode, setLoadingCode] = useState<boolean>(false);
  const [from, setFrom] = useState<string>("");
  const toast = useToast();
  const inputCode = useRef<any>();
  const collectMintContext = useContext(CollectMintContext);

  useEffect(() => {
    if (state) {
      const { phone, from } = state;
      if (from) {
        setFrom(from);
      }
      if (phone) {
        setPhone(phone);
        if (confirmCode === null) {
          sendCode(phone);
        }
      } else {
        navigate("/inputphone");
      }
    } else {
      navigate("/");
    }
  }, [state]);

  useEffect(() => {
    if (code.length === 6) {
      confirm();
    }
  }, [code]);

  const generateReCaptch = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const sendCode = async (phone: string, resendCode = false) => {
    if (!resendCode) {
      generateReCaptch();
    }
    try {
      const _confirm = await signInWithPhoneNumber(
        auth,
        phone,
        (window as any).recaptchaVerifier
      );
      setConfirmCode(_confirm);
    } catch (error) {
      // toast.show({ title: JSON.stringify(error) });
      console.error(error);
      toast.show({ title: translate.alerts.sendPhoneCodeError });
      navigate("/inputphone");
    }
  };

  const confirm = async () => {
    let errorMessage = translate.alerts.confirmPhoneCodeError;
    try {
      setLoadingCode(true);
      const response: UserCredential = await confirmCode?.confirm(code);
      errorMessage = translate.alerts.savePhoneError;
      await savePhone(response.user.phoneNumber as string);
      errorMessage = translate.alerts.getUserPreferencesError;
      const responsePreferences = await userPreferences();
      if (responsePreferences.data.user.acceptTerms) {
        if (from === "COLLECT") {
          navigate(`/nftpage/${state.data}/collect`, { state: { collect: true } });
        } else if (from === "MINT") {
          navigate("/mint");
        } else if (from === "PROFILE") {
          navigate("/profile");
        } else if (from === "NFTPAGE") {
          navigate(`/nftpage/${state.token}/collect`);
        } else {
          navigate("/");
        }
        window.location.reload();
      } else {
        navigate("/terms", {
          state,
        });
      }
    } catch (error) {
      setLoadingCode(false);
      toast.show({ title: errorMessage });
    }
  };

  const resend = async () => {
    setConfirmCode(null);
    sendCode(phone, true);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <div id="sign-in-button"></div>
      <VStack h="100vh" justifyContent="flex-start" alignItems="center">
        <Header
          title={collectMintContext.active ? collectMintContext.active : ""}
          avatar={true}
          backAction={() => navigate("/")}
        />
        <Center w="100%" paddingY="20px">
          <Image
            source={{ uri: Images.logoCircle }}
            w="130px"
            h="130px"
            alt=""
          />
        </Center>
        <VStack space={10} w="100%" flexGrow={1}>
          {confirmCode && !loadingCode ? (
            <VStack
              w="100%"
              paddingY="20px"
              alignItems="center"
              h="100%"
              maxH="500px"
            >
              <VStack
                w="100%"
                justifyContent="center"
                alignItems="center"
                marginBottom="20px"
              >
                <Text textAlign="center" fontSize="18px">
                  {translate.pages.phoneSignup.enterSMSCode}
                </Text>
                <Text textAlign="center" fontSize="22px">
                  {phone}
                </Text>
              </VStack>
              <ReactCodeInput
                type="tel"
                fields={6}
                name={""}
                ref={inputCode}
                inputMode="full-width-latin"
                value={code}
                onChange={(value) => {
                  setCode(value);
                }}
                inputStyle={{
                  fontSize: "20px",
                  width: "20px",
                  height: "30px",
                  textAlign: "center",
                  marginLeft: "5px",
                  marginRight: "5px",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderBottom: "3px solid black",
                  outline: "none",
                }}
              />
              <Spacer />
              <Center marginTop="30px">
                <Link onPress={() => resend()}>
                  <Text
                    fontSize="25px"
                    borderBottomWidth="1px"
                    lineHeight="20px"
                  >
                    {translate.pages.phoneSignup.resendCode}
                  </Text>
                </Link>
              </Center>
            </VStack>
          ) : (
            <Spinner accessibilityLabel="" mt="50px" size={50} />
          )}
        </VStack>
      </VStack>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  codeContainerStyle: {
    borderWidth: 0,
    borderBottomWidth: 2,
    marginHorizontal: 5,
  },
  codeContainerCaretStyle: {
    borderWidth: 0,
    borderBottomWidth: 2,
    marginHorizontal: 5,
  },
});

export default InputPhoneCode;
