import React, { useContext, useEffect, useState } from "react";
import {
  ArrowBackIcon,
  Center,
  HStack,
  IconButton,
  Image,
  Pressable,
  ScrollView,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useNavigate } from "react-router-dom";
import { translate } from "../../shared/i18n/translate";
import Images from "../../assets/images";
import { auth } from "../../firebase.config";

const Privacy = () => {
  const navigate = useNavigate();
  const useTranslate = translate.pages.privacy;

  const getTitle = (text: string) => <Text fontWeight="bold">{text}</Text>;
  const getP = (text: string) => <Text>{text}</Text>;
  const getL = (texts: string[]) => (
    <VStack>
      {texts.map((text) => (
        <HStack space="5px" alignItems="center">
          <Center
            borderRadius="100%"
            backgroundColor="black"
            w="5px"
            h="5px"
          ></Center>
          <Text>{text}</Text>
        </HStack>
      ))}
    </VStack>
  );
  const getU = (text: string) => <Text underline>{text}</Text>;
  const getI = (text: string) => <Text italic>{text}</Text>;

  return (
    <VStack space={0} alignItems="center" h="100vh" justifyContent="center" padding="20px">
      <Center>
        <Text fontWeight="bold" fontSize="25px">
          {useTranslate.title.toUpperCase()}
        </Text>
      </Center>
      <ScrollView w="100%" h="80">
        <Center>
          <VStack space="10px" padding="10px" maxWidth="600px">
            {getP(useTranslate.p1)}
            {getP(useTranslate.p2)}
            {getP(useTranslate.p3)}
            {getP(useTranslate.p4)}
            {getTitle(useTranslate.h1)}
            {getP(useTranslate.p5)}
            {getTitle(useTranslate.h2)}
            {getP(useTranslate.p6)}
            {getP(useTranslate.p7)}
            {getP(useTranslate.p8)}
            {getP(useTranslate.p9)}
            {getP(useTranslate.p10)}
            {getP(useTranslate.p11)}
            {getL([
              useTranslate.l1,
              useTranslate.l2,
              useTranslate.l3,
              useTranslate.l4,
            ])}
            {getTitle(useTranslate.h3)}
            {getP(useTranslate.p12)}
            {getU(useTranslate.u1)}
            {getP(useTranslate.p13)}
            {getP(useTranslate.p14)}
            {getU(useTranslate.u2)}
            {getP(useTranslate.p15)}
            {getL([
              useTranslate.l5,
              useTranslate.l6,
              useTranslate.l7,
              useTranslate.l8,
              useTranslate.l9,
            ])}
            {getU(useTranslate.u3)}
            {getP(useTranslate.p16)}
            {getU(useTranslate.u4)}
            {getP(useTranslate.p17)}
            {getU(useTranslate.u5)}
            {getP(useTranslate.p18)}
            {getU(useTranslate.u6)}
            {getP(useTranslate.p19)}
            {getU(useTranslate.u7)}
            {getP(useTranslate.p20)}
            {getP(useTranslate.p21)}
            {getTitle(useTranslate.h4)}
            {getP(useTranslate.p22)}
            {getP(useTranslate.p23)}
            {getP(useTranslate.p24)}
            {getP(useTranslate.p25)}
            {getP(useTranslate.p26)}
            {getTitle(useTranslate.h5)}
            {getP(useTranslate.p27)}
            {getL([
              useTranslate.l10,
              useTranslate.l11,
              useTranslate.l12,
              useTranslate.l13,
              useTranslate.l14,
              useTranslate.l15,
              useTranslate.l16,
              useTranslate.l17,
              useTranslate.l18,
            ])}
            {getP(useTranslate.p28)}
            {getP(useTranslate.p29)}
            {getP(useTranslate.p30)}
            {getP(useTranslate.p31)}
            {getTitle(useTranslate.h6)}
            {getP(useTranslate.p32)}
            {getTitle(useTranslate.h7)}
            {getP(useTranslate.p33)}
            {getI(useTranslate.i1)}
          </VStack>
        </Center>
      </ScrollView>
    </VStack>
  );
};

export default Privacy;
