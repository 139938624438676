import React, { useEffect, useState } from "react";
import { NativeBaseProvider } from "native-base";
import Root from "./pages";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase.config";
import "./App.css";
import { wakameTheme } from "./assets/theme";
import CollectMintContextProvider from "./contexts/collect-mint";
import UserPreferencesContextProvider from "./contexts/user-preferences";
import { userPreferences } from "./shared/services/user.service";
import "react-datepicker/dist/react-datepicker.css";

export default function App() {
  const [active, setActive] = useState<"MINT" | "COLLECT">("COLLECT");
  const [preferences, setPreferences] = useState<any>(null);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        getPreferences();
      }
    });
  }, []);

  const getPreferences = async () => {
    try {
      const response = await userPreferences();
      setPreferences(response.data.user);
    } catch (error) {}
  };

  return (
    <NativeBaseProvider theme={wakameTheme}>
      <CollectMintContextProvider active={active} setActive={setActive}>
        <UserPreferencesContextProvider
          preferences={preferences}
          setPreferences={setPreferences}
        >
          <Root />
        </UserPreferencesContextProvider>
      </CollectMintContextProvider>
    </NativeBaseProvider>
  );
}
