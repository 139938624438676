import { format } from "date-fns";
import { Center, HStack, Image, Text, VStack } from "native-base";

const NftHomeImage = ({ image, name, collected, total, date }: any) => {

  const formatDate = (date: string) => {
    if (date) {
      return format(new Date(date), "dd LLL uuuu");
    }
    return ''
  }

  return (
    <>
      <VStack w="100%" h="100%" p="10px" shadow="1" borderRadius="12px">
        <Image
          w="100%"
          h="calc(100% - 100px)"
          marginTop="10px"
          source={{ uri: image }}
          resizeMode="contain"
        />
        {/* <HStack
          position="absolute"
          zIndex="1"
          h="40px"
          justifyContent="space-between"
          paddingX="20px"
          alignItems="center"
          bottom={0}
          background="rgba(0,0,0,0.5)"
          w="100%"
          borderBottomRadius="20px"
          marginBottom="-4px"
        >
      </HStack> */}
        <VStack alignItems="flex-start" h="100px" justifyContent="center">
          <Text textAlign="left" bold fontSize="16px" numberOfLines={2}>{name}</Text>
          <Text>{formatDate(date)}</Text>
        </VStack>
      </VStack>
    </>
  );
};

export default NftHomeImage;
