import logoGreen from "./img/logo_green.jpg";
import user from "./img/user.png";
import logoCircle from "./img/logo_circle.png";
import logo from "./img/logo.png";
import plus from "./img/plus.png";
import back from "./img/back.png";
import backWhite from "./img/back_white.png";
import edit from "./img/edit.png";
import faq from "./img/faq.png";
import editProfile from "./img/edit_profile.png";
import lang from "./img/lang.png";
import security from "./img/security.png";
import whatsApp from "./img/whatsApp.png";
import telegram from "./img/telegram.png";
import email from "./img/email.png";
import clipboardDocument from "./img/clipboard-document.png";
import clipboardDocumentWhite from "./img/clipboard-document-white.png";
import map from "./img/map.png";
import qrCode from "./img/qr-code.png";
import share from "./img/share.png";
import share2 from "./img/share2.svg";
import shareWhite from "./img/share_white.png";
import star from "./img/star.png";
import close from "./img/close.png";
import bird from "./img/bird.png";
import partyHat from "./img/party_hat.png";
import closeToast from "./img/close_toast.png";
import info from "./img/info.png";
import gallery from "./img/gallery.png";
import camera from "./img/camera.png";
import triangleDown from "./img/triangle_down.png";
import triangleDownBlack from "./img/triangle_down_black.png";
import triangleUpBlack from "./img/triangle_up_black.png";
import success from "./img/success.png";
import exportWhite from "./img/export_white.png";
import arrowRight from "./img/arrow_right.svg";
import arrowLeft from "./img/arrow_left.svg";
import chevronRight from "./img/chevron_right.svg";
import settings from "./img/settings.svg";
import terms from "./img/terms.svg";
import privacy from "./img/privacy.svg";
import faqIcon from "./img/faq.svg";
import calendar from "./img/calendar.svg";
import mark from "./img/mark.svg";
import logout from "./img/logout.svg";
import map2 from "./img/map2.svg";
import clock from "./img/clock.svg";
import faq2 from "./img/faq2.svg";

const Images = {
  logoGreen,
  user,
  logoCircle,
  arrowRight,
  arrowLeft,

  // icons
  logo,
  plus,
  back,
  backWhite,
  edit,
  faq,
  editProfile,
  lang,
  security,
  whatsApp,
  telegram,
  email,
  clipboardDocument,
  clipboardDocumentWhite,
  map,
  qrCode,
  share,
  share2,
  shareWhite,
  star,
  close,
  bird,
  partyHat,
  closeToast,
  info,
  gallery,
  camera,
  triangleDown,
  triangleDownBlack,
  triangleUpBlack,
  success,
  exportWhite,
  chevronRight,
  settings,
  terms,
  privacy,
  faqIcon,
  calendar,
  mark,
  logout,
  map2,
  clock,
  faq2,
};

export default Images;
