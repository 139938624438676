import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import React, { useEffect, useRef, useState } from "react";
import Images from "../../../assets/images";
import Header from "../../../shared/components/Header";

const CollectMap = () => {
  return (
    <VStack h="100vh" w="100vw">
      <Header avatar={true} />
      <Text textAlign="center" fontSize="25px" fontWeight="bold">
        COLLECT
      </Text>
      <HStack flexGrow={1} padding="10px">
        <Center backgroundColor="green.300" w="100%" h="calc(100vh - 220px)" borderRadius="20px"></Center>
      </HStack>
      <Center position="fixed" top="calc(100vh - 100px)" w="100vw" zIndex={10}>
        <Pressable onPress={() => {}}>
          {({ isHovered, isFocused, isPressed }) => {
            return (
              <Center rounded="100" shadow="3" bg="#57ee49" p={5}>
                <Image source={{ uri: Images.logo }} size="40px" alt="" />
              </Center>
            );
          }}
        </Pressable>
      </Center>
    </VStack>
  );
};

export default CollectMap;
