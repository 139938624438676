import { User } from "firebase/auth";
import { COOKIE_TERMS_LABEL } from "../constants/cookie";
import api from "./api.service";
import { getCookie } from "./cookie.service";

export const WAKAME_TOKEN_LABEL = "wakame-auth-token";

export const saveToken = (token: string) => {
  sessionStorage.setItem(WAKAME_TOKEN_LABEL, token);
};

export const getToken = () => {
  return sessionStorage.getItem(WAKAME_TOKEN_LABEL);
};

export const deleteToken = () => {
  sessionStorage.removeItem(WAKAME_TOKEN_LABEL);
};

export const islogged = (user: User | null) => {
  try {
    return user && user.uid;
  } catch (error) {
    return false;
  }
};

export const savePhone = (phoneNumber: string) => {
  return api.post("/savePhoneNumber", { phoneNumber });
};

export const saveUserInfo = (
  data: Partial<{
    name: string;
    email: string;
    profilePicture: string;
    nickname: string;
  }>
) => {
  return api.post("/saveBasicUserInfo", data);
};

export const generateCryptoAddress = () => {
  return api.post("/generateCryptoAddress", {});
};

export const isCompletedKyc = (user: User | null) => {
  if (user) {
    if (!user.displayName || !user.photoURL) {
      return false;
    }
    return true;
  }
  return false;
};

export const userPreferences = () => {
  return api.get("/userPreferences");
};

export const updatePreferences = (data: {
  acceptTerms?: boolean;
  shareLocation?: boolean;
  sharePersonalInfo?: boolean;
}) => {
  return api.post("/updatePreferences", data);
};

export const postDeleteAccount = () => {
  return api.post("/deleteAccount");
};
