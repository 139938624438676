import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Center,
  HStack,
  Input,
  ScrollView,
  Spinner,
  Text,
  VStack,
} from "native-base";
import { setCookie } from "../../shared/services/cookie.service";
import { COOKIE_TERMS_LABEL } from "../../shared/constants/cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.config";
import { savePhone, updatePreferences, userPreferences } from "../../shared/services/user.service";
import { useToast } from "native-base";
import { translate } from "../../shared/i18n/translate";
import TermsContent from "../../shared/components/TermsContent";
import { UserPreferencesContext } from "../../contexts/user-preferences";

const Terms = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(false);
  const userPreferencesContext = useContext(UserPreferencesContext)

  const logout = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        // TODO erro ao deslogar
      });
  };

  const cancel = () => {
    logout();
    navigate("/");
    toast.show({title: translate.pages.terms.cancelTermsMessage})
  }

  const accept = async () => {
    if (!auth.currentUser) {
      return logout();
    }
    const { phoneNumber } = auth.currentUser;
    if (!phoneNumber) {
      return logout();
    }
    try {
      setLoading(true);
      await savePhone(phoneNumber);
      await updatePreferences({ acceptTerms: true });
      const response = await userPreferences();
      userPreferencesContext.setPreferences(response.data.user);
      if (state) {
        if (state.from === "COLLECT") {
          navigate(`/nftpage/${state.data}`, {
            state: {
              collect: true,
            },
          });
        } else if (state.from === "MINT") {
          navigate("/mint", { state });
        } else if (state.from === "PROFILE") {
          if (
            auth.currentUser?.displayName &&
            auth.currentUser.email &&
            auth.currentUser.photoURL
          ) {
            navigate("/");
          } else {
            navigate("/personalinfo", { state });
          }
        } else if (state.from === "NFTPAGE") {
          navigate(`/nftpage/${state.token}/collect`, {
            state: { savePhone: true },
          });
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      toast.show({ title: translate.alerts.savePhoneError });
      logout();
      navigate("/");
      setLoading(false);
    }
  };

  return (
    <VStack space={0} alignItems="center" h="100vh" justifyContent="center">
      <ScrollView w="100%" flexGrow="1">
        <TermsContent />
      </ScrollView>
      <HStack w="100%" h="100px" alignItems="center" justifyContent="center">
        {loading ? (
          <Spinner />
        ) : (
          <HStack w="90vw" space="20px" maxW="400px">
            <Button flexGrow="1" variant="outline" onPress={() => cancel()}>
              Cancel
            </Button>
            <Button flexGrow="1" onPress={() => accept()}>
              Accept
            </Button>
          </HStack>
        )}
      </HStack>
    </VStack>
  );
};

export default Terms;
