import { Center, Text, VStack } from "native-base";
import React from "react";
import { translate } from "../../i18n/translate";

const TermsContent = () => {
  const translateTerms = translate.pages.terms;

  const title = (text: string) => (
    <Text fontWeight="bold" fontSize="25px">
      {text}
    </Text>
  );

  const p = (text: string) => <Text>{text}</Text>;

  const t = (text: string) => (
    <Text fontWeight="bold" fontSize="20px">
      {text}
    </Text>
  );

  const l = (text: string) => <Text marginLeft="10px">{text}</Text>;

  const b = (text: string) => <Text fontWeight="bold">{text}</Text>;

  const d = (text: string) => <Text italic>{text}</Text>;

  return (
    <Center>
      <VStack space="20px" padding="20px" maxWidth="600px">
        {title(translateTerms.title)}
        {p(translateTerms.p1)}
        {p(translateTerms.p2)}
        {p(translateTerms.p3)}
        {p(translateTerms.p4)}
        {t(translateTerms.t1)}
        {p(translateTerms.p5)}
        {l(translateTerms.l1)}
        {l(translateTerms.l2)}
        {l(translateTerms.l3)}
        {l(translateTerms.l4)}
        {l(translateTerms.l5)}
        {l(translateTerms.l6)}
        {l(translateTerms.l7)}
        {l(translateTerms.l8)}
        {l(translateTerms.l9)}
        {l(translateTerms.l10)}
        {l(translateTerms.l11)}
        {l(translateTerms.l12)}
        {l(translateTerms.l13)}
        {l(translateTerms.l14)}
        {l(translateTerms.l15)}
        {t(translateTerms.t2)}
        {p(translateTerms.p6)}
        {p(translateTerms.p7)}
        {p(translateTerms.p8)}
        {p(translateTerms.p9)}
        {t(translateTerms.t3)}
        {p(translateTerms.p10)}
        {p(translateTerms.p11)}
        {t(translateTerms.t4)}
        {p(translateTerms.p12)}
        {p(translateTerms.p13)}
        {p(translateTerms.p14)}
        {t(translateTerms.t5)}
        {p(translateTerms.p15)}
        {p(translateTerms.p16)}
        {p(translateTerms.p17)}
        {p(translateTerms.p18)}
        {b(translateTerms.b1)}
        {p(translateTerms.p19)}
        {p(translateTerms.p20)}
        {p(translateTerms.p21)}
        {p(translateTerms.p22)}
        {p(translateTerms.p23)}
        {t(translateTerms.t6)}
        {p(translateTerms.p24)}
        {p(translateTerms.p25)}
        {t(translateTerms.t7)}
        {p(translateTerms.p26)}
        {p(translateTerms.p27)}
        {p(translateTerms.p28)}
        {l(translateTerms.al1)}
        {l(translateTerms.al2)}
        {l(translateTerms.al3)}
        {l(translateTerms.al4)}
        {p(translateTerms.p29)}
        {l(translateTerms.al5)}
        {l(translateTerms.al6)}
        {l(translateTerms.al7)}
        {l(translateTerms.al8)}
        {p(translateTerms.p30)}
        {t(translateTerms.t8)}
        {p(translateTerms.p31)}
        {p(translateTerms.p32)}
        {p(translateTerms.p33)}
        {p(translateTerms.p34)}
        {p(translateTerms.p35)}
        {t(translateTerms.t9)}
        {p(translateTerms.p36)}
        {p(translateTerms.p37)}
        {p(translateTerms.p38)}
        {p(translateTerms.p39)}
        {t(translateTerms.t10)}
        {p(translateTerms.p40)}
        {l(translateTerms.al9)}
        {l(translateTerms.al10)}
        {l(translateTerms.al11)}
        {l(translateTerms.al12)}
        {p(translateTerms.p41)}
        {t(translateTerms.t11)}
        {p(translateTerms.p42)}
        {p(translateTerms.p43)}
        {p(translateTerms.p44)}
        {p(translateTerms.p45)}
        {p(translateTerms.p46)}
        {p(translateTerms.p47)}
        {p(translateTerms.p48)}
        {p(translateTerms.p49)}
        {d(translateTerms.d)}
      </VStack>
    </Center>
  );
};

export default TermsContent;
