import React, { useContext } from "react";
import {
  ArrowBackIcon,
  Center,
  HStack,
  IconButton,
  Image,
  Pressable,
  ScrollView,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import TermsContent from "../../shared/components/TermsContent";
import { auth } from "../../firebase.config";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <VStack space={0} alignItems="center" h="100vh" justifyContent="center">
      <ScrollView w="100vw" h="80">
        <TermsContent />
      </ScrollView>
    </VStack>
  );
};

export default Terms;
