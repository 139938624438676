import axios from "axios";
import { auth } from "../../firebase.config";
import { getToken } from "./user.service";

const api = axios.create({
  baseURL: 'https://us-central1-wakame-89b57.cloudfunctions.net/api'//process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();
    config.headers = {
      Authorization: `${token}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
