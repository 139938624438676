export const ptBR = {
  pages: {
    home: {
      collect: "COLETAR",
      mint: "CRIAR",
      descriptionCollect: {
        swipe1: "DESLIZE",
        swipe2: "PARA DIREITA PARA CRIAR",
        swipe3: "NFTS",
        line1: "PRESSIONE WAKAME",
        line2: "PARA COLETAR",
        line3: "SEU PRIMEIRO NFT",
      },
      descriptionMint: {
        swipe1: "DESLIZE",
        swipe2: "PARA ESQUERDA PARA CRIAR",
        swipe3: "NFTS",
        line1: "PRESSIONE +",
        line2: "PARA CRIAR SEU",
        line3: "PRIMEIRO NFT",
      },
    },
    profile: {
      completeProfile: "Complete seu perfil",
      profile: "Perfil",
      name: "Nome",
      userName: "Nome de Usuário",
      email: "E-mail",
      editProfileInfo: "Editar informações do perfil",
      lang: "Idioma",
      settings: "Configurações",
      faq: "FAQ",
      helpAndSupp: "Fale Conosco",
      termsAndCond: "Termos & Condições",
      privacyPolicy: "Política de Privacidade",
      privacy: "Privacidade",
      phoneNumber: "Número de Telefone",
      wallet: "Carteira",
      edit: "Editar",
      walletAddress: "Endereço da Carteira:",
      walletAddressCopied: "Endereço da Carteira Copiado",
      mail: "E-mail",
      telegram: "Telegram",
      whatsapp: "WhatsApp",
      account: "Conta",
      help: "Ajuda",
      privacyOptions: "Opções de Privacidade",
      deleteAccount: "Ecluir Conta",
      deleteAccountMessage: "Isso removerá todos os dados. Essa ação não pode ser desfeita. Dados excluídos não podem ser recuperados.",
    },
    languages: {
      title: "Idioma",
      suggested: "Sugerido",
      enUs: "Inglês (US)",
      ptBr: "Português  (PT-BR)",
      esp: "Espanhol (ESP)",
    },
    settings: {
      title: "Configurações",
      shareLocalization: "Compartilhar Localização",
      shareLocalizationDesc: "Text about what means Share Localization bla bla",
      sharePersonalInfo: "compartilhar Informações Pessoais",
      sharePersonalInfoDesc: "Text about what means Share Personal Info bla bla",
      security: "Segurança",
      deleteAccount: "Excluir Conta",
      deleteAccountDescription:
        "Isto removerá todos os dados. Essa ação não pode ser desfeita. Dados excluídos não podem ser recuperados.",
      delete: "Excluir",
      cancel: "Cancelar",
      logout: "Sair",
      locationPersonalInfo:
        "Sua localização e seus dados pessoais serão compartilhados, de acordo com os termos de uso.",
      privacyOptions: "Opções de Privacidade",
      accountOptions: "Opções da Conta",
    },
    mint: {
      title: "CRIAR",
      selectImage: "Faça o upload ou tire sua foto",
      nftName: "Nome do NFT",
      tags: "Adicione TAGs separadas por vírgula",
      nftDesc: "Descrição do NFT",
      numCopies: "Quantidade de NFTs",
      creationDate: "Data da Criação",
      creationTime: "Hora da Criação",
      avaliableFrom: "Disponível a partir de",
      location: "Localização",
      next: "PRÓXIMO",
      previous: "ANTERIOR",
      submmitButton: "CRIAR NFT",
      mint: "Criar",
    },
    phoneSignup: {
      inputPhoneMensage:
        "Ao continuar, você receberá uma mensagem de texto para confirmação.Cobranças de serviço de mensagens e dados podem ser aplicadas.",
      inputCodeMensage: "Verifique o código SMS enviado para",
      phoneError: "Seu número é inválido. Tente novamente.",
      codeError:
        "O código de verificação SMS usado para criar a credencial de autenticação de telefone é inválido. Por favor, reenvie a mensagem de verificação SMS e certifique-se de usar o código de verificação correto.",
      confirmButton: "CONFIRMAR",
      enterSMSCode: "Por favor, insira o código de 6 dígitos enviado para",
      resendCode: "REENVIAR CÓDIGO",
    },
    nftpage: {
      nftMintCode: "Código de criação de NFT",
      nftMintCodeCopied: "Código de NFT copiado",
      collect: "COLETAR NFT",
      collected: "NFT COLETADO",
      description: "Descrição",
      seeMore: "veja mais",
      seeLess: "veja menos",
      deleteNft: "Deletar NFT",
      deleteNftDescription:
        "Isto removerá seu NFT. Essa ação não pode ser desfeita. NFT excluído não pode ser recuperado",
      cancel: "Cancelar",
      delete: "Deletar",
      available: "Disponível",
      share: "Compartilhar",
      qrcodeCopy: "Copiar QR-CODE",
      addTags: "+ add tags",
      nftCode: "CÓDIGO NFT",
      contractAddressCopied: "Endereço do Contrato Copiado",
      unknown: "Desconhecido",
      nftUnavailable: "NFT Indisponível",
    },
    collectPage: {
      title: "Coletar NFT",
      enterNFTcode: "Entre com código do NFT",
    },
    personalInfo: {
      title: "Informações Pessoais",
      buttonText: "Completar",
      walletAddress: "Endereço da Carteira",
      name: "Nome",
      email: "E-mail",
      phone: "Telefone",
      profileName: "Perfil do Usuário",
    },
    privacy: {
      title: "Política de Privacidade",
      p1: "Obrigado por visitar https://wakame.app (o “Site”) ou acessar o Wakame App (o “App”). O Site e o App são de propriedade e operados pela Wakame LLC (“Wakame” “Nós ,” “Nosso”, “Nós” ou “Empresa”), uma empresa das BVI, que é a controladora dos dados de Suas Informações Pessoais coletadas durante o uso deste Site ou Aplicativo.",
      p2: "Como parte de nossas operações comerciais diárias, coletamos informações pessoais de nossos clientes e clientes em potencial para fornecer nossos produtos e serviços e garantir que possamos atender às suas necessidades ao fornecer esses produtos e serviços, bem como ao fornecê-los com quaisquer informações respectivas. Ao aceitar esta política de privacidade, você está ciente de que seus dados ainda podem ser compartilhados com empresas terceirizadas selecionadas para fins comerciais e promocionais, como publicidade, marketing e pesquisa de mercado, bem como campanhas promocionais e que, neste caso, Seus dados também estarão sujeitos às políticas de privacidade específicas dessas empresas.",
      p3: "Esta Política de Privacidade (\"Política de Privacidade\") estabelece a base sobre a qual quaisquer Informações Pessoais, conforme definido abaixo, coletamos de Você (\"Você\", \"Seu\", \"Usuário\" ou \"Cliente\"), ou que Você nos fornece, será processado por Nós. Por \"Informações Pessoais\", entendemos qualquer informação que, sozinha ou em combinação com outros dados, permite que Você seja direta ou indiretamente identificado, por exemplo, Seu nome, endereço de e-mail, nome de usuário e detalhes de contato, fornecidos por Você ou coletados por Nós quando Você está usando nosso Site e/ou Aplicativo.",
      p4: "Leia atentamente o seguinte para entender quais dados coletamos, como esses dados são usados e como podem ser compartilhados por nós.",
      h1: "ALTERAÇÕES NESTA POLÍTICA DE PRIVACIDADE",
      p5: "Podemos modificar esta Política de Privacidade de tempos em tempos, o que será indicado alterando a data na parte inferior deste documento. Se fizermos alterações materiais, notificaremos você por e-mail (enviado para o e-mail fornecido por você) , usando um aviso, antes que a alteração entre em vigor ou conforme exigido por lei.",
      h2: "INFORMAÇÕES QUE COLETAMOS",
      p6: "Para acessar nosso site ou aplicativo, preliminarmente não coletamos nenhuma informação pessoal nem nenhum cookie. Sob sua ação de entrar e usar o aplicativo ou site, você deve nos fornecer as informações necessárias para entrar em sua conta, que é o seu endereço de e-mail.",
      p7: "Para realizar a ação de coleta de NFTs no Wakame, seja no Site ou App, você precisa concluir seu cadastro e preencher o formulário de coleta de NFT. Nesse momento, Você nos fornecerá Seu nome completo, foto do perfil, número de telefone, endereço de e-mail e nome de usuário.",
      p8: "Não somos responsáveis por qualquer problema decorrente dos serviços prestados por terceiros, nem por seus sistemas operacionais, ou questões de privacidade, nem pelo conteúdo gerado por terceiros no Wakame.",
      p9: "Quando a coleta de NFTs for reivindicada por Você, conforme declarado acima, solicitaremos e coletaremos Seu nome completo, e-mail, foto do perfil e número de telefone. Você pode ou não concordar com os termos desta Política de Privacidade e fornecer Suas informações. Caso você não concorde com esta Política de Privacidade, você não poderá cunhar ou armazenar esse NFT específico, nem participar de qualquer evento promocional, ou receber qualquer informação ou qualquer campanha relacionada ao NFT. No entanto, seu acesso para a plataforma não será afetado por esta ação.",
      p10: "Não coletamos ou compartilhamos nenhuma informação pessoal sensível sobre você (isso inclui detalhes sobre sua raça ou etnia, crenças religiosas ou filosóficas, vida sexual, orientação sexual, opiniões políticas, filiação sindical, informações sobre sua saúde e genética e dados biométricos).",
      p11: "Todas as informações são coletadas quando você está:",
      l1: "Registro de uma conta;",
      l2: "Usando nossa Plataforma ou Site;",
      l3: "Correspondência conosco por telefone, e-mail, WhatsApp, Telegram ou de outra forma;",
      l4: "Coletando e armazenando as NFTs.",
      h3: "FINS PARA OS QUAIS COLETAMOS SUAS INFORMAÇÕES PESSOAIS",
      p12: "Recolheremos e processaremos as suas informações pessoais da seguinte forma e para os seguintes fins:",
      u1: "Execução de um contrato.",
      p13: "Tratamos dados pessoais para fornecer nossos Serviços, bem como informações sobre Nossos produtos e serviços com base na relação contratual com nossos clientes (ou seja, para cumprir nossas obrigações contratuais);",
      p14: "O processamento de dados pessoais ocorre para permitir a conclusão do nosso processo de integração do cliente e o acesso aos nossos serviços e produtos.",
      u2: "Para salvaguardar interesses legítimos.",
      p15: "Processamos dados pessoais para proteger os interesses legítimos perseguidos por nós ou por terceiros. Um interesse legítimo é quando temos um motivo comercial ou comercial para usar suas informações. Exemplos de tais atividades de processamento incluem o seguinte:",
      l5: "Iniciar ações judiciais e preparar Nossa defesa em processos contenciosos;",
      l6: "Meios e processos Comprometemo-nos a prover segurança de TI e sistemas da Empresa, prevenção de possíveis crimes, segurança patrimonial e controles de acesso;",
      l7: "Medidas para gestão do negócio e desenvolvimento de produtos e serviços;",
      l8: "Compartilhar seus dados com empresas terceirizadas para acordos promocionais celebrados com esses terceiros, e",
      l9: "Gestão de riscos.",
      u3: "Para fornecer a você produtos e serviços ou informações sobre nossos produtos e serviços e para revisar suas necessidades contínuas.",
      p16: "Depois que você nos fornecer suas informações pessoais com sucesso, devemos usar suas informações pessoais para executar nossos serviços e cumprir nossas obrigações para com você. Também é de nosso interesse legítimo tentar garantir que estamos fornecendo os melhores serviços para que possamos pode revisar periodicamente Suas necessidades com base em nossa avaliação de Suas informações pessoais para garantir que Você esteja obtendo o benefício dos melhores produtos e serviços possíveis de Nós.",
      u4: "Para nos ajudar a melhorar nossos produtos e serviços, incluindo serviços de suporte, desenvolver e comercializar novos produtos e serviços.",
      p17: "Podemos, de tempos em tempos, usar as informações pessoais fornecidas por você por meio de seu uso dos serviços e/ou por meio de pesquisas com clientes para nos ajudar a melhorar nossos serviços. É de nosso interesse legítimo usar suas informações pessoais dessa maneira para tentar garantir os mais altos padrões ao fornecer nossos serviços a você.",
      u5: "Para enviar pesquisas a você.",
      p18: "De tempos em tempos, podemos enviar a você pesquisas como parte de nosso processo de feedback do cliente e produto da plataforma (site ou aplicativo). É de nosso interesse legítimo solicitar esse feedback para tentar garantir que fornecemos nosso produtos e serviços do mais alto padrão. No entanto, podemos, de tempos em tempos, também solicitar que você participe de outras pesquisas de terceiros que sejam parceiros ou tenham acordos comerciais conosco e, se você concordar em participar de tais pesquisas, contamos com Seu consentimento para usar as informações pessoais que coletamos como parte de tais pesquisas.",
      u6: "Para fins de marketing.",
      p19: "Podemos usar suas informações pessoais para enviar a você comunicações de marketing por e-mail ou outras formas acordadas (incluindo campanhas de mídia social), para garantir que você esteja sempre atualizado com nossos serviços mais recentes. Se enviarmos a você comunicações de marketing, faça isso com base em Seu consentimento e preferências de marketing registradas. Além disso, também podemos compartilhar seus dados com empresas parceiras selecionadas para fins específicos de promoção comercial, marketing e pesquisa de mercado relacionados ao uso de nossos produtos e serviços.",
      u7: "Para fins de negócios internos e manutenção de registros.",
      p20: "Podemos precisar processar suas informações pessoais para fins comerciais e de pesquisa internos, bem como para fins de manutenção de registros. Tal processamento é de nossos interesses legítimos e é necessário para cumprir nossas obrigações legais. Isso pode incluir quaisquer comunicações que nós temos com Você sobre os produtos e serviços que fornecemos a Você e nosso relacionamento com Você. Também manteremos registros para garantir que Você cumpra Suas obrigações contratuais sob os Termos e Condições que regem nosso relacionamento com Você.",
      p21: "As informações pessoais que mantemos sobre você devem ser precisas e atuais. Por favor, mantenha-nos informados se suas informações mudarem durante seu relacionamento conosco.",
      h4: "SEGURANÇA E CONFIDENCIALIDADE",
      p22: "A Empresa respeita a privacidade de todos os usuários que acessam o Site ou o Aplicativo e, portanto, está empenhada em tomar todas as medidas razoáveis para proteger quaisquer clientes existentes ou potenciais, candidatos e visitantes do site. Estamos empenhados em proteger sua privacidade .",
      p23: "A Empresa mantém todos os dados pessoais de seus clientes e de seus clientes em potencial de acordo com as leis e regulamentos de privacidade e proteção de dados aplicáveis. Temos as medidas e procedimentos técnicos e organizacionais necessários e apropriados para garantir que suas informações permaneçam seguras o tempo todo.",
      p24: "Nossas operações são suportadas por uma rede de computadores, servidores e outras infraestruturas e tecnologia da informação, incluindo, entre outros, provedores de serviços terceirizados, como MongoDB App Services (MongoDB Inc).",
      p25: "Informações pessoais e outros dados podem, portanto, ser exportados para fora da jurisdição em que você reside. Suas informações pessoais podem ser processadas e armazenadas em um país ou país estrangeiro. Nessas circunstâncias, os governos, tribunais, autoridades policiais ou reguladores as agências desse país ou desses países podem obter acesso às suas informações pessoais por meio de leis estrangeiras. Você precisa estar ciente de que os padrões de privacidade desses países podem ser mais baixos do que os da jurisdição em que você reside.",
      p26: "Infelizmente, a transmissão de informações pela Internet não é totalmente segura. Embora façamos o possível para proteger suas informações pessoais, não podemos garantir a segurança de seus dados transmitidos a nós por e-mail ou pelo site; qualquer transmissão é Seu próprio risco. Assim que recebermos seus dados, usaremos procedimentos rígidos e recursos de segurança para tentar impedir o acesso não autorizado.",
      h5: "DIVULGAÇÕES",
      p27: "A Empresa não divulgará nenhuma Informação Confidencial de seu cliente a terceiros, exceto nas seguintes situações, nas quais Você concorda que Temos o direito de compartilhar Suas Informações Pessoais:",
      l10: "Na medida em que seja exigido por quaisquer leis, regras ou regulamentos aplicáveis;",
      l11: "Qualquer membro da Empresa, o que significa que qualquer uma de nossas afiliadas e subsidiárias pode receber tais informações;",
      l12: "Qualquer um de nossos provedores de serviços e parceiros de negócios, para fins comerciais, como consultores especializados que foram contratados para nos fornecer serviços administrativos, financeiros, jurídicos, tributários, de conformidade, seguros, TI, recuperação de dívidas, análises, pesquisas, ou outros serviços;",
      l13: "Terceiros selecionados, incluindo provedores de mecanismos de pesquisa e análises que nos auxiliam na melhoria e otimização dos Serviços;",
      l14: "Terceiros selecionados para fins de promoção comercial, pesquisa de mercado e publicidade;",
      l15: "Quando você coleta qualquer NFT em nosso Site ou App, para fins de ações promocionais específicas;",
      l16: "Se houver dever de divulgação;",
      l17: "Se nossos interesses comerciais legítimos exigirem divulgação;",
      l18: "A seu pedido ou com o seu consentimento ou para aqueles descritos nesta Política de Privacidade.",
      p28: "Se a Empresa divulgar Suas informações pessoais a prestadores de serviços e parceiros de negócios, para executar os serviços solicitados pelos clientes, tais fornecedores e parceiros podem armazenar Suas informações pessoais em seus sistemas para cumprir suas obrigações legais e outras. Exigimos esse serviço fornecedores e parceiros de negócios que processam informações pessoais reconhecem a confidencialidade dessas informações, comprometem-se a respeitar o direito de qualquer cliente à privacidade e a cumprir todas as leis relevantes de privacidade e proteção de dados e esta Política de Privacidade.",
      p29: "Todos os usuários podem acessar e verificar suas informações pessoais mantidas por nós enviando uma solicitação por escrito para support@wakame.app e exigir, se necessário, retificar quaisquer informações pessoais imprecisas que possamos manter; ter suas informações pessoais apagadas em determinados circunstâncias (ou seja, quando as Informações Pessoais não são mais necessárias e para cumprir Nossos propósitos de processamento); restringir o processamento de Suas Informações Pessoais onde, por exemplo, as informações são imprecisas ou não é mais necessário para Nós processar essas informações ou onde Você exerceu Seu direito de se opor ao nosso processamento; e teve Seus dados transferidos para um novo provedor de serviços se Você não deseja mais usar os Serviços. Você pode exercer esses direitos entrando em contato conosco.",
      p30: "Podemos precisar solicitar informações específicas de você para nos ajudar a confirmar sua identidade e garantir seu direito de acessar suas informações pessoais (ou exercer qualquer um de seus outros direitos). Esta é uma medida de segurança para garantir que as informações pessoais não sejam divulgado a qualquer pessoa que não tenha o direito de recebê-lo. Também podemos entrar em contato com você para solicitar mais informações sobre sua solicitação para agilizar nossa resposta.",
      p31: "Esta Política de Privacidade e o uso de Suas Informações Pessoais se aplicam apenas às informações que Você fornece ou que são coletadas com Seu consentimento no uso do Aplicativo ou acesso ao Site. Você está avisado de que, se divulgar informações pessoais ou dados pessoais sensíveis por meio do uso da Internet, como salas de bate-papo, comunidades, quadros de avisos ou outros fóruns on-line públicos, essas informações podem ser coletadas e usadas por outras pessoas ou empresas quais a Companhia não tem controle. É sua responsabilidade revisar as declarações de privacidade, políticas, termos e condições de qualquer pessoa ou empresa a quem você escolher vincular ou com quem escolher contratar. Não somos responsáveis pelas declarações de privacidade, políticas, termos, condições ou outro conteúdo de qualquer Site que não seja de nossa propriedade ou administrado por nós.",
      h6: "ALTERAÇÕES À NOSSA POLÍTICA DE PRIVACIDADE",
      p32: "Quaisquer alterações que fizermos em nossa política de privacidade no futuro serão publicadas nesta página e, quando apropriado, notificadas a você por e-mail. Verifique com frequência para ver quaisquer atualizações ou alterações em nossa política de privacidade.",
      h7: "SE VOCÊ TEM UMA RECLAMAÇÃO",
      p33: "Se você tiver alguma dúvida sobre como lidamos com suas informações pessoais, entre em contato conosco em primeiro lugar. Faremos o possível para resolver sua preocupação. Dúvidas, comentários e solicitações relacionadas a esta política de privacidade devem ser endereçadas a contact@ wakame.aplicativo.",
      i1: "22 de julho",
    },
    terms: {
      title: "TERMOS DE SERVIÇO E CONDIÇÕES DE USO DA PLATAFORMA WAKAME",
      p1: 'Estes Termos e Condições de Uso ("Termos") são celebrados entre Você ("Usuário" ou "Você") e a WAKAME ("Empresa", "Nós", "Nós" ou "Nosso") em relação ao Seu acesso e uso para e de Nossa Plataforma (o "Site" e/ou aplicativo móvel, aqui referido como "App") incluindo, sem limitação, a coleta, através de airdrops de tokens não fungíveis ("NFTs"), criação de endereços de carteira criptográfica e interação com nossos serviços online e/ou móveis e software fornecido em ou em conexão com esses serviços (coletivamente, os "Serviços").',
      p2: "WAKAME É UMA PLATAFORMA ELETRÔNICA PARA VER, COLETAR E ARMAZENAR NFTs, BEM COMO INTERAGIR COM MARCAS E PROMOÇÕES DE TERCEIROS ATRAVÉS DESTA FERRAMENTA. NÃO SOMOS CORRETOR, INSTITUIÇÃO FINANCEIRA OU CREDOR. A WAKAME NÃO FACILITA TRANSAÇÕES ENTRE USUÁRIOS EM TROCA POR DINHEIRO OU CRIPTOMOEDAS SENDO IMPOSSÍVEL REALIZAR TRANSAÇÕES EM TROCA DE DINHEIRO OU CRIPTOMOEDAS PELOS TOKENS NÃO FUNGÍVEIS ENTRE QUAISQUER USUÁRIOS E/OU EMPRESAS DENTRO DA PLATAFORMA.",
      p3: "Ao criar uma conta na Plataforma, Você reconhece e concorda com estes Termos, com nossa Política de Privacidade (wakame.app/privacy) e com todos os termos, diretrizes e regras adicionais estabelecidos na Plataforma, que são todos incorporados por referência a estes Termos e expressamente acordados e reconhecidos pelo Usuário.",
      p4: "Você também deve monitorar a Plataforma ou Seu e-mail para quaisquer anúncios da Empresa, pois podemos adicionar ou alterar estes Termos de tempos em tempos. A Empresa reserva-se o direito de alterar estes Termos a seu próprio critério, e sem necessidade de apresentar qualquer justificação, mediante simples aviso na Plataforma.",
      t1: "DEFINIÇÕES",
      p5: "Nestes Termos e em todos os documentos aqui incorporados por referência, as seguintes palavras têm os seguintes significados, salvo indicação em contrário:",
      l1: '"Afiliada" significa, em relação à Empresa ou ao Usuário, uma subsidiária direta ou indireta da Parte, uma holding da Parte e qualquer outra subsidiária dessa holding;',
      l2: '"Aplicativo" significa o aplicativo móvel desenvolvido e fornecido por Nós onde a Plataforma será alocada e onde o Usuário poderá acessar a Plataforma;',
      l3: '"Copyrights" tem o significado de propriedade exclusiva de todos os materiais na Plataforma;',
      l4: '"Indivíduo" é uma Pessoa capaz de usar a Plataforma e os Serviços de acordo com estes Termos;',
      l5: '"Leis" significa todas as leis, estatutos, ordens, regulamentos, regras, tratados e/ou obrigações ou requisitos oficiais decretados, promulgados, emitidos, ratificados, executados ou administrados por qualquer Governo que se aplicam a Você na Plataforma; ',
      l6: '"Marcas" significa todas as marcas comerciais, marcas de serviço e nomes comerciais, incluindo marcas nominativas e marcas de design relacionadas à WAKAME;',
      l7: '"NFT" – significa Token não fungível, que é um tipo de chave eletrônica criptográfica (Token) emitida exclusivamente. A titularidade do NFT é garantida através do certificado digital de autenticidade e unicidade, neste caso, do conteúdo digital vinculado ao token criptográfico não fungível cunhado na Plataforma e distribuído aos usuários que queiram recolhê-lo.',
      l8: '"Pessoa" inclui um indivíduo, associação, parceria, corporação, outro órgão corporativo, fundo fiduciário, patrimônio e qualquer forma de organização, grupo ou entidade reconhecida como personalidade jurídica;',
      l9: '"Serviços" significa qualquer serviço ou produto disponibilizado a Você na ou por meio da Plataforma ou fornecido a Você por Nós.',
      l10: '"Services" means any service or product made available to You on or through the Platform or provided to You by Us.',
      l11: '"Site" significa o nosso site onde a Plataforma será alocada e onde o Usuário poderá acessar a Plataforma;',
      l12: '"Termos" significa estes termos e condições, pois podem ser alterados, alterados ou atualizados de tempos em tempos, incluindo as seguintes políticas e páginas de outras plataformas;',
      l13: '"Tokens" é um registro criptografado em uma rede distribuída de computadores chamada blockchain;',
      l14: '"Usuário" significa a Pessoa ou Entidade que/cuja utiliza a Plataforma e os Serviços.',
      l15: '"WAKAME" é uma plataforma eletrônica acessível por meio de seu Site ou App que permite a exibição, e permite a cunhagem e armazenamento de NFTs criados por Usuários ou Empresas para os Usuários na forma de airdrops gratuitos e promocionais, contendo Conteúdo digital, representado em um NFT.',
      t2: "I. CONTA DE USUÁRIO",
      p6: "Você pode precisar se registrar em nosso aplicativo ou site para acessar e usar determinados recursos da plataforma. Se você optar por se registrar na plataforma, concorda em fornecer e manter informações verdadeiras, precisas, atuais e completas sobre você como solicitados em nosso formulário de registro. Os dados de registro e algumas outras informações sobre você são regidos por nossa Política de Privacidade, com a qual você concorda ao fazer login na Plataforma. Você deve ter pelo menos 16 anos de idade para se registrar em uma conta como Usuário. Você é o único responsável pela segurança de sua conta e por qualquer coisa que ocorra quando você (ou qualquer pessoa não autorizada que roube seus dados) esteja conectado à sua conta, seja por meio do aplicativo ou da plataforma da Web.",
      p7: "Você é responsável por manter a confidencialidade, segurança e acesso privado de sua conta e senha, e é totalmente responsável por todas e quaisquer atividades que ocorram sob o uso de ou conta. Você concorda expressamente em (a) notificar imediatamente a WAKAME de qualquer uso não autorizado ou tentativa de uso de sua conta ou qualquer tentativa não autorizada identificada de roubar sua senha ou conta ou qualquer outra violação de segurança que você identificar. Não seremos responsáveis por qualquer perda ou dano decorrente do não cumprimento desta Seção e por qualquer perda de qualquer NFT armazenado em sua conta.",
      p8: "Você concorda que a WAKAME tem o direito de suspender, pausar ou cancelar imediatamente sua conta ou acesso à plataforma, ou encerrar sua conta se você incitar, favorecer, representar e/ou facilitar quaisquer atividades ou práticas impróprias ou ilegais, como atividades, discriminação de qualquer natureza, descumprimento de quaisquer direitos fundamentais ou obrigações legais, prática de crimes contra a honra, a vida ou o patrimônio, ocultação ou prestação de informações falsas e violação destes Termos de Uso.",
      p9: 'Você só poderá cunhar ("Coletar") um NFT por coleção.',
      t3: "II. COMUNICAÇÃO",
      p10: "Você concorda que podemos enviar comunicações por e-mail ou mensagem a Você relacionadas ao status do recebimento de uma NFT e outras comunicações relacionadas à Sua interação com a Plataforma, incluindo, mas não se limitando a, notificações sobre o status de um campanha NFT ou airdrop que você está interessado em participar.",
      p11: "Você concorda que nós e terceiros que têm acesso aos seus dados por meio da coleta de NFTs podemos enviar comunicações promocionais a você por e-mail, incluindo, entre outros, boletins informativos, ofertas especiais, pesquisas e outras notícias e informações que Consideramos ser do seu interesse. É importante que saiba que pode cancelar comunicações promocionais a qualquer momento seguindo as instruções fornecidas, no entanto, ao cancelar essas comunicações poderá não ser elegível para participar em algumas campanhas promocionais.",
      t4: "III. PROPRIEDADE INTELECTUAL DA WAKAME",
      p12: "Todo o conteúdo, ou seja, textos, audiovisuais, obras musicais, marcas, fotografias, software e outros conteúdos protegidos por direitos de propriedade intelectual são de propriedade exclusiva de terceiros que autorizaram seu uso na Plataforma e desenvolveram o conteúdo e estão protegidos pelas leis e tratados internacionais, sendo negada a sua cópia, reprodução ou qualquer outro uso, os infratores estão sujeitos às correspondentes sanções civis e criminais.",
      p13: "Todo e qualquer conteúdo relacionado ao funcionamento da WAKAME, incluindo o código-fonte da ferramenta, a marca registrada Wakame, software desenvolvido, aplicativo e site são de propriedade exclusiva da WAKAME e não devem ser replicados, copiados, usados ou projetado revertido por você.",
      p14: "A propriedade intelectual resultante da prestação dos Serviços será de propriedade integral da Empresa. A Empresa será a única proprietária com relação aos direitos autorais, domínios, códigos, soluções raiz, software não público ou de código aberto e codificação, patentes, marcas registradas, segredos comerciais e quaisquer outros direitos de propriedade intelectual sobre os serviços desenvolvidos sob o escopo deste Contrato.",
      t5: "IV. CREATION OF CONTENT BY THE USER",
      p15: "O Utilizador obriga-se a utilizar a Plataforma e o(s) seu(s) conteúdo(s) de acordo com o ordenamento jurídico, devendo abster-se de obter, ou tentar obter, os conteúdos por meios diversos daqueles que, em cada caso, tenham sido disponibilizados para o efeito .",
      p16: 'Os Usuários que se cadastrarem e fornecerem as informações adequadas solicitadas poderão criar NFTs na Plataforma ("Provedor de Conteúdo"). Ao criar qualquer conteúdo e NFT na Plataforma, o Usuário garante que todo e qualquer conteúdo carregado ou usado em conexão com o uso do WAKAME não viola nenhum direito de propriedade intelectual de terceiros.',
      p17: "O Provedor de Conteúdo está limitado a gerar coleções de no máximo 100.000 NFTs por coleção. Se a WAKAME identificar que o Usuário está fazendo spam ao criar várias coleções de NFTs, poderá, a seu critério, suspender ou excluir a conta dos Criadores de Conteúdo sem qualquer aviso prévio.",
      p18: "A NFT gerada deve observar os requisitos mínimos de sua criação, como tipo de documento e tamanho da imagem, que deve ser limitada a um tamanho de documento de 20MB (Megabytes), utilizando o formato .JPEG ou .PNG. O coleções ou NFTs individuais devem ter um nome, uma descrição e indicar a data de criação.",
      b1: "WAKAME não é responsável e não será responsável por qualquer violação de direitos de propriedade intelectual resultante do conteúdo carregado ou usado pelo usuário na plataforma.",
      p19: "A WAKAME tem o direito de remover todo e qualquer conteúdo fornecido, criado ou carregado pelo usuário por meio da plataforma a seu próprio critério.",
      p20: "A WAKAME não é responsável por quaisquer danos causados ao usuário ou a qualquer outra pessoa física ou jurídica após a remoção do referido conteúdo.",
      p21: "Todo conteúdo criado ou fornecido pelo Provedor de Conteúdo não pode conter informações falsas, não autorais não acompanhadas da devida referência, de cunho racista ou homofóbico, ou qualquer conteúdo que configure algum crime contra qualquer terceiro, sendo o Provedor de Conteúdo o único responsável por qualquer dano causado por qualquer material gerado e carregado na Plataforma e cunhado como NFT, mesmo que seja publicado na Plataforma.",
      p22: "Caso o Provedor de Conteúdo esteja envolvido ou pratique quaisquer atos ou atitudes mencionados no item acima, que possam denegrir a imagem da Plataforma, a WAKAME poderá suspender e/ou retirar seu acesso e as NFTs geradas, sem necessidade de prévia notificação, e não será responsável e não terá obrigação de indenizar por qualquer dano resultante desta ação.",
      p23: "Se a WAKAME for processada, condenada ou sofrer qualquer dano devido a um processo administrativo ou judicial relacionado ao conteúdo gerado e fornecido pelo Provedor de Conteúdo, incluindo danos à imagem e marca, o Provedor de Conteúdo deverá reembolsar todos os custos e reparar todos os danos causados à WAKAME, desde danos materiais bem como os resultantes da denegrição da sua imagem e Plataforma.",
      t6: "V. QUEIXAS DECORRENTES DE VIOLAÇÃO DE DIREITOS",
      p24: "A WAKAME respeita a propriedade intelectual e os direitos de terceiros e pedimos aos nossos usuários que façam o mesmo. Se você acredita que seu trabalho foi infringido em termos de direitos autorais e/ou propriedade industrial, ou que seus direitos foram infringidos de outra forma , você deve notificar a WAKAME sobre sua reclamação de violação de acordo com o procedimento estabelecido abaixo.",
      p25: 'A WAKAME investigará notificações envolvendo suposta violação e tomará as medidas cabíveis de acordo com as leis aplicáveis com relação a qualquer reclamação de violação de direitos. A notificação com a descrição do evento deve ser enviada para o e-mail support@wakame.app (Linha de assunto: "Reclamação de direitos autorais").',
      t7: "VI. REPRESENTAÇÃO OU GARANTIA",
      p26: "Para acessar alguns dos Serviços, os Usuários podem precisar aprimorar ou atualizar o hardware ou software em seus sistemas de computador ou atualizar seus telefones celulares. A WAKAME não assume nenhuma responsabilidade por qualquer falha no acesso aos Serviços, parcial ou totalmente, seja devido ao sistema do Usuário, à rede de Internet ou a qualquer outra causa.",
      p27: "A WAKAME não garante que o acesso ao Site, App ou qualquer um dos Serviços seja ininterrupto ou livre de erros. No entanto, a WAKAME não será responsável por qualquer recurso não acessível ou por qualquer indisponibilidade dos Serviços. ",
      p28: "Você declara e garante que:",
      al1: "Nosso Serviço não permite que Você venda, compre, envie ou adquira NFTs. A WAKAME não é responsável por nenhuma transação feita por Você, diretamente no Blockchain que suporta a cunhagem dos NFTs e que armazena as informações dos mesmos. ",
      al2: "Existem riscos associados ao uso de uma estrutura baseada na Internet, incluindo, entre outros, o risco de hardware, software e conexões com a Internet, o risco de introdução de software malicioso e o risco de terceiros obterem acesso não autorizado a informações armazenadas em sua carteira. Você aceita e reconhece que a WAKAME não será responsável por quaisquer falhas de comunicação, interrupções, erros, distorções ou atrasos que você possa experimentar ao usar os NFTs, no entanto, causados.",
      al3: "O regime regulatório que rege as tecnologias blockchain, criptomoedas e tokens é incerto, e novos regulamentos ou políticas podem afetar adversamente o desenvolvimento do Leilão e/ou Serviço e a utilidade dos NFTs.",
      al4: "Existem riscos associados ao recebimento de conteúdo gerado pelo usuário, incluindo, entre outros, o risco de comprar NFTs falsificados e rotulados incorretamente e ativos que são ou podem se tornar intransferíveis ou excluídos no futuro. A Wakame se reserva o direito de ocultar coleções, contratos e ativos afetados por qualquer um desses problemas ou por outros problemas.",
      p29: "Você não pode:",
      al5: "Usar a Plataforma se alguma Lei aplicável proibir, penalizar, sancionar ou expor o Site, Aplicativo ou Plataforma a qualquer responsabilidade por quaisquer Serviços fornecidos ou oferecidos a Você;",
      al6: "Use a Plataforma se Você residir em países que proíbem o recebimento, armazenamento e uso de qualquer tipo de ativos criptográficos ou criptomoedas;",
      al7: "Usar a plataforma para interferir ou subverter os direitos ou obrigações da WAKAME ou os direitos ou obrigações de qualquer outro site, aplicativo, usuário ou qualquer outro terceiro ou causar responsabilidade legal para a WAKAME, outro site, aplicativo ou usuário ;",
      al8: "Aproveitar qualquer falha técnica, mau funcionamento, falha, atraso, inadimplência ou violação de segurança no Site, Aplicativo ou Plataforma;",
      p30: "Uma violação de qualquer um destes Termos pode resultar no encerramento da conta do Usuário ou no acesso à Plataforma. Qualquer tentativa sua de interromper ou interferir nos Serviços, incluindo, sem limitação, minar ou manipular a operação legítima do Site ou App, é uma violação destes Termos e pode ser uma violação ou violação das leis criminais e civis.",
      t8: "VII. RISCOS E LIMITAÇÕES DE RESPONSABILIDADE",
      p31: "A Empresa fez e continuará a fazer esforços para incluir informações precisas e atualizadas na Plataforma. No entanto, a WAKAME não pode garantir que as informações postadas na Plataforma sejam precisas, completas ou adequadas para qualquer finalidade. Em nenhum caso a WAKAME, seus diretores, executivos, funcionários, contratados ou agentes serão responsáveis por quaisquer danos incidentais, especiais, consequentes ou indiretos ou quaisquer danos diretos que resultem do uso do desempenho dos Serviços, ou qualquer falha no os serviços.",
      p32: "O usuário assume total responsabilidade pelo uso dos Serviços. A Empresa pode alterar ou remover materiais do Site, Aplicativo, Plataforma ou destes Termos a qualquer momento.",
      p33: "Você entende e concorda que nós, nossas subsidiárias, afiliadas e licenciantes não seremos responsáveis perante você ou terceiros por quaisquer danos indiretos, incidentais, especiais, consequentes ou exemplares que você possa ter, incluindo, mas não limitado a qualquer perda de lucros (tanto direta quanto indiretamente), perda de boa vontade ou reputação comercial, perda de dados, custo de aquisição de bens ou serviços substitutos ou qualquer outra perda intangível, mesmo que tenhamos sido avisados da possibilidade de tais danos.",
      p34: "Existem riscos associados ao uso ou acesso à Plataforma, incluindo, entre outros, falha de hardware, software e conexões de Internet. A Empresa não é responsável pela transmissão adequada e/ou completa das informações contidas em qualquer comunicação eletrônica ou da própria comunicação eletrônica, nem por qualquer interrupção, distorção ou atraso em sua entrega ou recebimento, seja qual for o motivo. Você é o único responsável por fazer backup e manter cópias duplicadas de qualquer informação que você armazene ou transfira por meio da Plataforma.",
      p35: "Você reconhece que as informações transmitidas pela Internet (incluindo comunicações por e-mail) podem ser suscetíveis a acesso e monitoramento ilegais. Além disso, você reconhece que a transmissão de dados ou informações pela Internet pode resultar em perda, interceptação ou alteração enquanto em trânsito e que todas essas informações não serão consideradas confidenciais, e a Empresa não tem nenhuma obrigação fiduciária com relação a essas informações transmitidas.",
      t9: "VIII. ISENÇÃO DE GARANTIAS",
      p36: 'Os Serviços, Plataforma, Site e Aplicativo são fornecidos em toda a extensão legalmente permitida para Você "como está" e "conforme disponível" sem garantia de qualquer tipo, estatutária, expressa ou implícita, incluindo , mas não limitado a, as garantias implícitas de título, não violação, comercialização e adequação a uma finalidade específica. Você assume toda a responsabilidade e risco com relação ao Seu uso da Plataforma.',
      p37: "A Empresa não representa ou garante que as informações, software ou serviços contidos ou fornecidos pela Plataforma estejam em conformidade com as leis aplicáveis de Seu país.",
      p38: "A Empresa não garante que a Plataforma e os Serviços que ela fornece não possam ser duplicados ilegitimamente (em parte ou no todo) por terceiros sem o consentimento prévio por escrito da Empresa.",
      p39: "Se a lei aplicável não permitir que toda ou qualquer parte da exclusão acima de garantias ou isenção de termos implícitos em contratos se aplique a você, as limitações, exclusões e isenções de responsabilidade serão aplicadas a você somente na medida permitida pela lei aplicável .",
      t10: "IX. INDENIZAÇÃO",
      p40: "Você concorda em nos indenizar e defender, incluindo nossas afiliadas e respectivos diretores, executivos e funcionários de e contra todas as perdas, custos, danos, despesas e responsabilidades (incluindo honorários advocatícios razoáveis e desembolsos) que possam ser sofridos por nós decorrente de ou como resultado de ou relacionado de qualquer maneira a uma reivindicação relacionada a:",
      al9: "Seu uso do Site;",
      al10: "Violação destes Termos de Uso por Você;",
      al11: "Qualquer violação de Suas representações e garantias estabelecidas nestes Termos de Uso;",
      al12: "Sua violação dos direitos de terceiros, incluindo, entre outros, direitos de propriedade intelectual.",
      p41: "Não obstante o acima exposto, reservamo-nos o direito, às suas custas, de assumir a defesa e o controle exclusivos de qualquer assunto pelo qual você seja obrigado a nos indenizar, e você concorda em cooperar, às suas custas, com nossa defesa de tal reclamações. Faremos todos os esforços razoáveis para notificá-lo sobre qualquer reclamação, ação ou processo que esteja sujeito a esta indenização ao tomarmos conhecimento dela.",
      t11: "X. DIVERSOS",
      p42: "Não obstante qualquer coisa aqui contida, a Empresa se reserva o direito, sem aviso e a seu exclusivo critério, de rescindir estes Termos, suspender Seu direito de acessar a Plataforma ou Serviços e todas as informações e arquivos relacionados sem responsabilidade para Você, a seu critério critério exclusivo, incluindo, entre outros, em caso de violação destes Termos ou se a Empresa acreditar que você cometeu fraude ou outra má conduta.",
      p43: "A WAKAME pode, a seu critério e sem responsabilidade perante Você, com ou sem aviso prévio, suspender Seu acesso a toda ou parte da Plataforma ou Serviços. Caso a Empresa rescinda estes Termos, revogue Seu direito de usar ou acessar a Plataforma ou Serviços, na medida permitida pela Lei Aplicável",
      p44: "Nenhuma disposição destes Termos será considerada renunciada, a menos que tal renúncia seja por escrito e assinada pela Parte que se beneficia da aplicação de tal disposição. Nenhuma renúncia de qualquer disposição destes Termos, no entanto, será considerada uma renúncia de um violação subsequente de tal disposição ou uma renúncia de uma disposição semelhante. Além disso, uma renúncia a qualquer violação ou falha em cumprir qualquer termo ou condição destes Termos não afetará, limitará ou renunciará de forma alguma aos direitos de uma Parte aqui em qualquer tempo para impor o cumprimento estrito de todos os termos e condições deste instrumento.",
      p45: "A Empresa pode, a seu exclusivo critério, atribuir qualquer um de seus direitos e/ou delegar suas obrigações sob estes Termos (incluindo, mas não limitado a todos e quaisquer direitos de propriedade intelectual em ou para toda tecnologia, software e código relacionados à Plataforma e Serviços), sem notificar Você ou qualquer outro Usuário.",
      p46: "Se qualquer termo, disposição, acordo ou restrição destes Termos for considerado por um tribunal de jurisdição competente como inválido, ilegal, nulo ou inexequível, o restante dos termos, disposições, acordos e restrições aqui estabelecidos permanecerá em pleno vigor e efeito e de forma alguma será afetado, prejudicado ou invalidado, e a Empresa deverá envidar esforços comercialmente razoáveis para encontrar e empregar um meio alternativo para alcançar o mesmo ou substancialmente o mesmo resultado contemplado por tal termo, disposição, avença ou restrição. Fica estipulado e declarado como intenção das Partes que elas teriam executado os demais termos, disposições, avenças e restrições sem incluir qualquer um dos que possam ser declarados inválidos, ilegais, nulos, ou inexequíveis.",
      p47: "A Empresa não faz declarações sobre as implicações fiscais decorrentes das NFTs ou a posse ou uso delas.",
      p48: "Estes Termos e todos os assuntos decorrentes ou relacionados a estes Termos serão regidos e interpretados de acordo com as leis da Ilha Virgem Britânica, sem consideração a qualquer conflito de provisões legais. Cada parte concorda irrevogavelmente que qualquer reclamação apresentada por isso de qualquer forma decorrente dos Serviços deve ser levado única e exclusivamente a um tribunal estadual ou federal localizado nas Ilhas Virgens Britânicas, e cada parte aceita e se submete irrevogavelmente à jurisdição única e exclusiva de cada um dos tribunais supracitados pessoalmente, geralmente e incondicionalmente, com relação a qualquer ação, processo ou procedimento movido por ela ou contra ela pela outra parte.",
      p49: "Se você não tiver certeza se o uso dos Serviços envolve ou não um uso proibido, ou tiver dúvidas sobre a Plataforma, Site ou Aplicativo, entre em contato conosco em: contact@wakame.app.",
      d: "Julho de 2022",
      cancelTermsMessage: "Você precisa aceitar os termos para continuar!",
    },
    faq: {
      items: [
        {
          code: "what-is-nft",
          title: "O que é NFT?",
          text: "Token não fungível (NFT) é um ativo criptográfico em uma blockchain com um código de identificação e metadados exclusivos. Colete e crie seu NFT no Wakame totalmente grátis :)",
        },
        {
          code: "whats-the-difference-between-collect-and-mint",
          title: "Qual é a diferença entre COLLECT e MINT?",
          text: "COLLECT: coletar NFT. MINT: criar seu próprio NFT",
        },
        {
          code: "how-to-collect-nft",
          title: "Como coletar um NFT?",
          text: "Pressione o botão verde \"Wakame\", escaneie o código QR ou insira um código de 8 dígitos. Depois que o NFT abrir, clique no botão verde \"Collect NFT\" e colete-o",
        },
        {
          code: "how-to-mint-nft",
          title: "Como criar um NFT",
          text: "Pressione o botão verde "+", preencha todas as informações necessárias e pressione o botão verde \"Mint\". É tão fácil! Seu NFT agora está disponível para ser compartilhado via link, código QR ou código de 8 dígitos.",
        },
        {
          code: "what-is-6-digit-code",
          title: "O que é o código de validação?",
          text: "O código de 6 dígitos é usado para validar seu número de telefone. O código de 8 dígitos é utilizado para compartilhar, exibir e coletar NFT.",
        },
        {
          code: "how-to-delete-your-account",
          title: "Como deletar sua conta?",
          text: "Para excluir sua conta, acesse: Configurações > Perfil > Excluir conta > Confirmar.",
        },
      ],
    },
  },
  components: {
    save: "Salver",
    cancel: "Cancelar",
  },
  alerts: {
    sendPhoneCodeError: "Muitas tentativas de login, tente novamente em alguns minutos",
    confirmPhoneCodeError: "Erro ao confirmar código do telefone",
    savePhoneError: "Erro ao salvar número do telefone",
    saveNameError: "Erro ao salvar nome",
    saveEmailError: "Erro ao salvar e-mail",
    saveProfilePictureError: "Erro ao salvar imagem de perfil",
    collectNftError: "Erro ao coletar NFT",
    getNftInfoError: "Erro ao exibir informações do NFT",
    getNftsCollectedError: "Erro ao coletar NFTs",
    getNftsMintedError: "Erro ao criar NFT",
    mintKycIncompleteError: "Por favor, complete seu registro para criar NFTs",
    openPhoneCamera: "Por favor! Abra a câmera do seu telefone para coletar seu NFT",
    nftCollected: "NFT Coletado",
    nftAlreadyCollected: "NFT já coletado",
    nftImageUploadError: "Erro ao enviar sua imagem para o servidor",
    nftSaveError: "Erro ao salvar NFT",
    errorOnRequestGeolocation: "Erro ao solicitar permissão de geolocalização",
    permissionDenied: "Permissão negada!",
    errorShare: "Erro ao compartilhar",
    nftNotFound: "NFT não encontrado",
    nftRemoved: "NFT Removido",
    nftRemovedError: "Erro ao remover NFT",
    getUserPreferencesError: "Erro ao obter as preferências do usuário",
    saveShareLocationError: "Erro ao salvar local de compartilhamento",
    savePersonalInfoError: "Erro ao salvar compartilhar informações pessoais",
    saveNicknameError: "Erro ao salvar nome do usuário",
    nicknameExist: "Nome do usuário já existe, por favor adicione outro",
    errorLocationRequired: "Sua localização precisa ser acessada, revise as permissões",
    errorUploadImage: "Erro ao carregar imagem",
    errorDeleteAccount: "Erro ao deletar conta",
  },
};
