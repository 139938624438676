import React, { useEffect, useState } from "react";
import Home from "./Home";
import InputPhone from "./Signup/InputPhone";
import InputPhoneCode from "./Signup/InputPhoneCode";
import Terms from "./Terms";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import Profile from "./Profile";
import CollectCamera from "./Collect/CollectCamera";
import Privacy from "./Privacy";
import Faq from "./Faq";
import CollectCode from "./Collect/CollectCode";
import CollectMap from "./Collect/CollectMap";
import NftPage from "./NftPage";
import Settings from "./Profile/settings";
import MintNft from "./Mint";
import TermsAndCondition from "./TermsAndCondition";
import PersonalInfo from "./Profile/personalInfo";
import Help from "./Profile/help";
import PrivacyPage from "./Profile/PrivacyPage";

const Root = () => {
  const [initialRouteName, setIinitialRouteName] = useState<string>("home");

  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "/inputphone", element: <InputPhone /> },
    { path: "/inputphonecode", element: <InputPhoneCode /> },
    { path: "/terms", element: <Terms /> },
    { path: "/termsandcondition", element: <TermsAndCondition /> },
    { path: "/profile", element: <Profile /> },
    { path: "/personalinfo", element: <PersonalInfo /> },
    // { path: "/collectcamera", element: <CollectCamera /> },
    { path: "/collectcode", element: <CollectCode /> },
    { path: "/nftpageminted/:token", element: <NftPage /> },
    { path: "/nftpage/:token", element: <NftPage /> },
    { path: "/nftpage/:token/collect", element: <NftPage /> },
    { path: "/collectmap", element: <CollectMap /> },
    { path: "/privacypage", element: <PrivacyPage /> },
    { path: "/privacy", element: <Privacy /> },
    { path: "/faq", element: <Faq /> },
    { path: "/settings", element: <Settings /> },
    { path: "/mint", element: <MintNft /> },
    { path: "/help", element: <Help /> },
  ]);

  return <RouterProvider router={router} />;
};

export default Root;
