import {
  Center,
  HStack,
  Image,
  Pressable,
  ScrollView,
  Skeleton,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { mintNftGallery } from "../../shared/services/nft.service";
import { useToast } from "native-base";
import { translate } from "../../shared/i18n/translate";
import NftHomeImage from "./nft-home-image";
import { auth } from "../../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import Images from "../../assets/images";

interface NFTGallery {
  id: string;
  imagePath: string;
  name: string;
  status: null;
  contractAddress: null;
  numberOfCopies: number;
  token: string;
  startDate: string;
  userId: string;
  txHash: null;
  locationLat: string;
  locationLong: string;
  description: string;
  numberOfCopiesToAvailable: number;
  numberOfCopiesToRequest: number;
  created_at: string;
  updated_at: string;
  tags: Array<{
    id: string;
    name: string;
    counter: number;
    created_at: string;
    updated_at: string;
  }>;
}

const HomeMint = () => {
  const navigate = useNavigate();
  const container = useRef();
  const [nfts, setNfts] = useState<NFTGallery[]>([]);
  const toast = useToast();
  const [height, setHeight] = useState<string>(`${300 * 1.4}px`);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (auth.currentUser) {
      getNfts();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        getNfts();
      }
    });
    return unsubscriber();
  }, []);

  useEffect(() => {
    if (!container.current) return;
    const el: HTMLElement = (container.current as any).children[0];
    setHeight(`${el.getBoundingClientRect().width * 1.4}px`);
  }, [container.current]);

  const getNfts = async () => {
    try {
      setLoading(true);
      const response: any = await mintNftGallery(1000, 0);
      setNfts(response.data.usersNfts.nftList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.show({ title: translate.alerts.getNftsMintedError });
    }
  };

  const getHeight = () => {
    try {
      const el: HTMLElement = (container.current as any).children[0];
      return `${el.getBoundingClientRect().width * 1.4}px`;
    } catch (error) {}
    return "0vh";
  };

  return loading ? (
    <ScrollView w="100%" h="calc(100vh - 110px)" marginTop="10px">
      <HStack
        flexGrow={1}
        flexWrap="wrap"
        paddingX="10px"
        justifyContent="center"
      >
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
        <Skeleton
          w="min(calc(50% - 10px), 300px)"
          h="min(75vw, 400px)"
          m="5px"
          borderRadius="10px"
        />
      </HStack>
    </ScrollView>
  ) : nfts.length ? (
    <ScrollView w="100%" h="calc(100vh - 110px)" marginTop="10px">
      <HStack
        flexGrow={1}
        flexWrap="wrap"
        paddingX="10px"
        justifyContent="center"
        ref={container}
      >
        {nfts.map((nft, indexImg) => (
          <Pressable
            key={`img${indexImg}`}
            onPress={() => {
              navigate(`/nftpageminted/${nft.token}`);
            }}
            w="min(50%, 300px)"
            h="min(75vw, 400px)"
            p="5px"
          >
            <NftHomeImage
              image={nft.imagePath}
              name={nft.name}
              collected={nft.numberOfCopiesToRequest}
              total={nft.numberOfCopies}
              date={nft.created_at}
            />
          </Pressable>
        ))}
      </HStack>
    </ScrollView>
  ) : (
    <VStack
      h="calc(100vh - 350px)"
      justifyContent="center"
      space="70px"
      alignItems="center"
    >
      {/* <VStack>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.swipe1}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.swipe2}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.swipe3}
        </Text>
      </VStack>
      <Image
        source={{ uri: Images.arrowLeft }}
        h="10px"
        w="80vw"
        maxW="300px"
      /> */}
      <Spacer />
      <VStack>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.line1}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.line2}
        </Text>
        <Text
          fontSize="25px"
          lineHeight="35"
          textAlign="center"
          fontFamily="LibreBaskerville"
        >
          {translate.pages.home.descriptionMint.line3}
        </Text>
      </VStack>
    </VStack>
  );
};

export default HomeMint;
