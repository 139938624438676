import {
  Box,
  Button,
  Center,
  CheckIcon,
  HStack,
  Image,
  Input,
  Pressable,
  Select,
  Text,
  VStack,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../../assets/images";
import countryCodes, {
  ICountryCode,
} from "../../../shared/constants/countryCodes";
import { mask as masker, unMask } from "node-masker";
import masks from "../../../shared/constants/mask";
import { useToast } from "native-base";
import CountrySelector from "./countrySelector";
import { getMaskByCountryCode } from "../../../shared/constants/countryPhoneMaskByCode";
import Header from "../../../shared/components/Header";
import ReactCodeInput from "react-code-input";
import { CollectMintContext } from "../../../contexts/collect-mint";

interface InputPhoneProps {}

const InputPhone = ({}: InputPhoneProps) => {
  const [country, setCountry] = useState<ICountryCode>({
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  });
  const toast = useToast();
  const [phone, setPhone] = useState<string>("");
  const [maskTemplate, setMaskTemplate] = useState<string>("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const collectMintContext = useContext(CollectMintContext);

  useEffect(() => {
    if (!country) return;
    const _mask = getMaskByCountryCode(country.code);
    console.log(_mask);
    setMaskTemplate(_mask);
  }, [country]);

  const confirm = async () => {
    navigate("/inputphonecode", {
      state: {
        ...state,
        phone: `${country.dial_code} ${phone}`,
      },
    });
  };

  // const validatePhone = () => {
  //   if (maskTemplate.length > 0) {
  //     return phone.length === maskTemplate.replace(/\D/g, "").length;
  //   } else {
  //     return phone.length > 5;
  //   }
  // };

  return (
    <VStack
      w="100vw"
      h="100vh"
      maxH="600px"
      justifyContent="space-between"
      alignItems="center"
    >
      <Header
        title={collectMintContext.active ? collectMintContext.active : ""}
        backAction={() => navigate("/")}
        avatar={true}
      />
      <Center w="100%" width={"100vw"} maxW="350px">
        <Image source={{ uri: Images.logoCircle }} w="130px" h="130px" alt="" />
      </Center>
      <Text bold fontSize="25px">
        Welcome to Wakame
      </Text>
      <Text>Enter phone to access your account</Text>
      <HStack alignItems="center" space="10px">
        <CountrySelector country={country} setCountry={setCountry} />
        <Input
          onChangeText={(text) => {
            setPhone(
              maskTemplate.length > 0
                ? unMask(masker(text, maskTemplate))
                : text.replace(/\D/g, "")
            );
          }}
          keyboardType="number-pad"
          value={maskTemplate.length > 0 ? masker(phone, maskTemplate) : phone}
          variant="underlined"
        />
      </HStack>
      <Center w="90vw" maxW="500px">
        <Button
          onPress={() => confirm()}
          // disabled={!validatePhone()}
          w="100%"
          // opacity={!validatePhone() ? 0.6 : 1}
        >
          CONTINUE
        </Button>
      </Center>
      <Center w="100%">
        <Text textAlign="center">
          You will receive an SMS for confirmation.
        </Text>
        <Text textAlign="center">
          Messaging and data service charges may apply.
        </Text>
      </Center>
    </VStack>
  );
};

export default InputPhone;
