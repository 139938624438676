import api from "./api.service";

export const nftCollect = (token: any) => {
  return api.post("/collectNft", { token });
};

export const nftRequest = (nftId: string) => {
  return api.post("/requestNft", { nftId });
};

export const collectNftGallery = (pageSize: number, currentPage: number) => {
  return api.post(`/userNftGallery?page_size=${pageSize}&current_page=${currentPage}`, {});
};

export const mintNftGallery = (pageSize: number, currentPage: number) => {
  return api.post(`/nftInfoGallery?page_size=${pageSize}&current_page=${currentPage}`, {});
};

export const saveNftInfo = (nftData: any) => {
  return api.post('/saveNftInfo', nftData);
};

export const getNftInfo = (token: any) => {
  return api.post('/getNftInfo', { token });
};

export const getCollectedNftInfo: any = (token: any) => {
  return api.post('/getCollectedNftInfo', { token });
};

export const isNftOwner = (token: any) => {
  return api.post('/isNftOwner', { token });
};

export const deleteCollectedNft = (id: any) => {
  return api.post('/deleteCollectedNft', { id });
}

export const disableNft = (token: any) => {
  return api.post('/disableNft', { token });
}