import {
  AddIcon,
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  HStack,
  IconButton,
  Image,
  Input,
  Link,
  MinusIcon,
  Modal,
  Pressable,
  Skeleton,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "native-base";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Images from "../../assets/images";
import {
  deleteCollectedNft,
  disableNft,
  getCollectedNftInfo,
  getNftInfo,
  isNftOwner,
  nftCollect,
} from "../../shared/services/nft.service";
import { useToast } from "native-base";
import { translate } from "../../shared/i18n/translate";
import { auth } from "../../firebase.config";
import { shareImage, shareLink } from "../../shared/services/share.service";
import copyToClipboard from "../../shared/services/copyToClipboard.service";
import Header from "../../shared/components/Header";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import { svg2jpeg, toFile } from "../../shared/services/file.service";
import { reverseGeocoding } from "../../shared/services/geocoding.service";
import { IGetNftCollectedResponse, NftInfo } from "../../shared/interfaces/nft";

const NftPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { token } = useParams();
  const { state } = useLocation();
  const [fromMint, setFromMint] = useState<boolean>(false);
  const carouselContainer = useRef(null);
  const [nft, setNft] = useState<NftInfo>();
  const toast = useToast();
  const [nftCollected, setNftCollected] = useState<boolean>();
  const [loadingCollect, setLoadingCollect] = useState<boolean>(false);
  const [modalQrCode, setModalQrCode] = useState<boolean>(false);
  const qrCodeRef = useRef<any>();
  const [locationInfo, setLocationInfo] = useState<String>("");
  const [buttonTopHeight, setButtonTopHeight] = useState<any>();

  const [alertMsg, setAlertMsg] = useState("");
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingRemoveNft, setLoadingRemoveNft] = useState<boolean>(false);
  const carousel = useRef(null);
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [showSeeMore, setShowSeeMore] = useState<boolean>(true);
  const textDesc = useRef<any>(null);
  const [collapsableDeleteNft, setCollapsableDeleteNft] =
    useState<boolean>(true);

  const isFromMint = () => {
    return location.pathname.split("/")[1] === "nftpageminted";
  };

  useLayoutEffect(() => {
    function updateSize() {
      setButtonTopHeight(`calc(${window.innerHeight}px - 75px)`);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (isFromMint()) {
      setFromMint(true);
    }
  }, [location]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        checkIsCollected();
      }
    });
    if (token) {
      getNftByToken();
      if (!state) return;
    } else {
      setLoading(false);
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (nftCollected !== undefined && nft) {
      if (window.location.href.split("/").reverse()[0] === "collect") {
        if (!nftCollected) {
          collectNft();
        } else {
          toast.show({ title: translate.alerts.nftAlreadyCollected });
          navigate(`/nftpage/${token}`);
          setLoading(false);
        }
      } else {
        if (nftCollected) {
          getNftByToken();
        }
        setLoading(false);
        if (nft.locationLat && nft.locationLong) {
          getReverseGeolocation(
            Number(nft.locationLat),
            Number(nft.locationLong)
          );
        } else {
          setLocationInfo("");
        }
      }
    }
  }, [nftCollected]);

  useEffect(() => {
    if (carouselContainer.current) {
      const container = carouselContainer.current as HTMLDivElement;
      setImageHeight(container.getBoundingClientRect().height);
    }
  }, [carouselContainer.current]);

  const getReverseGeolocation = async (lat: number, lon: number) => {
    try {
      const response = await reverseGeocoding(lat, lon);
      setLocationInfo(
        `${response.data.address.city}, ${response.data.address.country}`
      );
    } catch (error) {}
  };

  const checkIsCollected = async () => {
    try {
      const response: any = await isNftOwner(token);
      setNftCollected(response.data.isOwner);
    } catch (error) {
      setNftCollected(false);
    }
  };

  const collectNft = async () => {
    if (auth.currentUser) {
      setLoadingCollect(true);
      try {
        const response: any = await isNftOwner(token);
        if (!response.data.isOwner) {
          await nftCollect(token);
          toast.show({ title: translate.alerts.nftCollected });
          setNftCollected(true);
          getNftByToken();
          setLoadingCollect(false);
          navigate(`/nftpage/${token}`);
        } else {
          toast.show({ title: translate.alerts.nftAlreadyCollected });
          setLoadingCollect(false);
          navigate(`/nftpage/${token}`);
        }
      } catch (error) {
        toast.show({ title: translate.alerts.collectNftError });
        setLoadingCollect(false);
      }
    } else if (token) {
      navigate("/inputphone", {
        state: {
          from: "COLLECT",
          data: token.toLowerCase(),
        },
      });
    } else {
      navigate("/");
    }
  };

  const getNftByToken = async () => {
    try {
      let nft: NftInfo;
      if (isFromMint() || !nftCollected) {
        const response: any = await getNftInfo(token);
        nft = response.data.nft;
      } else {
        const response: { data: IGetNftCollectedResponse } =
          await getCollectedNftInfo(token);
        nft = {
          id: response.data.collectedData.id,
          imagePath: response.data.nftBoilerplateData.imagePath,
          txHash: response.data.collectedData.txHash,
          name: response.data.nftBoilerplateData.name,
          status: response.data.nftBoilerplateData.status,
          contractAddress: response.data.nftBoilerplateData.contractAddress,
          numberOfCopies: response.data.nftBoilerplateData.numberOfCopies,
          token: response.data.nftBoilerplateData.token,
          startDate: response.data.nftBoilerplateData.startDate,
          locationLat: response.data.nftBoilerplateData.locationLat,
          locationLong: response.data.nftBoilerplateData.locationLong,
          description: response.data.nftBoilerplateData.description,
          numberOfCopiesToAvailable:
            response.data.nftBoilerplateData.numberOfCopiesToAvailable,
          numberOfCopiesToRequest:
            response.data.nftBoilerplateData.numberOfCopiesToRequest,
          created_at: response.data.nftBoilerplateData.created_at,
          updated_at: response.data.nftBoilerplateData.updated_at,
          tokenId: response.data.collectedData.tokenId,
          tags: response.data.nftBoilerplateData.tags,
          userId: response.data.userOwnerBoilerplate,
        };
      }
      if (nft) {
        setNft(nft);
      } else {
        toast.show({ title: translate.alerts.nftNotFound });
        navigate("/");
      }
      setLoading(false);
    } catch (error) {
      toast.show({ title: translate.alerts.getNftInfoError });
      setLoading(false);
      navigate("/");
    }
  };

  const removeNft = async () => {
    if (fromMint) {
      try {
        if (!nft) return;
        setLoadingRemoveNft(true);
        await disableNft(nft.token);
        navigate("/");
        toast.show({ title: translate.alerts.nftRemoved });
        setLoadingRemoveNft(false);
      } catch (error) {
        setLoadingRemoveNft(false);
        toast.show({ title: translate.alerts.nftRemovedError });
      }
    } else {
      try {
        if (!nft) return;
        setLoadingRemoveNft(true);
        await deleteCollectedNft(nft.id);
        navigate("/");
        toast.show({ title: translate.alerts.nftRemoved });
        setLoadingRemoveNft(false);
      } catch (error) {
        setLoadingRemoveNft(false);
        toast.show({ title: translate.alerts.nftRemovedError });
      }
    }
  };

  const formatDate = (date?: string) => {
    if (date) {
      return format(new Date(date), "dd LLLL uuuu");
    }
    return "";
  };

  const formatTime = (date?: string) => {
    if (date) {
      return format(new Date(date), "hh:mm a");
    }
    return "";
  };

  const exportQrCode = async () => {
    try {
      const jpeg = await svg2jpeg(qrCodeRef.current.outerHTML);
      const file = await toFile(jpeg, `${nft?.name}.jpeg`);
      await shareImage([file]);
    } catch (error) {
      // copyToClipboard(link);
      toast.show({
        title: translate.alerts.errorShare,
      });
    }
  };

  const getUrl = () => {
    return window.location.href.replace("nftpageminted", "nftpage");
  };

  const getNftToken = () => {
    if (!nft) return "";
    const token = nft.token.toUpperCase().split("");
    return token.join("-");
  };

  return (
    <VStack minH="100vh" w="100vw" alignItems="center" space="15px">
      <Header backAction={() => navigate("/")} avatar={true} fixed={true} />

      {/* TITLE */}
      <HStack
        position="fixed"
        top="60px"
        w="calc(100vw - 50px)"
        maxW="500px"
        background="white"
        zIndex="1"
        paddingBottom="5px"
      >
        <Text bold fontSize="20px">
          {nft?.name}
        </Text>
      </HStack>

      <Center h="70px" />

      {loading ? (
        <Center>
          <Spinner size={40} />
        </Center>
      ) : (
        <>
          {/* BUTTON COLLECT */}
          {nftCollected || fromMint || !nft?.isActive ? (
            <></>
          ) : (
            <Button
              position="fixed"
              top={buttonTopHeight}
              zIndex="1000"
              bgColor="#58EE4A"
              w="calc(100vw - 30px)"
              maxW="520px"
              onPress={() => {
                if (!loadingCollect) {
                  collectNft();
                }
              }}
            >
              {loadingCollect ? (
                <Spinner height="30px" />
              ) : (
                <Text bold fontSize="20px">
                  {translate.pages.nftpage.collect}
                </Text>
              )}
            </Button>
          )}

          {/* MODAL QRCODE */}
          {modalQrCode ? (
            <Center
              position="fixed"
              w="100vw"
              h="100vh"
              backgroundColor="#000000C4"
              zIndex={100000}
            >
              <VStack
                width="80vw"
                maxW="300px"
                backgroundColor="#ffffff"
                borderRadius="12px"
                p="30px"
                alignItems="center"
                space="20px"
                mb="20px"
              >
                <QRCode size={200} value={getUrl()} />
                <Box position="fixed" opacity="0" pointerEvents="none">
                  <QRCode ref={qrCodeRef} size={2000} value={getUrl()} />
                </Box>
                <VStack alignItems="center">
                  <Text fontSize="20px">{translate.pages.nftpage.nftCode}</Text>
                  <HStack space="5px" alignItems="center">
                    <Text fontSize="20px">{getNftToken()}</Text>
                    <Pressable
                      onPress={() => {
                        if (!nft) return;
                        copyToClipboard(nft.token);
                        toast.show({
                          title: translate.pages.nftpage.nftMintCodeCopied,
                        });
                      }}
                    >
                      <Image
                        source={{ uri: Images.clipboardDocument }}
                        size="25px"
                      />
                    </Pressable>
                  </HStack>
                </VStack>
                <Button
                  variant="outline"
                  w="100%"
                  onPress={async () => {
                    if (nft) {
                      exportQrCode();
                    }
                  }}
                >
                  <HStack alignItems="center" space="10px">
                    <Image
                      source={{ uri: Images.share2 }}
                      size="18px"
                      mb="3px"
                    />
                    <Text fontSize="18px" bold>
                      {translate.pages.nftpage.share}
                    </Text>
                  </HStack>
                </Button>
              </VStack>
              <Pressable onPress={() => setModalQrCode(false)}>
                <Image source={{ uri: Images.close }} size="40px" />
              </Pressable>
            </Center>
          ) : (
            <></>
          )}

          {/* IMAGE */}
          <VStack w="calc(100vw - 50px)" maxW="500px" alignItems="center">
            {/* <Pressable onPress={() => {}}>
            <Image
              w="100%"
              h="300px"
              source={{ uri: nft?.imagePath }}
              resizeMode="contain"
              bgColor="#ffffff"
            />
          </Pressable> */}
            <img
              src={nft?.imagePath}
              style={{
                maxHeight: "300px",
                width: "auto",
                maxWidth: "calc(100vw - 50px)",
              }}
              alt=""
            />
          </VStack>

          {/* CREATOR */}
          {nft?.userId ? (
            <HStack
              w="calc(100vw - 50px)"
              maxW="500px"
              alignItems="center"
              space="15px"
            >
              <Avatar
                size="40px"
                bgColor="#000000"
                source={{ uri: nft?.userId.profilePicture }}
              />
              <Text bold>
                {nft?.userId.nickname === null
                  ? translate.pages.nftpage.unknown
                  : nft?.userId.nickname}
              </Text>
            </HStack>
          ) : (
            <></>
          )}

          {/* DESCRIPTION */}
          <HStack w="calc(100vw - 50px)" maxW="500px">
            <Text>{nft?.description}</Text>
          </HStack>

          {/* TAGS */}
          <HStack
            w="calc(100vw - 50px)"
            maxW="500px"
            space="5px"
            flexWrap="wrap"
          >
            {nft?.tags.map((tag, index) => (
              <Center
                key={index}
                background="#D9D9D9"
                borderRadius="6px"
                paddingX="10px"
                h="24px"
                marginBottom="10px"
                shadow="5"
              >
                <Text bold fontSize="14px" lineHeight="14px">
                  {tag.name}
                </Text>
              </Center>
            ))}
          </HStack>

          {/* MAP */}
          {/* <Center
            w="calc(100vw - 50px)"
            maxW="500px"
            background="#000000"
            h="130px"
          >
          </Center> */}

          {/* LOCATION AND TIME */}
          <HStack
            w="calc(100vw - 50px)"
            maxW="500px"
            justifyContent="space-between"
            alignItems="center"
          >
            <VStack>
              <Text>{locationInfo}</Text>
              <Text>{formatDate(nft?.created_at)}</Text>
            </VStack>
            <Text>{formatTime(nft?.created_at)}</Text>
          </HStack>

          {/* AVAILABLE AND TOKEN ID */}
          <HStack
            w="calc(100vw - 50px)"
            maxW="500px"
            justifyContent="space-between"
          >
            <VStack justifyContent="center">
              {fromMint ? (
                <HStack space="10px" alignItems="center">
                  <Text opacity={0.6}>{translate.pages.nftpage.available}</Text>
                  <HStack alignItems="center" space="5px">
                    <Text fontSize="17px">
                      {nft?.numberOfCopiesToAvailable}
                    </Text>
                    <Text opacity={0.6} fontSize="17px">
                      /
                    </Text>
                    <Text opacity={0.6} fontSize="17px">
                      {nft?.numberOfCopies}
                    </Text>
                  </HStack>
                </HStack>
              ) : nftCollected ? (
                <HStack space="10px" alignItems="center">
                  <Text opacity={0.6}>Token ID</Text>
                  <Text fontSize="17px">{nft?.tokenId}</Text>
                </HStack>
              ) : (
                <></>
              )}
            </VStack>
            {fromMint ? (
              <Pressable onPress={() => setModalQrCode(true)}>
                <Center
                  backgroundColor="#F2F2F2"
                  w="50px"
                  h="50px"
                  borderRadius="100%"
                >
                  <Image source={{ uri: Images.qrCode }} size="20px" />
                </Center>
              </Pressable>
            ) : (
              <></>
            )}
          </HStack>

          {/* CONTRACT ADDRESS */}
          <VStack
            w="calc(100vw - 50px)"
            maxW="500px"
            backgroundColor="#F2F2F2"
            borderRadius="6px"
            px="12px"
            py="10px"
          >
            <Text>Contract address:</Text>
            <HStack space="10px">
              <Pressable
                w="calc(100%)"
                onPress={() =>
                  window.open(
                    `https://polygonscan.com/address/${nft?.contractAddress}`,
                    "_blank"
                  )
                }
              >
                <Text underline w="calc(100%)">
                  {nft?.contractAddress}
                </Text>
              </Pressable>

              {/* <Pressable
                onPress={() => {
                  if (!nft || !nft.contractAddress) return;
                  copyToClipboard(nft.contractAddress);
                  toast.show({
                    title: translate.pages.nftpage.contractAddressCopied,
                  });
                }}
              >
                <Image source={{ uri: Images.clipboardDocument }} size="25px" />
              </Pressable> */}
            </HStack>
            <Text>Transaction hash:</Text>
            <HStack space="10px">
              <Pressable
                w="calc(100%)"
                onPress={() =>
                  window.open(
                    `https://polygonscan.com/tx/${nft?.txHash}`,
                    "_blank"
                  )
                }
              >
                <Text underline w="calc(100%)">
                  {nft?.txHash}
                </Text>
              </Pressable>

              {/* <Pressable
              onPress={() => {
                if (!nft || !nft.contractAddress) return;
                copyToClipboard(nft.contractAddress);
                toast.show({
                  title: translate.pages.nftpage.contractAddressCopied,
                });
              }}
            >
              <Image source={{ uri: Images.clipboardDocument }} size="25px" />
            </Pressable> */}
            </HStack>
          </VStack>

          {fromMint ? (
            // SHARE BUTTON
            <Button
              variant="outline"
              w="calc(100vw - 50px)"
              maxW="500px"
              onPress={async () => {
                if (nft) {
                  const link = getUrl();
                  try {
                    shareLink(nft?.name, link);
                  } catch (error) {
                    copyToClipboard(link);
                  }
                }
              }}
            >
              <HStack alignItems="center" space="10px">
                <Image source={{ uri: Images.share2 }} size="18px" mb="3px" />
                <Text fontSize="18px" bold>
                  {translate.pages.nftpage.share}
                </Text>
              </HStack>
            </Button>
          ) : (
            <></>
          )}

          {!nftCollected && !nft?.isActive ? (
            <Center>
              <Text fontSize="20px">
                {translate.pages.nftpage.nftUnavailable}
              </Text>
            </Center>
          ) : (
            <></>
          )}

          {/* NFT COLLECTED */}
          {/* {!fromMint && nftCollected ? (
            <Button bgColor="#58ee4aa4" w="calc(100vw - 50px)" maxW="500px">
              <Text bold fontSize="20px">
                {translate.pages.nftpage.collected}
              </Text>
            </Button>
          ) : (
            <></>
          )} */}

          {/* DELETE NFT */}
          {(!fromMint && nftCollected) || fromMint ? (
            <Box
              w="calc(100vw - 50px)"
              maxW="500px"
              padding={"14px"}
              borderColor="coolGray.300"
              borderWidth="1"
              borderRadius="27px"
            >
              <HStack>
                <Text fontSize={16}>{translate.pages.nftpage.deleteNft}</Text>
                <Spacer />
                <IconButton
                  onPress={() => setCollapsableDeleteNft(!collapsableDeleteNft)}
                  size={"24px"}
                  icon={
                    collapsableDeleteNft ? (
                      <AddIcon color={"black"} />
                    ) : (
                      <MinusIcon color={"black"} />
                    )
                  }
                />
              </HStack>
              {!collapsableDeleteNft && (
                <VStack>
                  <Text paddingY={"10px"}>
                    {translate.pages.nftpage.deleteNftDescription}
                  </Text>
                  <HStack>
                    <Spacer />
                    {loadingRemoveNft ? (
                      <Spinner />
                    ) : (
                      <>
                        <Button
                          w="100px"
                          marginRight={"14px"}
                          variant="outline"
                          onPress={() => setCollapsableDeleteNft(true)}
                        >
                          {translate.pages.nftpage.cancel}
                        </Button>
                        <Button
                          w="100px"
                          colorScheme="warning"
                          onPress={() => {
                            removeNft();
                          }}
                        >
                          {translate.pages.nftpage.delete}
                        </Button>
                      </>
                    )}
                  </HStack>
                </VStack>
              )}
            </Box>
          ) : (
            <></>
          )}

          {/* SPACE ON END */}
          <Center h={nftCollected || fromMint ? "20px" : "100px"} />
        </>
      )}
    </VStack>
  );
};

export default NftPage;
