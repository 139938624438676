import { Center, HStack, Image, Pressable, Text, VStack } from "native-base";
import React from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../../assets/images";
import { translate } from "../../i18n/translate";

const SuppAndHelp = () => {
  const useTranslate = translate.pages.profile;
  const navigate = useNavigate();

  return (
    <VStack alignItems="center" justifyContent="space-between" paddingY="10px" space="20px">
      <Text fontSize="22px" bold>{useTranslate.helpAndSupp}</Text>
      <HStack width="80vw" maxW="400px" justifyContent="space-between">
        <Pressable
          onPress={() => {
            window.open("mailto:support@wakame.app", "_blank");
          }}
        >
          <VStack w="80px" alignItems="center" space="10px">
            <Image
              source={{ uri: Images.email }}
              height="35px"
              width="35px"
              alt={"email"}
            />
            <Text>{translate.pages.profile.mail}</Text>
          </VStack>
        </Pressable>
        <Pressable
          onPress={() => {
            window.open("https://t.me/wakameapp", "_blank");
          }}
        >
          <VStack w="80px" alignItems="center" space="10px">
            <Image
              source={{ uri: Images.telegram }}
              height="35px"
              width="35px"
              alt={"email"}
            />
            <Text>{translate.pages.profile.telegram}</Text>
          </VStack>
        </Pressable>
        <Pressable
          onPress={() => {
            window.open("https://wa.me/5511943094340", "_blank");
          }}
        >
          <VStack w="80px" alignItems="center" space="10px">
            <Image
              source={{ uri: Images.whatsApp }}
              height="35px"
              width="35px"
              alt={"email"}
            />
            <Text>{translate.pages.profile.whatsapp}</Text>
          </VStack>
        </Pressable>
      </HStack>
    </VStack>
  );
};

export default SuppAndHelp;
