import { AxiosError } from "axios";
import {
  onAuthStateChanged,
  updateProfile,
  updateEmail,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
} from "firebase/auth";
import {
  AddIcon,
  Avatar,
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Input,
  MinusIcon,
  Modal,
  Pressable,
  Spacer,
  Spinner,
  Text,
  useToast,
  VStack,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import { UserPreferencesContext } from "../../contexts/user-preferences";
import { auth } from "../../firebase.config";
import Header from "../../shared/components/Header";
import ImagePicker from "../../shared/components/ImagePicker";
import { COOKIE_LANGUAGE_LABEL } from "../../shared/constants/cookie";
import { currentLanguage, translate } from "../../shared/i18n/translate";
import copyToClipboard from "../../shared/services/copyToClipboard.service";
import { uploadCloudStorage } from "../../shared/services/file.service";
import {
  deleteToken,
  generateCryptoAddress,
  postDeleteAccount,
  saveUserInfo,
  userPreferences,
} from "../../shared/services/user.service";

const PersonalInfo = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const userPreferencesContext = useContext(UserPreferencesContext);
  const { state } = useLocation();
  const [fromMint, setFromMint] = useState<boolean>();

  const [editingUsername, setEditingUsername] = useState<boolean>(false);
  const [userUsername, setUserUsername] = useState<string>("");
  const [loadingUsername, setLoadingUsername] = useState<boolean>(false);

  const [editingName, setEditingName] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [loadingName, setLoadingName] = useState<boolean>(false);

  const [editingEmail, setEditingEmail] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");
  const [loadingEmail, setLoadingEmail] = useState<boolean>(false);

  const [editingPhone, setEditingPhone] = useState<boolean>(false);
  const [userPhone, setUserPhone] = useState<string>("");
  const [loadingPhone, setLoadingPhone] = useState<boolean>(false);

  const [profileImage, setProfileImage] = useState<string>("");
  const [currentProfileImage, setCurrentProfileImage] = useState<string>("");
  const imagePickerRef = useRef<any>();
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);
  const [imagePickerModal, setImagePickerModal] = useState<boolean>(false);

  const [walletAddress, setWalletAddress] = useState<string>("");
  const [loadingWalletAddress, setLoadingWalletAddress] =
    useState<boolean>(false);

  const [collapsableDeleteAccount, setCollapsableDeleteAccount] =
    useState<boolean>(true);
  const [loadingDeleteAccount, setLoadingDeleteAccount] =
    useState<boolean>(false);

  useEffect(() => {
    if (userPreferencesContext.preferences) {
      if (
        userPreferencesContext.preferences.nickname &&
        userPreferencesContext.preferences.nickname.length > 0
      ) {
        setUserUsername(userPreferencesContext.preferences.nickname);
      } else {
        setEditingUsername(true);
      }
    }
  }, [userPreferencesContext.preferences]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.displayName) {
          setUserName(user.displayName as string);
        } else {
          setEditingName(true);
        }
        if (user.email) {
          setUserEmail(user.email as string);
        } else {
          setEditingEmail(true);
        }
        getWallet();
      }
    });
  }, []);

  useEffect(() => {
    if (state) {
      if (state.from) {
        setFromMint(state.from === "MINT");
      }
    }
  }, [state]);

  const saveUserPhoto = async () => {
    if (!auth.currentUser) return;
    setImagePickerModal(false);
    setLoadingPhoto(true);
    try {
      const filename = `${auth.currentUser.uid}_photo`;
      const url: any = await uploadCloudStorage(profileImage, filename);
      await saveUserInfo({ profilePicture: url });
      await updateProfile(auth.currentUser, { photoURL: url });
      setCurrentProfileImage(url);
      setProfileImage("");
      setLoadingPhoto(false);
    } catch (error) {
      setLoadingPhoto(false);
      toast.show({ title: translate.alerts.saveProfilePictureError });
    }
  };

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user && user.photoURL) {
        setCurrentProfileImage(user.photoURL);
      }
    });
    return unsubscriber();
  }, []);

  const getWallet = async () => {
    setLoadingWalletAddress(true);
    try {
      const response = await generateCryptoAddress();
      setWalletAddress(response.data.address);
      setLoadingWalletAddress(false);
    } catch (error) {
      setLoadingWalletAddress(false);
    }
  };

  const saveNickname = async () => {
    try {
      if (!auth.currentUser) return;
      setEditingUsername(false);
      setLoadingUsername(true);
      await saveUserInfo({ nickname: userUsername });
      const response = await userPreferences();
      userPreferencesContext.setPreferences(response.data.user);
      setLoadingUsername(false);
    } catch (error: any) {
      if (error.response.status === 304) {
        toast.show({ title: translate.alerts.nicknameExist });
      } else {
        toast.show({ title: translate.alerts.saveNicknameError });
      }
      setLoadingUsername(false);
      setEditingUsername(true);
    }
  };

  const saveName = async () => {
    try {
      if (!auth.currentUser) return;
      setEditingName(false);
      setLoadingName(true);
      await saveUserInfo({ name: userName });
      await updateProfile(auth.currentUser, {
        displayName: userName,
      });
      setLoadingName(false);
    } catch (error) {
      toast.show({ title: translate.alerts.saveNameError });
      setLoadingName(false);
      setEditingName(true);
    }
  };

  const saveEmail = async () => {
    try {
      if (!auth.currentUser) return;
      setLoadingEmail(true);
      setEditingEmail(false);
      await saveUserInfo({ email: userEmail });
      await updateEmail(auth.currentUser, userEmail);
      setLoadingEmail(false);
    } catch (error) {
      toast.show({ title: translate.alerts.saveEmailError });
      setLoadingEmail(false);
      setEditingEmail(true);
    }
  };

  const generateReCaptch = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const saveUserPhone = async () => {
    try {
      if (!auth.currentUser) return;
      setLoadingPhone(true);
      generateReCaptch();
      const phoneProvider = new PhoneAuthProvider(auth);
      const id = await phoneProvider.verifyPhoneNumber(
        userPhone,
        (window as any).recaptchaVerifier
      );
      const cred = PhoneAuthProvider.credential(id, "");
      await updatePhoneNumber(auth.currentUser, cred);
      setLoadingPhone(false);
      setEditingPhone(false);
    } catch (error) {
      setLoadingPhone(false);
      setEditingPhone(false);
    }
  };

  const checkIsComplete = () => {
    const isComplete =
      !!auth.currentUser?.displayName &&
      !!auth.currentUser?.email &&
      !!auth.currentUser?.phoneNumber;
    return isComplete;
  };

  const deleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      const response = await postDeleteAccount();
      setLoadingDeleteAccount(false);
      auth.signOut().then(() => {
        deleteToken();
        navigate("/");
      })
    } catch (error) {
      toast.show({ title: translate.alerts.errorDeleteAccount })
      setLoadingDeleteAccount(false);
    }
  };

  return (
    <VStack
      h="100vh"
      maxH="700px"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Header backAction={() => navigate("/profile")} fixed={true} />

      <Center h="50px" />

      <VStack
        justifyContent="flex-start"
        alignItems="center"
        space="20px"
        h="calc(100vh - 80px)"
        maxH="700px"
      >
        {/* AVATAR */}
        <Box w="100px" h="100px">
          {loadingPhoto ? (
            <Spinner size={40} />
          ) : (
            <Avatar
              source={{
                uri: currentProfileImage ? currentProfileImage : Images.user,
              }}
              w="100px"
              h="100px"
            >
              <Avatar.Badge
                bg="#F5F5F5"
                size={10}
                style={{ borderWidth: 5, borderColor: "#FFFFFF" }}
              >
                <Pressable onPress={() => setImagePickerModal(true)}>
                  <Image
                    source={{ uri: Images.edit }}
                    size={8}
                    alt={"Edit Image Profile"}
                  />
                </Pressable>
              </Avatar.Badge>
            </Avatar>
          )}
        </Box>

        {/* MODAL PHOTO */}
        <Modal
          isOpen={imagePickerModal}
          onClose={() => setImagePickerModal(false)}
        >
          <Modal.Content maxWidth="400px">
            <Modal.CloseButton
              onPress={() => {
                if (imagePickerRef.current) {
                  if (imagePickerRef.current.getShowCamera()) {
                    imagePickerRef.current.hideCamera();
                  } else {
                    setImagePickerModal(false);
                  }
                } else {
                  setImagePickerModal(false);
                }
              }}
            />
            <Modal.Body>
              <ImagePicker
                profileImage={profileImage}
                ref={imagePickerRef}
                setProfileImage={setProfileImage}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <Button
                  variant="ghost"
                  colorScheme="blueGray"
                  onPress={() => {
                    if (imagePickerRef.current) {
                      if (imagePickerRef.current.getShowCamera()) {
                        imagePickerRef.current.hideCamera();
                      } else {
                        setImagePickerModal(false);
                      }
                    } else {
                      setImagePickerModal(false);
                    }
                  }}
                >
                  {translate.components.cancel}
                </Button>
                <Button
                  onPress={saveUserPhoto}
                  disabled={profileImage === null || profileImage === undefined}
                >
                  {translate.components.save}
                </Button>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        {/* TITLE */}
        <Center>
          <Text bold fontSize="22px">
            {translate.pages.profile.account}
          </Text>
        </Center>

        {/* INPUTS */}
        <VStack
          w="calc(100vw - 50px)"
          maxW="500px"
          justifyContent="flex-start"
          space="20px"
        >
          {/* USERNAME */}
          <Input
            type="text"
            w="100%"
            size="lg"
            placeholder={
              userUsername
                ? userUsername
                : translate.pages.personalInfo.profileName
            }
            accessibilityLabel={translate.pages.personalInfo.profileName}
            value={userUsername}
            onChangeText={(text) => setUserUsername(text)}
            isDisabled={!editingUsername}
            InputRightElement={
              editingUsername ? (
                <Pressable onPress={() => saveNickname()} paddingX={5}>
                  <Text>{translate.components.save}</Text>
                </Pressable>
              ) : loadingUsername ? (
                <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
              ) : (
                <Pressable
                  onPress={() => setEditingUsername(true)}
                  paddingX={5}
                >
                  <Image size="20px" source={{ uri: Images.edit }} />
                </Pressable>
              )
            }
          />

          {/* NAME */}
          <Input
            type="text"
            w="100%"
            size="lg"
            placeholder={
              auth.currentUser?.displayName
                ? auth.currentUser?.displayName
                : translate.pages.personalInfo.name
            }
            accessibilityLabel={translate.pages.personalInfo.name}
            value={userName}
            onChangeText={(text) => setUserName(text)}
            isDisabled={!editingName}
            InputRightElement={
              editingName ? (
                <Pressable onPress={() => saveName()} paddingX={5}>
                  <Text>{translate.components.save}</Text>
                </Pressable>
              ) : loadingName ? (
                <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
              ) : (
                <Pressable onPress={() => setEditingName(true)} paddingX={5}>
                  <Image size="20px" source={{ uri: Images.edit }} />
                </Pressable>
              )
            }
          />

          {/* EMAIL */}
          <Input
            type="text"
            w="100%"
            size="md"
            placeholder={
              auth.currentUser?.email
                ? auth.currentUser?.email
                : translate.pages.personalInfo.email
            }
            isDisabled={!editingEmail}
            value={userEmail}
            onChangeText={(text) => setUserEmail(text)}
            InputRightElement={
              editingEmail ? (
                <Pressable onPress={() => saveEmail()} paddingX={5}>
                  <Text>{translate.components.save}</Text>
                </Pressable>
              ) : loadingEmail ? (
                <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
              ) : (
                <Pressable onPress={() => setEditingEmail(true)} paddingX={5}>
                  <Image size="20px" source={{ uri: Images.edit }} />
                </Pressable>
              )
            }
          />

          {/* PHONE */}
          <Input
            type="text"
            w="100%"
            size="md"
            placeholder={
              auth.currentUser?.phoneNumber
                ? auth.currentUser?.phoneNumber
                : translate.pages.personalInfo.phone
            }
            isDisabled={!editingPhone}
            value={userPhone}
            onChangeText={(text) => setUserPhone(text)}
            // InputRightElement={
            //   editingPhone ? (
            //     <Pressable onPress={() => saveUserPhone()} paddingX={5}>
            //       <Text>{translate.components.save}</Text>
            //     </Pressable>
            //   ) : loadingPhone ? (
            //     <Spinner paddingRight={5} accessibilityLabel="Loading posts" />
            //   ) : (
            //     <Pressable onPress={() => setEditingPhone(false)} paddingX={5}>
            //       <Image size="20px" source={{ uri: Images.edit }} />
            //     </Pressable>
            //   )
            // }
          />
        </VStack>

        {/* WALLET BOX */}
        <VStack
          w="calc(100vw - 50px)"
          maxW="500px"
          background="white"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderRadius="12px"
          paddingY="10px"
          paddingX="15px"
        >
          <Text>{translate.pages.profile.walletAddress}</Text>
          <HStack justifyContent="space-between" alignItems="center">
            {loadingWalletAddress ? (
              <Spinner />
            ) : (
              <Text maxW="calc(100% - 50px)" bold>
                {walletAddress}
              </Text>
            )}
            <Pressable
              onPress={() => {
                copyToClipboard(walletAddress);
                toast.show({
                  title: translate.pages.profile.walletAddressCopied,
                });
              }}
            >
              <Image source={{ uri: Images.clipboardDocument }} size="25px" />
            </Pressable>
          </HStack>
        </VStack>

        <Spacer />

        {/* ACTION */}
        {fromMint ? (
          <Center w="calc(100vw - 50px)" maxW="500px" paddingBottom="20px">
            <Button
              rounded="50px"
              w="100%"
              disabled={!checkIsComplete()}
              opacity={checkIsComplete() ? 1 : 0.5}
              onPress={() => {
                navigate("/mint");
              }}
            >
              {translate.pages.personalInfo.buttonText}
            </Button>
          </Center>
        ) : (
          <></>
        )}

        {/* DELETE ACCOUNT */}
        <Box
          w="calc(100vw - 50px)"
          maxW="500px"
          padding={"14px"}
          borderColor="coolGray.300"
          borderWidth="1"
          borderRadius="27px"
        >
          <HStack>
            <Text fontSize={16}>{translate.pages.profile.deleteAccount}</Text>
            <Spacer />
            <IconButton
              onPress={() =>
                setCollapsableDeleteAccount(!collapsableDeleteAccount)
              }
              size={"24px"}
              icon={
                collapsableDeleteAccount ? (
                  <AddIcon color={"black"} />
                ) : (
                  <MinusIcon color={"black"} />
                )
              }
            />
          </HStack>
          {!collapsableDeleteAccount && (
            <VStack>
              <Text paddingY={"10px"}>
                {translate.pages.profile.deleteAccountMessage}
              </Text>
              <HStack>
                <Spacer />
                {loadingDeleteAccount ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      w="100px"
                      marginRight={"14px"}
                      variant="outline"
                      onPress={() => setCollapsableDeleteAccount(true)}
                    >
                      {translate.pages.nftpage.cancel}
                    </Button>
                    <Button
                      w="100px"
                      colorScheme="warning"
                      onPress={() => {
                        deleteAccount();
                      }}
                    >
                      {translate.pages.nftpage.delete}
                    </Button>
                  </>
                )}
              </HStack>
            </VStack>
          )}
        </Box>

        <Center h="20px" w="10px" />
      </VStack>
    </VStack>
  );
};

export default PersonalInfo;
