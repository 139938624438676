/* eslint-disable react/react-in-jsx-scope */
import { createContext, ReactNode, useEffect } from "react";

interface Preferences {
  acceptTerms: boolean;
  created_at: string;
  email: string;
  id: string;
  name: string;
  nickname: string;
  phoneNumber: string;
  profilePicture: string;
  shareLocation: boolean;
  sharePersonalInfo: boolean;
  updated_at: string;
}

export interface IUserPreferencesContext {
  preferences: Preferences | null;
  setPreferences: any;
  children?: any;
}

export const INITIAL_COLLECT_MINT_STATE: IUserPreferencesContext = {
  preferences: null,
  setPreferences: null,
};

export const UserPreferencesContext = createContext<IUserPreferencesContext>(
  INITIAL_COLLECT_MINT_STATE
);

const UserPreferencesContextProvider = ({
  preferences,
  setPreferences,
  children,
}: IUserPreferencesContext) => {

  return (
    <UserPreferencesContext.Provider value={{ preferences, setPreferences }}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export default UserPreferencesContextProvider;
