import React, { useContext, useEffect, useState } from "react";
import {
  ArrowBackIcon,
  Box,
  Center,
  Divider,
  HStack,
  IconButton,
  Image,
  ScrollView,
  Spacer,
  Text,
  VStack,
} from "native-base";
import { useNavigate, useParams } from "react-router-dom";
import { Pressable } from "react-native";
import Images from "../../assets/images";
import Header from "../../shared/components/Header";
import { translate } from "../../shared/i18n/translate";

interface FaqItem {
  title: string;
  code: string;
  text: string;
}

const Faq = () => {
  const navigate = useNavigate();
  const [faq, setFaq] = useState<FaqItem[]>(translate.pages.faq.items);
  const [faqsToShow, setFaqsToShow] = useState<string[]>([]);

  return (
    <VStack alignItems="center">
      <Header
        backAction={() => {
          navigate("/help");
        }}
        title="FAQ"
        avatar={true}
        fixed={true}
      />
      <Center h="50px" />
      <ScrollView>
        <VStack w="90vw" maxW="500px" space="10px" paddingTop="20px">
          {faq.map((item, index) => (
            <VStack key={index} space="10px">
              <VStack>
                <Pressable
                  onPress={() => {
                    if (faqsToShow.indexOf(item.code) > -1) {
                      setFaqsToShow(faqsToShow.filter((f) => f !== item.code));
                    } else {
                      setFaqsToShow([...faqsToShow, item.code]);
                    }
                  }}
                >
                  <HStack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    space="10px"
                  >
                    <Text bold fontSize="20px" selectable={false}>
                      {item.title}
                    </Text>
                    <Image
                      source={{
                        uri:
                          faqsToShow.indexOf(item.code) > -1
                            ? Images.triangleUpBlack
                            : Images.triangleDownBlack,
                      }}
                      size="15px"
                    />
                  </HStack>
                </Pressable>
                {faqsToShow.indexOf(item.code) > -1 ? (
                  <Box padding="10px">
                    <Text fontSize="14px">{item.text}</Text>
                  </Box>
                ) : (
                  <></>
                )}
              </VStack>
              <Divider />
            </VStack>
          ))}
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export default Faq;
