import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase.config";
import loadImage from "blueimp-load-image";
const ImageCompressor = require("js-image-compressor/dist/image-compressor");

export const uploadImage = () => {
  return new Promise((resolve, reject) => {
    const inputfile = document.createElement("input");
    inputfile.type = "file";
    inputfile.style.display = "none";
    inputfile.accept = "image/jpg, image/jpeg, image/png";
    inputfile.onchange = async (event: any) => {
      try {
        const files: File[] = event.target.files;
        const base64Images: string[] = [];
        for (const file of files) {
          const base64: any = await toBase64(file);
          base64Images.push(base64);
        }
        resolve(base64Images);
        inputfile.remove();
      } catch (error) {
        reject(error);
        inputfile.remove();
      }
    };
    document.body.prepend(inputfile);
    inputfile.click();
  });
};

export const toBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

export const toFile = (base64: any, filename: string): File => {
  var arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const uploadCloudStorage = (base64: any, filename: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const storageRef = ref(storage, filename);
      const file = toFile(base64, filename);
      const fileCompressed: any = await compress(file, {
        convertSize: 2000000,
      });
      loadImage(
        file,
        async function (img: any, data: any) {
          if (data.imageHead && data.exif) {
            (loadImage as any).writeExifData(
              data.imageHead,
              data,
              "Orientation",
              1
            );
            img.toBlob(function (blob: any) {
              (loadImage as any).replaceHead(
                blob,
                data.imageHead,
                async function (newBlob: any) {
                  await uploadBytes(storageRef, newBlob);
                  const url = await getDownloadURL(ref(storage, filename));
                  resolve(url as string);
                }
              );
            }, "image/jpeg");
          } else {
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(ref(storage, filename));
            resolve(url as string);
          }
        },
        { meta: true, orientation: true, canvas: true }
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const compress = (file: File, _options: Partial<any>) => {
  return new Promise(async (resolve, reject) => {
    try {
      const options: any = _options ? _options : {};
      options.file = file;
      options.success = (result: any) => {
        resolve(result);
      };
      options.error = (err: any) => {
        reject(err);
      };
      new ImageCompressor(options);
    } catch (error) {
      reject(error);
    }
  });
};

export const svg2jpeg = (svg: any) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(
      new Blob([svg], {
        type: "image/svg+xml",
      })
    );
    const svgImage = document.createElement("img");
    document.body.appendChild(svgImage);
    svgImage.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = svgImage.clientWidth;
      canvas.height = svgImage.clientHeight;
      const canvasCtx: any = canvas.getContext("2d");
      canvasCtx.drawImage(svgImage, 0, 0);
      const imgData = canvas.toDataURL("image/png");
      resolve(imgData);
      document.body.removeChild(svgImage);
      URL.revokeObjectURL(url);
    };
    svgImage.onerror = (error) => {
      reject(error);
    };
    svgImage.src = url;
  });
};
