export const shareLink = (title: string, url: string) => {
  return new Promise((resolve, reject) => {
    if (!!navigator.share) {
      navigator
        .share({
          title,
          url,
        })
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    } else {
      reject();
    }
  });
};

export const shareImage = (files: File[]) => {
  return new Promise((resolve, reject) => {
    if (!!navigator.share) {
      navigator
        .share({
          files,
        })
        .then(() => {
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      reject();
    }
  });
};
