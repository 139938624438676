import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD33jTttCMS4XKZO9slocOgq8qrk4ZxLog",
  authDomain: "wakame-89b57.firebaseapp.com",
  projectId: "wakame-89b57",
  storageBucket: "wakame-89b57.appspot.com",
  messagingSenderId: "826642646593",
  appId: "1:826642646593:web:f32119ad1f5238e5d7a8c0",
  measurementId: "G-3T0HJFQN3M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
// if (process.env.REACT_APP_USE_EMULATORS === "true") {
//   connectAuthEmulator(auth, "http://192.168.68.106:5001");
// }
