export const enUS = {
  pages: {
    home: {
      collect: "COLLECT",
      mint: "MINT",
      descriptionCollect: {
        swipe1: "SWIPE",
        swipe2: "RIGHT TO MINT",
        swipe3: "NFTS",
        line1: "PRESS WAKAME",
        line2: "TO COLLECT",
        line3: "YOUR FIRST NFT",
      },
      descriptionMint: {
        swipe1: "SWIPE",
        swipe2: "LEFT TO MINT",
        swipe3: "NFTS",
        line1: "PRESS +",
        line2: "TO CREATE YOUR",
        line3: "FIRST NFT",
      },
    },
    profile: {
      completeProfile: "Complete Your Profile",
      profile: "Profile",
      name: "Name",
      userName: "User Name",
      email: "Email",
      editProfileInfo: "Edit profile information",
      lang: "Language",
      settings: "Settings",
      faq: "FAQ",
      helpAndSupp: "Contact Us",
      termsAndCond: "Terms & Condition",
      privacyPolicy: "Privacy Policy",
      privacy: "Privacy",
      phoneNumber: "Phone number",
      wallet: "Wallet",
      edit: "Edit",
      walletAddress: "Wallet address:",
      walletAddressCopied: "Wallet address copied",
      mail: "Mail",
      telegram: "Telegram",
      whatsapp: "WhatsApp",
      account: "Account",
      help: "Help",
      privacyOptions: "Privacy Options",
      deleteAccount: "Delete Account",
      deleteAccountMessage: "This will remove all data.This action cannot be reversed. Deleted data can not be recovered.",
    },
    languages: {
      title: "Language",
      suggested: "Suggested",
      enUs: "English (US)",
      ptBr: "Portuguese  (PT-BR)",
      esp: "Spanish (ESP)",
    },
    settings: {
      title: "Settings",
      shareLocalization: "Share Localization",
      shareLocalizationDesc: "Text about what means Share Localization bla bla",
      sharePersonalInfo: "Share Personal Information",
      sharePersonalInfoDesc: "Text about what means Share Personal Info bla bla",
      security: "Security",
      deleteAccount: "Delete Account",
      deleteAccountDescription:
        "This will remove all data. This action cannot be reversed. Deleted data can not be recovered.",
      delete: "Delete",
      cancel: "Cancel",
      logout: "Log Out",
      locationPersonalInfo:
        "Your location and your personal data will be shared, in accordance with the terms of use.",
      privacyOptions: "Privacy options",
      accountOptions: "Account options",
    },
    mint: {
      title: "MINT",
      selectImage: "Upload or take your picture",
      nftName: "NFT Name",
      tags: "Add TAGs splited by comma",
      nftDesc: "NFT Description",
      numCopies: "Quantity of NFTs",
      creationDate: "Creation Date",
      creationTime: "Creation Time",
      avaliableFrom: "Available From",
      location: "Location",
      next: "NEXT",
      previous: "PREVIOUS",
      submmitButton: "CREATE NFT",
      mint: "Mint",
    },
    phoneSignup: {
      inputPhoneMensage:
        "When continuing, you will receive an SMS for confirmation.\n\nMessaging and data service charges may apply.",
      inputCodeMensage: "Check the SMS code sent to",
      phoneError: "Your number is invalid. Try Again.",
      codeError:
        "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code SMS and be sure to use the correct verification code.",
      confirmButton: "CONFIRM",
      enterSMSCode: "Please enter the 6-digit code sent to",
      resendCode: "RESEND CODE",
    },
    nftpage: {
      nftMintCode: "NFT MINT Code",
      nftMintCodeCopied: "NFT code copied",
      collect: "COLLECT NFT",
      collected: "NFT COLLECTED",
      description: "Description",
      seeMore: "see more",
      seeLess: "see less",
      deleteNft: "Delete NFT",
      deleteNftDescription:
        "This will remove your NFT. This action cannot be reversed. Delete NFT can not be recovered",
      cancel: "Cancel",
      delete: "Delete",
      available: "Available",
      share: "Share",
      qrcodeCopy: "QR-CODE COPY",
      addTags: "+ add tags",
      nftCode: "NFT CODE",
      contractAddressCopied: "Contract address copied",
      unknown: "Unknown",
      nftUnavailable: "NFT Unavailable",
    },
    collectPage: {
      title: "Collect NFT",
      enterNFTcode: "Enter NFT Code",
    },
    personalInfo: {
      title: "Personal info",
      buttonText: "Complete",
      walletAddress: "Wallet address:",
      name: "Name",
      email: "Email",
      phone: "Phone",
      profileName: "Profile name",
    },
    privacy: {
      title: "Privacy Policy",
      p1: "Thank You for visiting https://wakame.app (the “Site”) or accessing the Wakame App (the “App”). The Site and the App are owned and operated by Wakame LLC (“Wakame” “We,” “Our”, “Us”, or “Company”), a BVI company, which is the data controller of Your Personal Information collected whilst using this Site or the App.",
      p2: "As part of our daily business operations, We collect personal information from Our clients and prospective clients to provide them with our products and services and ensure that We can meet their needs when providing these products and services, as well as when providing them with any respective information. By accepting this privacy policy, You are aware that Your data may still be shared with selected third-party companies for commercial and promotional purposes, such as advertising, marketing, and market research, as well as promotional campaigns, and that in this case, Your data shall also be subjected to the specific privacy policies of these companies.",
      p3: "This Privacy Policy (“Privacy Policy”) sets out the basis on which any Personal Information, as defined below, We collect from You (“You”, “Your”, “User” or “Customer”), or that You provide to Us, will be processed by Us. By “Personal Information”, We mean any information which, either alone or in combination with other data, enables You to be directly or indirectly identified, for example, Your name, email address, username, and contact details, either provided by You or collected by Us when You are using our Site and/or App.",
      p4: "Please read the following carefully to understand what data We collect, how that data is used and the ways it can be shared by Us.",
      h1: "CHANGES TO THIS PRIVACY POLICY",
      p5: "We may modify this Privacy Policy from time to time which will be indicated by changing the date at the bottom of this document. If We make any material changes, We will notify You by email (sent to the email provided by You), using a notice, before the change becomes effective, or as otherwise required by law.",
      h2: "INFORMATION WE COLLECT",
      p6: "To access Our Site or App, We preliminary do not collect any Personal Information nor any cookie. Under Your action to sign in and use the App or Site, you shall provide Us with the information required to sign in to Your account, which is Your e-mail address.",
      p7: "To make the action to collect NFTs on Wakame, either on the Site or App, you need to complete your registration and fill NFT collection form. At this moment You will provide Us with Your full name, profile picture, phone number, e-mail address, and username.",
      p8: "We are not responsible for any issue arising from the services provided by any third party, nor by their operational systems, or privacy matters, nor by the content generated by any third party on Wakame.",
      p9: "When the collection of NFTs is claimed by You, as stated above, We will request and collect Your full name, email, profile picture, and telephone number. You may or may not agree with the terms of this Privacy Policy and to provide Your information. In case You do not agree with this Privacy Policy, you may not be able to mint or store that specific NFT nor participate in any promotional event, or receive any information or any campaign related to the NFT. Nevertheless, Your access to the platform will not be affected by this action.",
      p10: "We do not collect or share any sensitive Personal Information about You (this includes details about Your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about Your health, and genetic and biometric data).",
      p11: "All the information is collected when You are:",
      l1: "Registering for an Account;",
      l2: "Using our Platform or Site;",
      l3: "Corresponding with Us by phone, email, WhatsApp, Telegram or otherwise;",
      l4: "Collecting and storing the NFTs.",
      h3: "PURPOSES FOR WHICH WE COLLECT YOUR PERSONAL INFORMATION",
      p12: "We will collect and process Your personal information on the following basis and for the following purposes:",
      u1: "Performance of a contract.",
      p13: "We process personal data to provide our Services, as well as information regarding Our products and services based on the contractual relationship with our clients (i.e. to perform our contractual obligations);",
      p14: "The processing of personal data takes place to enable the completion of our customer onboarding process and access to our services and products.",
      u2: "To safeguard legitimate interests.",
      p15: "We process personal data to safeguard the legitimate interests pursued by Us or by a third party. A legitimate interest is when We have a business or commercial reason to use Your information. Examples of such processing activities include the following:",
      l5: "Initiating legal claims and preparing Our defense in litigation procedures;",
      l6: "Means and processes We undertake to provide for the Company’s IT and system security, preventing potential crime, asset security, and access controls;",
      l7: "Measures for managing the business and for further developing products and services;",
      l8: "Sharing Your Data with third-party companies for promotional agreements entered into with these third parties, and",
      l9: "Risk management.",
      u3: "To provide You with products and services, or information about our products and services, and to review Your ongoing needs.",
      p16: "Once You successfully provide Your personal information to Us We must use Your personal information to perform our Services and comply with Our obligations to You. It is also in Our legitimate interests to try to ensure that We are providing the best Services so We may periodically review Your needs based on our assessment of Your personal information to ensure that You are getting the benefit of the best possible products and services from Us.",
      u4: "To help Us improve Our products and services, including support services, develop and market new products and services.",
      p17: "We may, from time to time, use Personal Information provided by You through Your use of the services and/or through client surveys to help Us improve Our services. It is in Our legitimate interests to use Your personal information in this way to try to ensure the highest standards when providing You with Our Services.",
      u5: "To send You surveys.",
      p18: "From time to time, We may send You surveys as part of our client feedback process and product of the Platform (Site or App). It is in Our legitimate interest to ask for such feedback to try to ensure that We provide our products and services at the highest standard. However, We may from time to time also ask You to participate in other surveys from third parties that are partners or have commercial agreements with Us, and if You agree to participate in such surveys, We rely on Your consent to use the personal information We collect as part of such surveys.",
      u6: "For marketing purposes.",
      p19: "We may use Your personal information to send You marketing communications by email or other agreed forms (including social media campaigns), to ensure You are always kept up to date with our latest Services. If We send You marketing communications, We will do so based on Your consent and registered marketing preferences. Besides, We may also share your data with selected partner companies for specific commercial promotion, marketing, and market research purposes related to the use of our products and services.",
      u7: "For internal business purposes and record keeping.",
      p20: "We may need to process Your personal information for internal business and research purposes as well as for record-keeping purposes. Such processing is in Our legitimate interests and is required to comply with Our legal obligations. This may include any communications that We have with You about the products and services We provide to You and our relationship with You. We will also keep records to ensure that You comply with Your contractual obligations under the Terms and Conditions governing our relationship with You.",
      p21: "The Personal Information We hold about You must be accurate and current. Please keep Us informed if Your Information changes during Your relationship with Us.",
      h4: "SECURITY AND CONFIDENTIALITY",
      p22: "The Company respects the privacy of any users who access the Site or the App, and it is therefore committed to take all reasonable steps to safeguard any existing or prospective clients, applicants, and website visitors. We are committed to protect Your privacy.",
      p23: "The Company keeps any personal data of its clients and its potential clients to the applicable privacy and data protection laws and regulations. We have the necessary and appropriate technical and organizational measures and procedures in place to ensure that Your information remains secure at all times.",
      p24: "Our operations are supported by a network of computers, servers, and other infrastructure and information technology, including, but not limited to, third-party service providers such as MongoDB App Services (MongoDB Inc).",
      p25: "Personal Information and other data may therefore be exported outside of the jurisdiction in which You reside. Your Personal Information may be processed and stored in a foreign country or country. Under those circumstances, the governments, courts, law enforcement, or regulatory agencies of that country or those countries may be able to obtain access to Your Personal Information through foreign laws. You need to be aware that the privacy standards of those countries may be lower than those of the jurisdiction in which You reside.",
      p26: "Unfortunately, the transmission of information via the Internet is not completely secure. While We do our utmost to protect Your Personal Information, We cannot guarantee the security of Your data transmitted to Us over email or through the Site; any transmission is at Your own risk. Once We have received Your Data, We will use strict procedures and security features to try to prevent unauthorized access.",
      h5: "DISCLOSURES",
      p27: "The Company will not disclose any of its client’s Confidential Information to a third party, except in the following situations, in which You agree that We have the right to share Your Personal Information:",
      l10: "To the extent that it is required to do so under any applicable laws, rules, or regulations;",
      l11: "Any members of the Company, which means that any of our affiliates and subsidiaries may receive such information;",
      l12: "Any of our service providers and business partners, for business purposes, such as specialist advisors who have been contracted to provide Us with administrative, financial, legal, tax, compliance, insurance, IT, debt-recovery, analytics, research, or other services;",
      l13: "Selected third parties, including analytics and search engine providers that assist Us in the improvement and optimization of the Services;",
      l14: "Selected third parties for commercial promotion, market research, and advertising purposes;",
      l15: "When you collect any NFT on our Site or App, for the purpose of specific promotional actions;",
      l16: "If there is a duty to disclose;",
      l17: "If our legitimate business interests require disclosure;",
      l18: "At Your request or with Your consent or to those described in this Privacy Policy.",
      p28: "If the Company discloses Your personal information to service providers and business partners, to perform the services requested by clients, such providers and partners may store Your personal information within their systems to comply with their legal and other obligations. We require that service providers and business partners who process personal information acknowledge the confidentiality of this information, undertake to respect any client’s right to privacy, and comply with all relevant privacy and data protection laws and this Privacy Policy.",
      p29: "All users may access and verify their Personal Information held by Us by submitting a written request to support@wakame.app, and demand, if needed to rectify any inaccurate Personal Information that We may hold; have Your Personal Information erased in certain circumstances, (i.e. when the Personal Information it is no longer necessary for and to fulfill Our processing purposes); restrict the processing of Your Personal Information where, for example, the information is inaccurate or it is no longer necessary for Us to process such information or where You have exercised Your right to object to our processing; and have Your data ported to a new service provider if You no longer wish to use the Services. You may exercise these rights by contacting Us.",
      p30: "We may need to request specific information from You to help Us confirm Your identity and ensure Your right to access Your Personal Information (or to exercise any of Your other rights). This is a security measure to ensure that Personal Information is not disclosed to any person who has no right to receive it. We may also contact You to ask You for further information about Your request to speed up our response.",
      p31: "This Privacy Policy and the use of Your Personal Information only applies to the information You provide or that is collected with Your consent within the use of the App or access to the Site. You are cautioned that if You disclose Personal Information or personally sensitive data through the use of the Internet, such as through chat rooms, communities, bulletin boards, or other public online forums, this information may be collected and used by other persons or companies over which the Company has no control. It is Your responsibility to review the privacy statements, policies, terms, and conditions of any person or company to whom You choose to link or with whom You choose to contract. We are not responsible for the privacy statements, policies, terms, conditions, or other content of any Site not owned or managed by Us.",
      h6: "CHANGES TO OUR PRIVACY POLICY",
      p32: "Any changes We make to Our Privacy Policy in the future will be posted on this page and, where appropriate, notified to You by email. Please check back frequently to see any updates or changes to our Privacy Policy.",
      h7: "IF YOU HAVE A COMPLAINT",
      p33: "If You have any concerns about how We handle Your Personal Information, please contact Us in the first instance. We will do our best to resolve Your concern. Questions, comments, and requests regarding this Privacy Policy should be addressed to contact@wakame.app.",
      i1: "July 22",
    },
    terms: {
      title: "TERMS OF SERVICE AND CONDITIONS OF USE OF WAKAME PLATFORM",
      p1: 'These Terms and Conditions of Use ("Terms") are entered into by and between You ("User" or "You") and WAKAME ("Company", "Us", "We", or "Our") regarding Your access and use to and of Our Platform (the "Site" and/or mobile application, herein referred to as "App") including without limitation the collection, trough airdrops of Non-fungible tokens ("NFTs"), creation of crypto wallet addresses and interaction with our online and/or mobile services, and software provided on or in connection with those services (collectively, the "Services").',
      p2: "WAKAME IS AN ELECTRONIC PLATFORM TO SEE, COLLECT, AND STORAGE NFTs AS WELL AS TO INTERACT WITH THIRD PARTIES BRANDS AND PROMOTIONS USING THIS TOOL. WE ARE NOT A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. WAKAME DOES NOT FACILITATE TRANSACTIONS BETWEEN USERS IN EXCHANGE FOR MONEY OR CRYPTOCURRENCIES BEING IMPOSSIBLE TO MAKE TRANSACTIONS IN EXCHANGE OF MONEY OR CRYPTOCURRENCIES FOR THE NON-FUNGIBLE TOKENS BETWEEN ANY USERS AND/OR COMPANIES WITHIN THE PLATFORM.",
      p3: "By creating an account on the Platform, You acknowledge and agree to these Terms, to our Privacy Policy (wakame.app/privacy), and to all additional terms, guidelines, and rules as set forth on the Platform, which are all incorporated by reference to these Terms and expressly agreed and recognized by the User.",
      p4: "You must also monitor the Platform or Your e-mail for any announcements from the Company as We may add to, or change these Terms from time to time. The Company reserves the right to change these Terms at its own discretion, and with no need to provide any justification, by providing a simple notice on the Platform.",
      t1: "DEFINITIONS",
      p5: "In these Terms and all documents incorporated herein by reference, the following words have the following meanings unless otherwise indicated:",
      l1: '"Affiliate" means, in relation to the Company or the User, a direct or indirect subsidiary of the Party, a holding company of the Party, and any other subsidiary of that holding company;',
      l2: '"App" means the mobile application developed and provided by Us where the Platform will be allocated and where the User will be able to access the Platform;',
      l3: '"Copyrights" has the meaning of the exclusive ownership of all materials on the Platform;',
      l4: '"Individual" is a Person capable to use the Platform and the Services according to this Terms;',
      l5: '"Laws" means all laws, statutes, orders, regulations, rules, treaties, and/or official obligations or requirements enacted, promulgated, issued, ratified, enforced, or administered by any Government that apply to You on the Platform;',
      l6: '"Marks" means all the trademarks, service marks, and trade names, including both word marks and design marks that are related to WAKAME;',
      l7: '"NFT" – means Non-Fungible Token, which is a uniquely issued cryptographic electronic key (Token) type. The ownership of the NFT is guaranteed through the digital certificate of authenticity and uniqueness, in this case, of the digital content linked to the non-fungible cryptographic token minted in the Platform and distributed to users that wants to collect it.',
      l8: '"Person" includes an individual, association, partnership, corporation, other body corporate, trust, estate, and any form of organization, group, or entity cognizable as legal personalities;',
      l9: '"Services" means all the Services provided by WAKAME;',
      l10: '"Services" means any service or product made available to You on or through the Platform or provided to You by Us.',
      l11: '"Site" means the website from Us where the Platform will be allocated and where the User will be able to access the Platform;',
      l12: '"Terms" means these terms and conditions, as they may be changed, amended, or updated from time to time, including the following other Platforms\' policies and pages;',
      l13: '"Tokens" is an encrypted record on a distributed network of computers called blockchain;',
      l14: '"User" means the Person or Entity who/whose uses the Platform and Services.',
      l15: '"WAKAME" is an electronic platform accessible through its Site or App that enables the display, and allows minting, and storage of NFTs created by Users or Companies for the Users in the form of free and promotional airdrops, containing digital Content, represented on an NFT.',
      t2: "I. USER ACCOUNT",
      p6: "You may need to register on our App or Site to access and use certain features of the Platform. If you choose to register on the Platform, you agree to provide and maintain true, accurate, current, and complete information about you as requested on our registration form. Registration data and some other information about you are governed by our Privacy Policy which you agree to by signing in to the Platform. You must be at least 16 years old to register for an account as a User. You are solely responsible for the safety of your account and for anything that occurs when You are (or any unauthorized person who steals your data is) logged into your account, either through the App or Web Platform.",
      p7: "You are responsible for maintaining the confidentiality, safety, and private access of your account and password, and are fully responsible for any and all activities that occur under the use of or account. You expressly agree to (a) immediately notify WAKAME of any unauthorized use or attempt to use of your account or any identified unauthorized attempt to steal Your password or account or any other security breach that You identify. We will not be held responsible for any loss or damage arising from failure to comply with this Section and for any loss of any NFT stored in Your Account.",
      p8: "You agree that WAKAME has the right to immediately suspend, pause or cancel Your account or access to the Platform, or terminate Your account if You incite, favor, represent and/or facilitate any improper or unlawful activities or practices such as fraudulent activities, discrimination of any kind, failure to comply with any fundamental rights or legal obligations, committing crimes against honor, life or property, concealment or provision of false information and violation of these Terms of Use.",
      p9: 'You will only be able to mint ("Collect") one NFT per collection.',
      t3: "II. COMMUNICATION",
      p10: "You agree that We may send email or message communications to You related to the status of the receipt of an NFT and other communications that pertain to Your interaction with the Platform, including, but not limited to, notifications about the status of an NFT campaign or airdrop that You are interested in participating.",
      p11: "You agree that We and third parties that have access to Your data through the collection of NFTs may send promotional communications to You by e-mail, including but not limited to newsletters, special offers, surveys, and other news and information that We consider to be of interest to You. It is important that You know that You can cancel promotional communications at any time by following the instructions provided, however, by canceling these communications You may not be eligible to participate in some promotional campaigns.",
      t4: "III. WAKAME INTELLECTUAL PROPERTY",
      p12: "All content, i.e. texts, audiovisual, musical works, trademarks, photographs, software, and other content protected by intellectual property rights are the exclusive property of third parties who have authorized their use on the Platform and developed the content and are protected by international laws and treaties, being denied their copying, reproduction, or any other use, offenders are subject to the corresponding civil and criminal sanctions.",
      p13: "Any and all content related to the functioning of WAKAME, including the tool’s source code, the Wakame trademark, developed software, App, and Site are the sole property of WAKAME, and shall not be replicated, or copied, used, or engineered reversed by You.",
      p14: "The intellectual property resulting from the provision of the Services will be the entire property of the Company. The Company shall be the sole owner with respect to the copyrights, domains, codes, root solutions, non-public or open-sourced software and coding, patents, trademarks, trade secrets, and any other intellectual property rights over the services developed under the scope of this Agreement.",
      t5: "IV. CREATION OF CONTENT BY THE USER",
      p15: "The User undertakes to use the Platform and its content(s) in accordance with the legal system and must refrain from obtaining, or attempting to obtain, the contents by means other than those that, in each case, have been made available for such purposes.",
      p16: 'The Users who sign in for it and provide the adequate information requested are able to create NFTs on the Platform ("Content Provider"). By creating any content and NFT in the Platform, the User guarantees that any and all content uploaded or used in connection with the use of WAKAME does not breach any intellectual property rights owned by third parties.',
      p17: "The Content Provider is limited to generate collections of a maximum of 100.000 NFTs per collection. If WAKAME identifies that the User is spamming by creating multiple collections of NFTs, it may at its own discretion suspend or exclude the Content Creators’ account without any previous notice.",
      p18: "The generated NFT must observe the minimum requirements of its creation, such as the type of document and size of the image, which shall be limited by a 20MB (Megabytes) document size, using the format .JPEG or .PNG. The collections or single NFTs must have a name, and a description and indicate the date of creation.",
      b1: "WAKAME is not responsible and shall not be liable for any breach of intellectual property rights resulting from the content uploaded or used by the User on the Platform.",
      p19: "WAKAME has the right to remove any and all content provided, created, or uploaded by the User through the Platform at its own discretion.",
      p20: "WAKAME is not liable for any damages brought to the user or any other legal or natural person following the removal of said content.",
      p21: "All content created or provided by the Content Provider may not contain false, non-authorial information not accompanied by due referencing, racist or homophobic nature, or any content that configures any crime against any third party, with the Content Provider being solely responsible for any damage caused by any material generated and uploaded in the Platform and minted as an NFT, even if it is published on the Platform.",
      p22: "If the Content Provider is involved or engages in any acts or attitudes mentioned in the item above, that may denigrate the image of the Platform, WAKAME may suspend and/or remove his access and the generated NFTs, with no need for prior notification, and will not be responsible by and have no obligation to indemnify by any damage as a result of this action.",
      p23: "If WAKAME is sued, convicted, or suffers any damage due to an administrative or judicial process related to the content generated and provided by the Content Provider, including damages to the image and brand, the Content Provider shall reimburse all costs and repair all damages caused to WAKAME, from material damages as well as those resulting from the denigration of its image and Platform.",
      t6: "V. COMPLAINTS ARISING FROM RIGHTS VIOLATIONS",
      p24: "WAKAME respects the intellectual property and rights of others, and we ask our Users to do the same. If You believe that Your work has been infringed in terms of copyright and/or industrial property, or that your rights have been otherwise infringed, you must notify WAKAME of your claim of infringement in accordance with the procedure set forth below.",
      p25: 'WAKAME will investigate notices involving alleged infringement and will take appropriate action in accordance with the applicable laws with respect to any claim of violation of rights. Notification with the description of the event must be sent to the e-mail support@wakame.app (Subject line: "Copyright Complaint").',
      t7: "VI. REPRESENTATION OR WARRANTY",
      p26: "To access some of the Services, Users may need to enhance or update the hardware or software in their computer systems or upgrade their mobile phones. WAKAME assumes no responsibility for any failure to access the Services, partially or fully, whether due to the User’s system, the Internet network, or any other cause.",
      p27: "WAKAME does not guarantee that the access to the Site, App, or any of the Services will be uninterrupted or error-free. Nevertheless, WAKAME shall not be liable for any feature not being accessible or for any unavailability of the Services.",
      p28: "You represent and warrant that:",
      al1: "Our Service does not allow You to sell, buy, send, or acquire NFTs. WAKAME is not responsible for any transaction made by You, directly in the Blockchain that supports the minting of the NFTs and that store the information of it.",
      al2: "There are risks associated with using an Internet-based structure, including but not limited to, the risk of hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that WAKAME will not be responsible for any communication failures, disruptions, errors, distortions, or delays you may experience when using the NFTs, however, caused.",
      al3: "The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens are uncertain, and new regulations or policies may materially adversely affect the development of the Auction and/or Service and the utility of NFTs.",
      al4: "There are risks associated with receiving user-generated content, including but not limited to, the risk of purchasing counterfeit and mislabeled NFTs and assets that are or may become untransferable or deleted in the future. Wakame reserves the right to hide collections, contracts, and assets affected by any of these issues or by other issues.",
      p29: "You may not:",
      al5: "Use the Platform if any applicable Laws prohibit, penalize, sanction, or expose the Site, App, or Platform to any liability for any Services furnished or offered to You;",
      al6: "Use the Platform if You are a resident of countries that prohibit the receipt, storage, and usage of any kind of crypto assets or cryptocurrencies;",
      al7: "Use the Platform to interfere with or subvert the rights or obligations of WAKAME or the rights or obligations of any other website, app, User, or any other third party or cause legal liability for WAKAME, another website, app, or User;",
      al8: "Take advantage of any technical glitch, malfunction, failure, delay, default, or security breach on the Site, App, or Platform;",
      p30: "A violation of any of these Terms may result in the termination of the User’s account or access to the Platform. Any attempt by You to disrupt or interfere with the Services, including without limitation undermining or manipulating the legitimate operation of the Site or App, is a breach of these Terms and may be a breach or violation of criminal and civil laws.",
      t8: "VII. RISKS AND LIMITATIONS OF LIABILITIES",
      p31: "The Company has made and will continue to make efforts to include accurate and up-to-date information on the Platform. However, WAKAME cannot guarantee that the information posted on the Platform is accurate, complete, or suitable for any purpose. In no event will WAKAME, its directors, officers, employees, contractors, or agents be liable for any incidental, special, consequential, or indirect damages or any direct damages that result from the use of the performance of the Services, or any failure in the Services.",
      p32: "User assumes total responsibility for the use of the Services. The Company may alter or remove materials from the Site, App, Platform, or this Terms at any time.",
      p33: "You understand and agree that We, Our subsidiaries, affiliates, and licensors will not be liable to You or to any third party for any indirect, incidental, special, consequential, or exemplary damages which You may have, including, but not limited to, any loss of profits (both directly and indirectly), loss of goodwill or business reputation, loss of data, cost of procurement of substitute goods or services, or any other intangible loss, even if we have been advised of the possibility of such damages.",
      p34: "There are risks associated with using or accessing the Platform, including, but not limited to, the failure of hardware, software, and Internet connections. The Company is not responsible for the proper and/or complete transmission of the information contained in any electronic communication or of the electronic communication itself, nor for any disruption, distortion, or delay in its delivery or receipt, howsoever caused. You are solely responsible for backing up and maintaining duplicate copies of any information You store on or transfer through the Platform.",
      p35: "You acknowledge that information transmitted over the Internet (including communications by email) may be susceptible to unlawful access and monitoring. Further, You acknowledge that the transmission of data or information over the Internet may result in loss, interception, or alteration while in transit and that all such information shall not be deemed confidential, and the Company does not have any fiduciary obligation with respect to such transmitted information.",
      t9: "VIII. DISCLAIMER OF WARRANTIES",
      p36: 'The Services, Platform, Site, and App are provided to the fullest extent legally permissible to You "as is" and on an "as available" basis with no warranty of any kind either, statutory, expressed, or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability, and fitness for a particular purpose. You assume all responsibility and risk with respect to Your use of the Platform.',
      p37: "The Company does not represent or warrant that the information, software, or services contained in or provided by the Platform complies with any applicable laws of Your country.",
      p38: "The Company does not guarantee that the Platform and Services it provides cannot be illegitimately duplicated (either in part or in full) by a third party without the Company’s prior written consent.",
      p39: "If applicable law does not permit all or any part of the above exclusion of warranties or disclaimer of implied terms in contracts to apply to You, the limitations, exclusions, and disclaimers will apply to You only to the extent permitted by applicable law.",
      t10: "IX. INDEMNITY",
      p40: "You agree to indemnify and defend Us, including Our affiliates and respective directors, officers, and personnel from and against all losses, costs, damages, expenses, and liabilities (including reasonable legal fees and disbursements) that may be suffered by Us arising out of or as a result of or relating in any manner to a claim relating to:",
      al9: "Your use of the Site;",
      al10: "Breach of these Terms of Use by You;",
      al11: "Any breach of Your representations and warranties set forth in these Terms of Use;",
      al12: "Your violation of the rights of a third party, including but not limited to intellectual property rights.",
      p41: "Notwithstanding the foregoing, We reserve the right, at Your expense, to assume the exclusive defense and control of any matter for which You are required to indemnify Us, and You agree to cooperate, at Your expense, with Our defense of such claims. We will use reasonable efforts to notify You of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.",
      t11: "X. MISCELLANEOUS",
      p42: "Notwithstanding anything contained herein, The Company reserves the right, without notice and in its sole discretion, to terminate these Terms, suspend Your right to access the Platform or Services, and all related information and files without liability to You, at its sole discretion, including but not limited to, in case of Your breach of these Terms or if The Company believes You have committed fraud or other misconduct.",
      p43: "WAKAME may, at its discretion and without liability to You, with or without prior notice, suspend Your access to all or a portion of the Platform or Services. In the event, The Company terminates these Terms, revokes Your right to use or access the Platform or Services, to the extent permitted under Applicable Law",
      p44: "No provision of these Terms shall be considered waived unless such waiver is in writing and signed by the Party that benefits from the enforcement of such provision. No waiver of any provision in these Terms, however, will be deemed a waiver of a subsequent breach of such provision or a waiver of a similar provision. In addition, a waiver of any breach or a failure to enforce any term or condition of these Terms will not in any way affect, limit, or waive a Party’s rights hereunder at any time to enforce strict compliance thereafter with every term and condition hereof.",
      p45: "The Company may, at its sole discretion, assign any of its rights and/or delegate its duties under these Terms (including, but not limited to any and all intellectual property rights in or to all technology, software, and code relating to the Platform and Services), without noticing You or any other User.",
      p46: "If any term, provision, covenant, or restriction of these Terms is held by a court of competent jurisdiction to be invalid, illegal, void, or unenforceable, the remainder of the terms, provisions, covenants, and restrictions set forth herein shall remain in full force and effect and shall in no way be affected, impaired or invalidated, and the Company shall use commercially reasonable efforts to find and employ an alternative means to achieve the same or substantially the same result as that contemplated by such term, provision, covenant or restriction. It is hereby stipulated and declared to be the intention of the Parties that they would have executed the remaining terms, provisions, covenants, and restrictions without including any of such that may be hereafter declared invalid, illegal, void, or unenforceable.",
      p47: "The Company makes no representations concerning the tax implications arising out of the NFTs or the possession or use of them.",
      p48: "These Terms and all matters arising out of or relating to these Terms shall be governed by and construed in accordance with the laws of British Virgin Island, without regard to any conflict of law provisions. Each party irrevocably agrees that any claim brought by it in any way arising out of the Services must be brought solely and exclusively in state or federal court located in the British Virgin Islands, and each party irrevocably accepts and submits to the sole and exclusive jurisdiction of each of the aforesaid courts in persona, generally and unconditionally, with respect to any action, suit, or proceeding brought by it or against it by the other party.",
      p49: "If You are uncertain as to whether or not Your use of the Services involves a prohibited use, or have questions about the Platform, Site or App, please contact Us at: contact@wakame.app.",
      d: "July 2022",
      cancelTermsMessage: "You need to accept the terms to continue!",
    },
    faq: {
      items: [
        {
          code: "what-is-nft",
          title: "What is NFT?",
          text: "Non-fungible token (NFT) is a cryptographic asset on a blockchain with a unique identification code and metadata. Collect and mint your NFT on Wakame totally free of charge :)",
        },
        {
          code: "whats-the-difference-between-collect-and-mint",
          title: "What is the difference between COLLECT and MINT?",
          text: "COLLECT: collect NFT. MINT: create your own NFT.",
        },
        {
          code: "how-to-collect-nft",
          title: "How to Collect NFT?",
          text: "Press the “Wakame” green button, scan the QR code or enter an 8-digit code. After NFT opens, click on the “Collect NFT” green button and collect it.",
        },
        {
          code: "how-to-mint-nft",
          title: "How to Mint NFT?",
          text: "Press the “+” green button, fulfill all required information and press the “Mint” green button. It's that easy! Your NFT is now available to be shared via link, QR code or 8-digit code.",
        },
        {
          code: "what-is-6-digit-code",
          title: "What is validation code?",
          text: "6-digit code is used to validate your email and phone number. 8-digit code is used to share, display and collect NFT.",
        },
        {
          code: "how-to-delete-your-account",
          title: "How to delete your account?",
          text: "To delete your account please access: Settings > Profile > Delete Account > Confirm",
        },
      ],
    },
  },
  components: {
    save: "Save",
    cancel: "Cancel",
  },
  alerts: {
    sendPhoneCodeError: "Too many login attempts, wait a few minutes",
    confirmPhoneCodeError: "Error on confirm phone code",
    savePhoneError: "Error on save phone",
    saveNameError: "Error on save name",
    saveEmailError: "Error on save email",
    saveProfilePictureError: "Error on save profile picture",
    collectNftError: "Error on collect NFT",
    getNftInfoError: "Error on get NFT info",
    getNftsCollectedError: "Error on get NFTs collected",
    getNftsMintedError: "Error on get NFTs minted",
    mintKycIncompleteError: "Please complete your registration to create NFTs",
    openPhoneCamera: "Please! Open your phone camera to collect your NFT",
    nftCollected: "NFT Collected",
    nftAlreadyCollected: "You already collect this NFT",
    nftImageUploadError: "Error on upload your image to the server",
    nftSaveError: "Error on save NFT",
    errorOnRequestGeolocation: "Error on request geolocation permission",
    permissionDenied: "Permission denied!",
    errorShare: "Error on share",
    nftNotFound: "NFT not found",
    nftRemoved: "NFT Removed",
    nftRemovedError: "Error on remove NFT",
    getUserPreferencesError: "Error on get user preferences",
    saveShareLocationError: "Error on save share location",
    savePersonalInfoError: "Error on save share personal info",
    saveNicknameError: "Error on save nickname",
    nicknameExist: "Nickname already exists, please enter another one",
    errorLocationRequired: "your location is required to be accessed, review permissions",
    errorUploadImage: "Error on upload image",
    errorDeleteAccount: "Error on delete account",
  },
};
