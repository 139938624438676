import { extendTheme } from "native-base";

export const wakameTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        rounded: "1000px",
      },
      defaultProps: {
        colorScheme: "yellow",
      },
    },
  },

  colors: {
    primary: {
      50: "#E4E4E4",
      100: "#C9C9C9",
      200: "#B8B8B8",
      300: "#ACACAC",
      400: "#787878",
      500: "#6C6C6C",
      600: "#636363",
      700: "#515151",
      800: "#070707",
      900: "#363636",
    },
    amber: {
      400: "#d97706",
    },
    yellow: {
      50: "#000000",
      100: "#000000",
      200: "#000000",
      300: "#000000",
      400: "#000000",
      500: "#000000",
      600: "#000000",
      700: "#000000",
      800: "#000000",
      900: "#000000",
    },
  },

  fontConfig: {
    baskervilleregular: {
      100: {
        normal: "LibreBaskervilleRegular",
        italic: "LibreBaskervilleRegular",
      },
      200: {
        normal: "LibreBaskervilleRegular",
        italic: "LibreBaskervilleRegular",
      },
      300: {
        normal: "LibreBaskervilleRegular",
        italic: "LibreBaskervilleRegular",
      },
      400: {
        normal: "LibreBaskervilleRegular",
        italic: "LibreBaskervilleRegular",
      },
      500: {
        normal: "LibreBaskervilleRegular",
      },
      600: {
        normal: "LibreBaskervilleRegular",
        italic: "LibreBaskervilleRegular",
      },
    },
    graphikregular: {
      100: {
        normal: "GraphikRegular",
        italic: "GraphikRegular",
      },
      200: {
        normal: "GraphikRegular",
        italic: "GraphikRegular",
      },
      300: {
        normal: "GraphikRegular",
        italic: "GraphikRegular",
      },
      400: {
        normal: "GraphikRegular",
        italic: "GraphikRegular",
      },
      500: {
        normal: "GraphikRegular",
      },
      600: {
        normal: "GraphikRegular",
        italic: "GraphikRegular",
      },
    },
  },

  fonts: {
    heading: "GraphikRegular",
    body: "GraphikRegular",
    mono: "GraphikRegular",
    LibreBaskerville: "LibreBaskervilleRegular",
  },
});
