import { ptBR } from "./pt/pt-br";
import { enUS } from "./en/en-us";
import { esp } from "./es/esp";
import { COOKIE_LANGUAGE_LABEL } from "../constants/cookie";

type LanguageTypes = "pt-BR" | "en" | "es";

const languages = {
  "pt-BR": ptBR,
  en: enUS,
  es: esp,
};

const item: any = localStorage.getItem(COOKIE_LANGUAGE_LABEL);
let locale: LanguageTypes = "en";
if (["pt-BR", "en", "es"].indexOf(item) > -1) {
  locale = item;
}

export const currentLanguage = locale;
export const translate = languages[locale];
