import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  HStack,
  IconButton,
  Image,
  Input,
  Pressable,
  Spacer,
  Spinner,
  Switch,
  Text,
  TextArea,
  VStack,
  Wrap,
} from "native-base";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import { translate } from "../../shared/i18n/translate";
import {
  uploadCloudStorage,
  uploadImage,
} from "../../shared/services/file.service";
import { saveNftInfo } from "../../shared/services/nft.service";
import { useToast } from "native-base";
import {
  isCompletedKyc,
  userPreferences,
} from "../../shared/services/user.service";
import { auth } from "../../firebase.config";
import Header from "../../shared/components/Header";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { reverseGeocoding } from "../../shared/services/geocoding.service";
import { UserPreferencesContext } from "../../contexts/user-preferences";
import { onAuthStateChanged } from "firebase/auth";

export interface MintData {
  tags: string[];
  image: string;
}

const MintNft = () => {
  const useTranslate = translate.pages.mint;
  const navigate = useNavigate();
  const userPreferencesContext = useContext(UserPreferencesContext);
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [createIsLoading, setCreateIsLoading] = useState<boolean>(false);
  const [locationInfo, setLocationInfo] = useState<String>("");
  const [formMintData, setFormMintData] = React.useState<any>({
    tags: [],
    creationDate: new Date(),
    creationTime: new Date(),
  });
  const toast = useToast();
  const [avaliableFromDateTitle, setAvaliableFromDateTitle] = useState<string>(
    useTranslate.avaliableFrom
  );
  const [avaliableFromTimeTitle, setAvaliableFromTimeTitle] = useState<string>(
    useTranslate.avaliableFrom
  );
  const [availableDate, setAvailableDate] = useState<any>(new Date());
  const [user, setUser] = useState<any>();
  const [buttonTopHeight, setButtonTopHeight] = useState<any>();

  useLayoutEffect(() => {
    function updateSize() {
      setButtonTopHeight(`calc(${window.innerHeight}px - 75px)`);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    getReverseGeolocation();
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
      }
    });
    return unsubscriber();
  }, []);

  useEffect(() => {
    if (!!user && !!userPreferencesContext.preferences) {
      checkIfCanMint();
    }
  }, [user, userPreferencesContext.preferences]);

  const checkIfCanMint = async () => {
    setLoading(true);
    if (
      !!auth.currentUser?.displayName &&
      !!auth.currentUser.phoneNumber &&
      !!auth.currentUser.email &&
      !!userPreferencesContext.preferences?.nickname
    ) {
      setLoading(false);
    } else {
      navigate("/personalinfo", { state: { from: "MINT" } });
      toast.show({ title: translate.alerts.mintKycIncompleteError });
    }
  };

  const getReverseGeolocation = async () => {
    try {
      const coords: any = await getCurrentLocation();
      const response = await reverseGeocoding(
        coords.locationLat,
        coords.locationLong
      );
      setLocationInfo(
        `${response.data.address.city}, ${response.data.address.country}`
      );
    } catch (error) {}
  };

  const backStep = () => {
    if (currentStep === 0) {
      return navigate("/", {
        state: {
          activeTab: 1,
        },
      });
    }
    setCurrentStep(currentStep - 1);
  };

  const createNFT = async () => {
    const locationInfo: any = {};
    try {
      const coords: any = await getCurrentLocation();
      locationInfo.locationLat = coords.locationLat;
      locationInfo.locationLong = coords.locationLong;
    } catch (error) {
      // locationInfo.locationLat = "";
      // locationInfo.locationLong = "";
    }
    let errorMessage = translate.alerts.errorUploadImage;
    try {
      setCreateIsLoading(true);
      const filename = `nft/${auth.currentUser?.uid}/${
        formMintData.nftName
      }_${new Date().getTime()}`;
      const url = await uploadCloudStorage(formMintData.nftImage, filename);
      errorMessage = translate.alerts.nftSaveError;
      const nftData = {
        imagePath: url,
        name: formMintData.nftName,
        tags: formMintData.tags,
        description: formMintData.description,
        numberOfCopies: parseInt(formMintData.numCopies),
        startDate: formMintData.avaliableFrom,
        ...locationInfo,
      };
      await saveNftInfo(nftData);
      navigate("/", { state: { nft: nftData } });
      setCreateIsLoading(false);
    } catch (error) {
      setCreateIsLoading(false);
      toast.show({ title: errorMessage });
    }
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve({
            locationLat: position.coords.latitude.toString(),
            locationLong: position.coords.longitude.toString(),
          });
        },
        () => {
          reject(false);
        }
      );
    });
  };

  const updateTags = (index: number) => {
    let tempList = formMintData.tags;
    tempList.splice(index, 1);
    setFormMintData({
      ...formMintData,
      tags: tempList,
    });
  };

  const collectImageFromGallery = async () => {
    try {
      const b64: any = await uploadImage();
      setFormMintData({
        ...formMintData,
        nftImage: b64[0],
      });
    } catch (error) {}
  };

  const formatDate = (date: Date) => {
    return format(date, "dd LLLL uuuu");
  };

  const formatTime = (date: Date) => {
    return format(date, "kk:mm");
  };

  useEffect(() => {
    if (availableDate) {
      setAvaliableFromDateTitle(formatDate(availableDate));
      setAvaliableFromTimeTitle(formatTime(availableDate));
      setFormMintData({
        ...formMintData,
        avaliableFrom: availableDate,
      });
    }
  }, [availableDate]);

  const AvailableDatepickerDate = forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <Pressable onPress={onClick}>
        <HStack space="15px" alignItems="center">
          <Image source={{ uri: Images.calendar }} size="20px" />
          <Text lineHeight="10px" pt="5px">
            {avaliableFromDateTitle}
          </Text>
          <Spacer />
          <Image source={{ uri: Images.chevronRight }} w="8px" h="13px" />
        </HStack>
      </Pressable>
    )
  );

  const AvailableDatepickerTime = forwardRef(
    ({ value, onClick }: any, ref: any) => (
      <Pressable onPress={onClick}>
        <HStack space="15px" alignItems="center">
          <Image source={{ uri: Images.clock }} size="20px" />
          <Text lineHeight="10px" pt="5px">
            {avaliableFromTimeTitle}
          </Text>
          <Spacer />
          <Image source={{ uri: Images.chevronRight }} w="8px" h="13px" />
        </HStack>
      </Pressable>
    )
  );

  const validForm = () => {
    try {
      if (!formMintData.nftImage) {
        return false;
      }
      if (!formMintData.avaliableFrom) {
        return false;
      }
      // if (
      //   formMintData.tags.length === 0 ||
      //   formMintData.tags.some((tag: string) => tag.length === 0)
      // ) {
      //   return false;
      // }
      if (!formMintData.numCopies || Number(formMintData.numCopies) <= 0) {
        return false;
      }
      if (formMintData.nftName.length === 0) {
        return false;
      }
      if (formMintData.description.length === 0) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const addTag = () => {
    setFormMintData({
      ...formMintData,
      tags: [...formMintData.tags, ""],
    });
  };

  return (
    <VStack minH="100vh" w="100vw" alignItems="center" space="15px">
      <Header
        backAction={() => {
          backStep();
        }}
        title={useTranslate.title}
        avatar={true}
        fixed={true}
      />

      <Center h="20px" />

      {loading ? (
        <Center>
          <Spinner size={40} />
        </Center>
      ) : (
        <>
          {/* BUTTON MINT */}
          <Button
            position="fixed"
            top={buttonTopHeight}
            zIndex="1000"
            bgColor={validForm() ? "#58EE4A" : "#D9D9D9"}
            w="calc(100vw - 30px)"
            maxW="520px"
            disabled={!validForm()}
            onPress={() => {
              if (!createIsLoading) {
                createNFT();
              }
            }}
          >
            {createIsLoading ? (
              <Spinner height="30px" />
            ) : (
              <Text
                bold
                fontSize="20px"
                color={validForm() ? "#ffffff" : "#979797"}
              >
                {translate.pages.mint.mint}
              </Text>
            )}
          </Button>

          {/* IMAGE */}
          <HStack>
            {formMintData.nftImage ? (
              <Pressable onPress={() => collectImageFromGallery()}>
                <img
                  src={formMintData.nftImage}
                  alt=""
                  style={{ maxHeight: "200px", maxWidth: "80vw" }}
                />
              </Pressable>
            ) : (
              <Center>
                <IconButton
                  onPress={() => collectImageFromGallery()}
                  backgroundColor={"gray.500"}
                  borderWidth={8}
                  borderRadius="20px"
                  borderColor={"gray.300"}
                  icon={
                    <Image
                      size={"24px"}
                      source={{ uri: Images.gallery }}
                      alt="gallery"
                    />
                  }
                />
              </Center>
            )}
          </HStack>

          {/* NAME */}
          <Input
            type="text"
            w="calc(100vw - 50px)"
            maxW="500px"
            height={45}
            placeholder={useTranslate.nftName}
            value={formMintData.nftName}
            onChangeText={(text) =>
              setFormMintData({ ...formMintData, nftName: text })
            }
          />

          {/* QUANTITY */}
          <Input
            value={formMintData.numCopies}
            keyboardType="decimal-pad"
            w="calc(100vw - 50px)"
            maxW="500px"
            height={45}
            placeholder={useTranslate.numCopies}
            onChangeText={(text) => {
              let numCopies = Number(text.replace(/[^0-9.,]+/, ""));
              if (numCopies > 99999999) {
                numCopies = 99999999;
              } else if (numCopies < 0) {
                numCopies = 0;
              }
              setFormMintData({
                ...formMintData,
                numCopies,
              });
            }}
          />

          {/* TAGS */}
          <HStack
            w="calc(100vw - 50px)"
            maxW="500px"
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            {formMintData.tags.map((tag: string, index: number) => (
              <HStack
                key={index}
                borderWidth="1px"
                borderColor="#B5B5B5"
                h="40px"
                w="calc(50% - 5px)"
                alignItems="center"
                pr="10px"
                borderRadius="5px"
                mb="10px"
                mr={index % 2 === 0 ? "10px" : "0px"}
              >
                <Input
                  w="calc(100% - 15px)"
                  variant="unstyled"
                  value={formMintData.tags[index]}
                  autoFocus={index === formMintData.tags.length - 1}
                  onChangeText={(text) => {
                    formMintData.tags[index] = text;
                    setFormMintData({
                      ...formMintData,
                      tags: formMintData.tags,
                    });
                  }}
                />
                <Pressable onPress={() => updateTags(index)}>
                  <Image source={{ uri: Images.close }} size="20px" />
                </Pressable>
              </HStack>
            ))}
            <Pressable w="calc(50% - 5px)" onPress={addTag}>
              <HStack
                borderWidth="1px"
                borderColor="#B5B5B5"
                h="40px"
                alignItems="center"
                justifyContent="center"
                px="10px"
                borderRadius="5px"
                mb="10px"
                w="100%"
              >
                <Text>{translate.pages.nftpage.addTags}</Text>
              </HStack>
            </Pressable>
          </HStack>

          {/* DESCRIPTION */}
          <TextArea
            type="text"
            w="calc(100vw - 50px)"
            maxW="500px"
            placeholder={useTranslate.nftDesc}
            value={formMintData.description}
            onChangeText={(text) =>
              setFormMintData({ ...formMintData, description: text })
            }
            autoCompleteType={undefined}
          />

          {/* DATETIME AND LOCATION */}
          <VStack
            w="calc(100vw - 50px)"
            maxW="500px"
            borderColor="#D9D9D9"
            borderWidth="1px"
            borderRadius="5px"
            py="10px"
            px="15px"
            space="10px"
          >
            <DatePicker
              selected={availableDate}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              onChange={(date: Date) => setAvailableDate(date)}
              customInput={<AvailableDatepickerDate />}
            />
            <Divider />
            <DatePicker
              selected={availableDate}
              timeInputLabel="Time:"
              dateFormat="MM/dd/yyyy h:mm aa"
              showTimeInput
              onChange={(date: Date) => setAvailableDate(date)}
              customInput={<AvailableDatepickerTime />}
            />
            <Divider />
            <HStack space="15px">
              <Image source={{ uri: Images.map2 }} w="20px" h="17px" />
              <Text>{locationInfo}</Text>
            </HStack>
          </VStack>

          {/* LOCATION */}
          {/* <HStack
          borderRadius="5px"
          w="calc(100vw - 50px)"
          maxW="500px"
          space="10px"
          alignItems="center"
          justifyContent="space-between"
          borderColor="#B5B5B5"
          borderWidth="1px"
          p="10px"
        >
          <Image source={{ uri: Images.mark }} h="20px" w="17px" />
          <VStack flexGrow="1">
            <Text>{locationInfo}</Text>
          </VStack>
          <Switch
            size="lg"
            offTrackColor="#e9e9e9"
            onTrackColor="#4CD964"
            onThumbColor="#fafafa"
            offThumbColor="#fafafa"
            isChecked={true}
            onToggle={(value) => {}}
          />
        </HStack> */}
        </>
      )}

      {/* SPACE ON END */}
      <Center h="100px" />
    </VStack>
  );
};

export default MintNft;
