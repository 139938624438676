export const esp = {
  pages: {
    home: {
      collect: "COLECTAR",
      mint: "CREAR",
      descriptionCollect: {
        swipe1: "DESLIZA",
        swipe2: "A LA DERECHA PARA CREAR",
        swipe3: "NFTS",
        line1: "PRESIONA WAKAME",
        line2: "PARA COLECTAR",
        line3: "TU PRIMER NFT",
      },
      descriptionMint: {
        swipe1: "DESLIZA",
        swipe2: "A LA IZQUIERDA PARA CREAR",
        swipe3: "NFTS",
        line1: "PRESIONA +",
        line2: "PARA CREAR TU",
        line3: "PRIMER NFT",
      },
    },
    profile: {
      completeProfile: "Complete su perfil",
      profile: "Perfil",
      name: "Nombre",
      userName: "Nombre de Usuario",
      email: "E-mail",
      editProfileInfo: "Editar información del perfil",
      lang: "Idioma",
      settings: "Configuraciones",
      faq: "FAQ",
      helpAndSupp: "Contáctenos",
      termsAndCond: "Términos y Condiciones",
      privacyPolicy: "Política de privacidad",
      privacy: "Privacidad",
      phoneNumber: "Número de Teléfono",
      wallet: "Cartera",
      edit: "Editar",
      walletAddress: "Dirección de la Cartera:",
      walletAddressCopied: "Dirección de la Cartera copiada",
      mail: "E-mail",
      telegram: "Telegram",
      whatsapp: "WhatsApp",
      account: "Cuenta",
      help: "Ayuda",
      privacyOptions: "Opciones de privacidad",
      deleteAccount: "Eliminar Cuonta",
      deleteAccountMessage: "Esto eliminará todos los datos. Esta acción no se puede deshacer. Los datos eliminados no se pueden recuperar.",
    },
    languages: {
      title: "Idioma",
      suggested: "Sugerido",
      enUs: "Inglés (US)",
      ptBr: "Portugués  (PT-BR)",
      esp: "Español (ESP)",
    },
    settings: {
      title: "Configuraciones",
      shareLocalization: "Compartir Ubicación",
      shareLocalizationDesc: "Text about what means Share Localization bla bla",
      sharePersonalInfo: "Compartir Información Personal",
      sharePersonalInfoDesc: "Text about what means Share Personal Info bla bla",
      security: "Seguridad",
      deleteAccount: "Eliminar Cuenta",
      deleteAccountDescription:
        "Esto eliminará todos los datos. Esta acción no se puede deshacer. Los datos eliminados no se pueden recuperar.",
      delete: "Eliminar",
      cancel: "Cancelar",
      logout: "Cerrar sesión",
      locationPersonalInfo:
        "Su ubicación y su información personal serán compartidos, de acuerdo con los términos de uso.",
      privacyOptions: "Opciones de Privacidad",
      accountOptions: "Opciones de cuenta",
    },
    mint: {
      title: "CREAR",
      selectImage: "sube tu foto o saca una",
      nftName: "Nombre del NFT",
      tags: "Agrega TAGs separadas por comas",
      nftDesc: "Descripción del NFT",
      numCopies: "Cantidad de NFTs",
      creationDate: "Fecha de Creación",
      creationTime: "Hora de Creación",
      avaliableFrom: "Disponible a partir de",
      location: "Ubicación",
      next: "SEGUIENTE",
      previous: "ANTERIOR",
      submmitButton: "CREAR NFT",
      mint: "Crear",
    },
    phoneSignup: {
      inputPhoneMensage:
        "Al continuar, recibirás un mensaje de texto para confirmación.Cargos de servicio de mensajes y datos pueden ser aplicados",
      inputCodeMensage: "Verifica el código SMS enviado a",
      phoneError: "u número es inválido. Inténtalo de nuevo.",
      codeError:
        "El código de verificación SMS usado para crear la credencial de autenticación de teléfono es inválido. Por favor, reenvía el mensaje de verificación SMS y asegúrate de usar el código de verificación correcto.",
      confirmButton: "CONFIRMAR",
      enterSMSCode: "Por favor, ingresa el código de 6 dígitos enviado a",
      resendCode: "REENVIAR CÓDIGO",
    },
    nftpage: {
      nftMintCode: "Código de creación de NFT",
      nftMintCodeCopied: "Código de NFT copiado",
      collect: "COLECTAR NFT",
      collected: "NFT COLECTADO",
      description: "Descripción",
      seeMore: "ver más",
      seeLess: "ver menos",
      deleteNft: "Eliminar NFT",
      deleteNftDescription:
        "Esto eliminará tu NFT. Esta acción no se puede deshacer. El NFT eliminado no se puede recuperar.",
      cancel: "Cancelar",
      delete: "Eliminar",
      available: "Disponible",
      share: "Compartir",
      qrcodeCopy: "Copiar QR-CODE",
      addTags: "+ add tags",
      nftCode: "CÓDIGO NFT",
      contractAddressCopied: "Dirección del Contrato Copiado",
      unknown: "Desconocido",
      nftUnavailable: "NFT Unavailable",
    },
    collectPage: {
      title: "Colectar NFT",
      enterNFTcode: "Entrar con el código do NFT",
    },
    personalInfo: {
      title: "Información Personal",
      buttonText: "Completar",
      walletAddress: "Dirección de la cartera",
      name: "Nombre",
      email: "E-mail",
      phone: "Teléfone",
      profileName: "Perfil del Usuário",
    },
    privacy: {
      title: "Política de privacidad",
      p1: "Gracias por visitar https://wakame.app (el \"Sitio\") o acceder a la Aplicación Wakame (la \"Aplicación\"). El Sitio y la Aplicación son propiedad y están operados por Wakame LLC (\"Wakame\" \"Nosotros\", \"Nuestro\", \"Nosotros\" o \"Compañía\"), una compañía de BVI, que es el controlador de datos de Su Información personal recopilada durante el uso de este Sitio o Aplicación.",
      p2: "Como parte de nuestras operaciones comerciales diarias, recopilamos información personal de nuestros clientes y clientes potenciales para proporcionar nuestros productos y servicios y para garantizar que podamos satisfacer sus necesidades al proporcionar estos productos y servicios, así como al proporcionar usted con cualquier información respectiva Al aceptar esta política de privacidad, usted es consciente de que sus datos aún pueden compartirse con empresas de terceros seleccionadas con fines comerciales y promocionales, como publicidad, marketing e investigación de mercado, así como campañas promocionales y que , en este caso, Sus datos también estarán sujetos a las políticas de privacidad específicas de dichas empresas.",
      p3: "Esta Política de privacidad (\"Política de privacidad\") establece la base sobre la cual cualquier Información personal, como se define a continuación, recopilamos de usted (\"Usted\", \"Su\", \"Usuario\" o \"Cliente\"), o que Usted nos proporciona será procesado por Nosotros. Por \"Información personal\" nos referimos a cualquier información que, sola o en combinación con otros datos, le permite ser identificado directa o indirectamente, por ejemplo, Su nombre, dirección de correo electrónico, nombre de usuario y contacto detalles proporcionados por usted o recopilados por nosotros cuando utiliza nuestro sitio y/o aplicación",
      p4: "Lea atentamente lo siguiente para comprender qué datos recopilamos, cómo se utilizan esos datos y cómo podemos compartirlos",
      h1: "CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD",
      p5: "Podemos modificar esta Política de privacidad de vez en cuando, lo que se indicará cambiando la fecha en la parte inferior de este documento. Si hacemos cambios materiales, se lo notificaremos por correo electrónico (enviado a la dirección de correo electrónico que usted proporcionó). ), mediante un aviso, antes de que el cambio entre en vigor o según lo exija la ley.",
      h2: "INFORMACION QUE RECOPILAMOS",
      p6: "Para acceder a nuestro sitio web o aplicación, preliminarmente no recopilamos ninguna información personal ni cookies. Bajo su acción para ingresar y utilizar la aplicación o sitio web, debe proporcionarnos la información necesaria para ingresar a su cuenta, que es su dirección de correo electrónico Email.",
      p7: "Para llevar a cabo la acción de recopilación de NFT en Wakame, ya sea en el Sitio o en la Aplicación, debe completar su registro y completar el formulario de recopilación de NFT. En ese momento, nos proporcionará Su nombre completo, foto de perfil, teléfono, dirección de correo electrónico y nombre de usuario.",
      p8: "No somos responsables de ningún problema que surja de los servicios prestados por terceros, ni de sus sistemas operativos, ni de cuestiones de privacidad, ni del contenido generado por terceros en Wakame",
      p9: "Cuando usted reclame la recopilación de NFT como se indicó anteriormente, le solicitaremos y recopilaremos su nombre completo, correo electrónico, foto de perfil y número de teléfono. Puede aceptar o no los términos de esta Política de privacidad y proporcionar su información. Si no está de acuerdo con esta Política de privacidad, no podrá acuñar o almacenar ese NFT específico, ni participar en ningún evento promocional, ni recibir ninguna información o campaña relacionada con el NFT. Sin embargo, su acceso a la plataforma se verá afectado. no se verá afectado por esta acción.",
      p10: "No recopilamos ni compartimos ninguna información personal confidencial sobre usted (esto incluye detalles sobre su raza o etnia, creencias religiosas o filosóficas, vida sexual, orientación sexual, opiniones políticas, afiliación sindical, información sobre su salud y genética, y datos biométricos) .",
      p11: "Toda la información se recopila cuando usted es:",
      l1: "Registrar una cuenta;",
      l2: "Uso de nuestra Plataforma o Sitio Web;",
      l3: "Correspondencia con nosotros por teléfono, correo electrónico, WhatsApp, Telegram u otros;",
      l4: "Recopilación y almacenamiento de NFT",
      h3: "PROPÓSITOS PARA LOS QUE RECOPILAMOS SU INFORMACIÓN PERSONAL",
      p12: "Recopilaremos y procesaremos su información personal de la siguiente manera y para los siguientes propósitos:",
      u1: "Ejecución de un contrato.",
      p13: "Procesamos datos personales para proporcionar nuestros Servicios, así como información sobre Nuestros productos y servicios en función de la relación contractual con nuestros clientes (es decir, para cumplir con nuestras obligaciones contractuales);",
      p14: "El procesamiento de datos personales tiene lugar para permitir la finalización de nuestro proceso de incorporación de clientes y el acceso a nuestros servicios y productos",
      u2: "Para salvaguardar los intereses legítimos.",
      p15: "Procesamos datos personales para proteger los intereses legítimos perseguidos por nosotros o por otros. Un interés legítimo es cuando tenemos un motivo comercial o comercial para usar su información. Los ejemplos de tales actividades de procesamiento incluyen lo siguiente:",
      l5: "Iniciar juicios y preparar Nuestra defensa en procesos contenciosos;",
      l6: "Medios y procesos que asumimos para brindar seguridad a los sistemas y TI de la Compañía, prevención de posibles delitos, seguridad patrimonial y controles de acceso;",
      l7: "Medidas para la gestión empresarial y desarrollo de productos y servicios;",
      l8: "Compartir sus datos con empresas de terceros para acuerdos promocionales celebrados con estos terceros, y",
      l9: "Gestión de riesgos.",
      u3: "Para proporcionarle productos y servicios o información sobre nuestros productos y servicios y para revisar sus necesidades en curso",
      p16: "Una vez que nos haya proporcionado con éxito su información personal, debemos usar su información personal para realizar nuestros servicios y cumplir con nuestras obligaciones con usted. También es de nuestro interés legítimo tratar de asegurarnos de que estamos brindando los mejores servicios para que que podemos revisar periódicamente sus necesidades en función de nuestra evaluación de su información personal para asegurarnos de que obtiene el beneficio de los mejores productos y servicios posibles de nuestra parte",
      u4: "Para ayudarnos a mejorar nuestros productos y servicios, incluidos los servicios de soporte, desarrollar y comercializar nuevos productos y servicios",
      p17: "Podemos, de vez en cuando, utilizar la información personal proporcionada por usted a través de su uso de los servicios y/o a través de encuestas de clientes para ayudarnos a mejorar nuestros servicios. Es nuestro interés legítimo utilizar su información personal en este manera de tratar de garantizar los más altos estándares en la prestación de nuestros servicios a usted.",
      u5: "Para enviarte encuestas.",
      p18: "De vez en cuando, podemos enviarle encuestas como parte de nuestra plataforma (sitio web o aplicación) proceso de comentarios de clientes y productos. Es de nuestro interés legítimo solicitar estos comentarios para tratar de garantizar que proporcionamos nuestros productos y servicios al más alto nivel. Sin embargo, de vez en cuando, también podemos pedirle que participe en otras encuestas realizadas por terceros que son socios o tienen acuerdos comerciales con nosotros y, si acepta participar en dichas encuestas, dependemos de su consentimiento para usar la información personal. información que recopilamos como parte de dicha investigación.",
      u6: "Para fines de marketing.",
      p19: "Podemos usar su información personal para enviarle comunicaciones de marketing por correo electrónico u otras formas acordadas (incluidas las campañas en las redes sociales), para asegurarnos de que siempre esté actualizado con nuestros últimos servicios de marketing, hacerlo en base a su consentimiento y preferencias de marketing registradas. Además, también podemos compartir sus datos con empresas asociadas seleccionadas para fines específicos de promoción comercial, marketing y estudios de mercado relacionados con el uso de nuestros productos y servicios",
      u7: "Para fines comerciales internos y mantenimiento de registros",
      p20: "Es posible que necesitemos procesar su información personal para fines comerciales e investigativos internos, así como para fines de mantenimiento de registros. Dicho procesamiento es de nuestro interés legítimo y es necesario para cumplir con nuestras obligaciones legales. Esto puede incluir cualquier comunicación que tengamos con usted sobre los productos y servicios que le brindamos y nuestra relación con usted. También mantendremos registros para garantizar que cumpla con sus obligaciones contractuales en virtud de los términos y condiciones que rigen nuestra relación con usted",
      p21: "La información personal que tenemos sobre usted debe ser precisa y actual. Por favor, manténganos informados si su información cambia durante su relación con nosotros",
      h4: "SEGURIDAD Y CONFIDENCIALIDAD",
      p22: "La Compañía respeta la privacidad de todos los usuarios que acceden al Sitio o la Aplicación y, por lo tanto, se compromete a tomar todas las medidas razonables para proteger a los clientes, solicitantes y visitantes del sitio existentes o potenciales. Nos comprometemos a proteger su privacidad.",
      p23: "La Compañía mantiene todos los datos personales de sus clientes y clientes potenciales de acuerdo con las leyes y regulaciones de privacidad y protección de datos aplicables. Contamos con las medidas y procedimientos técnicos y organizativos necesarios y apropiados para garantizar que su información permanezca segura en todo momento. ",
      p24: "Nuestras operaciones están respaldadas por una red de computadoras, servidores y otra infraestructura y tecnología de la información, incluidos, entre otros, proveedores de servicios externos como MongoDB App Services (MongoDB Inc)",
      p25: "Por lo tanto, la información personal y otros datos pueden exportarse fuera de la jurisdicción en la que reside. Su información personal puede procesarse y almacenarse en un país o en un país extranjero. En estas circunstancias, los gobiernos, los tribunales, las autoridades encargadas de hacer cumplir la ley o las agencias reguladoras en ese país o países pueden obtener acceso a su información personal a través de leyes extranjeras. Debe tener en cuenta que los estándares de privacidad de esos países pueden ser más bajos que los de la jurisdicción en la que reside",
      p26: "Desafortunadamente, la transmisión de información a través de Internet no es completamente segura. Si bien hacemos todo lo posible para proteger su información personal, no podemos garantizar la seguridad de los datos que nos transmite por correo electrónico o a través del sitio web; cualquier transmisión es su propio riesgo. Una vez que recibamos sus datos, utilizaremos procedimientos estrictos y características de seguridad para tratar de evitar el acceso no autorizado",
      h5: "DIVULGACIONES",
      p27: "La Compañía no divulgará ninguna información confidencial de sus clientes a terceros, excepto en las siguientes situaciones, en las que usted acepta que tenemos derecho a compartir su información personal:",
      l10: "En la medida en que lo exijan las leyes, normas o reglamentos aplicables;",
      l11: "Cualquier miembro de la Compañía, lo que significa que cualquiera de nuestras afiliadas y subsidiarias puede recibir dicha información",
      l12: "Cualquiera de nuestros proveedores de servicios y socios comerciales, con fines comerciales, como consultores especializados que hayan sido contratados para brindarnos servicios administrativos, financieros, legales, fiscales, de cumplimiento, de seguros, de TI, de recuperación de deudas, analíticos, encuestas o otros servicios;",
      l13: "Terceros seleccionados, incluidos proveedores de motores de búsqueda y análisis que nos ayudan a mejorar y optimizar los Servicios;",
      l14: "Terceros seleccionados con fines de promoción comercial, estudios de mercado y publicidad;",
      l15: "Cuando recopile cualquier NFT en nuestro Sitio o Aplicación, con el propósito de acciones promocionales específicas;",
      l16: "Si existe el deber de revelar;",
      l17: "Si nuestros intereses comerciales legítimos requieren divulgación;",
      l18: "A petición suya o con su consentimiento o para los descritos en esta Política de Privacidad.",
      p28: "Si la Compañía divulga Su información personal a proveedores de servicios y socios comerciales, para realizar los servicios solicitados por los clientes, dichos proveedores y socios pueden almacenar Su información personal en sus sistemas para cumplir con sus obligaciones legales y de otro tipo. Requerimos este servicio Los proveedores y socios comerciales que procesan información personal reconocen la confidencialidad de dicha información, se comprometen a respetar el derecho a la privacidad de cualquier cliente y a cumplir con todas las leyes de privacidad y protección de datos pertinentes y esta Política de privacidad",
      p29: "Todos los usuarios pueden acceder y verificar su información personal en nuestro poder enviando una solicitud por escrito a support@wakame.app y solicitar, si es necesario, rectificar cualquier información personal inexacta que podamos tener; borrar su información personal en ciertas circunstancias (es decir, cuando la información personal ya no sea necesaria y para cumplir con nuestros propósitos de procesamiento); restringir el procesamiento de su información personal cuando, por ejemplo, la información sea inexacta o ya no sea necesario que procesemos esa información o cuando haya ejercido su derecho para oponerse a nuestro procesamiento; y transfirió sus datos a un nuevo proveedor de servicios si ya no desea utilizar los Servicios. Puede ejercer estos derechos poniéndose en contacto con nosotros",
      p30: "Es posible que necesitemos solicitarle información específica para ayudarnos a confirmar su identidad y garantizar su derecho a acceder a su información personal (o ejercer cualquiera de sus otros derechos). Esta es una medida de seguridad para garantizar que la información personal no sea divulgado a cualquier persona que no tenga derecho a recibirlo. También podemos comunicarnos con usted para solicitar más información sobre su solicitud para acelerar nuestra respuesta",
      p31: "Esta Política de privacidad y el uso de Su información personal se aplican solo a la información que proporciona o que se recopila con su consentimiento al usar la Aplicación o acceder al Sitio. Se le advierte que si divulga información personal o información personal confidencial a través del uso de Internet, como salas de chat, comunidades, tablones de anuncios u otros foros públicos en línea, esta información puede ser recopilada y utilizada por otras personas o empresas sobre las que la Empresa no tiene control. Es su responsabilidad revisar la privacidad declaraciones, políticas, términos y condiciones de cualquier persona o compañía con la que elija vincularse o con la que elija contratar No somos responsables de las declaraciones de privacidad, políticas, términos, condiciones u otro contenido de cualquier Sitio que no sea de propiedad o administrado por nosotros.",
      h6: "CAMBIOS EN NUESTRA POLÍTICA DE PRIVACIDAD",
      p32: "Cualquier cambio que realicemos en nuestra política de privacidad en el futuro se publicará en esta página y, cuando corresponda, se le notificará por correo electrónico. Vuelva a consultar con frecuencia si hay actualizaciones o cambios en nuestra política de privacidad",
      h7: "SI TIENES UNA QUEJA",
      p33: "Si tiene alguna pregunta sobre cómo manejamos su información personal, comuníquese con nosotros primero. Haremos todo lo posible para resolver su inquietud. Las preguntas, comentarios y solicitudes con respecto a esta política de privacidad deben enviarse a contact@wakame .app. ",
      i1: "22 de julio",
    },
    terms: {
      title: "TÉRMINOS DE SERVICIO Y CONDICIONES DE USO DE LA PLATAFORMA WAKAME",
      p1: 'Estos Términos y Condiciones de Uso ("Términos") se celebran entre Usted ("Usuario" o "Usted") y WAKAME ("Empresa", "Nosotros", "Nos" o "Nuestro") en relación con Su acceso y uso de Nuestra Plataforma (el "Sitio" y/o la aplicación móvil, en lo sucesivo, la "Aplicación") que incluye, entre otros, la recopilación, a través de airdrops, tokens no fungibles ("NFT"), la creación de criptomonedas direcciones de billetera e interacción con nuestros servicios y software en línea y/o móviles proporcionados en o en relación con esos servicios (colectivamente, los "Servicios").',
      p2: "WAKAME ES UNA PLATAFORMA ELECTRÓNICA PARA VER, RECOGER Y ALMACENAR NFT, ASÍ COMO INTERACTUAR CON MARCAS Y PROMOCIONES DE TERCEROS A TRAVÉS DE ESTA HERRAMIENTA. NO SOMOS UN CORREDOR, INSTITUCIÓN FINANCIERA O PRESTAMISTA. WAKAME NO FACILITA LAS TRANSACCIONES ENTRE USUARIOS A CAMBIO POR DINERO O CRIPTOMONEDAS SIENDO IMPOSIBLE REALIZAR TRANSACCIONES A CAMBIO DE DINERO O CRIPTOMONEDAS POR TOKENS NO FUNGIBLES ENTRE CUALQUIER USUARIO Y/O EMPRESAS DENTRO DE LA PLATAFORMA.",
      p3: "Al crear una cuenta en la Plataforma, usted reconoce y acepta estos Términos, nuestra Política de privacidad (wakame.app/privacy) y todos los términos, pautas y reglas adicionales establecidos en la Plataforma, que se incorporan por referencia a estos Términos y expresamente aceptado y reconocido por el Usuario.",
      p4: "También debe monitorear la Plataforma o Su correo electrónico en busca de anuncios de la Compañía, ya que podemos agregar o cambiar estos Términos de vez en cuando. La Compañía se reserva el derecho de cambiar estos Términos a su entera discreción, y sin necesidad presentar cualquier justificación, mediante un simple aviso en la Plataforma.",
      t1: "DEFINICIONES",
      p5: "En estos Términos y en todos los documentos incorporados aquí por referencia, las siguientes palabras tienen los siguientes significados, a menos que se indique lo contrario:",
      l1: '"Afiliado" significa, en relación con la Compañía o el Usuario, una subsidiaria directa o indirecta de la Parte, una sociedad de cartera de la Parte y cualquier otra subsidiaria de esa sociedad de cartera;',
      l2: '"Aplicación" significa la aplicación móvil desarrollada y proporcionada por Nosotros donde se ubicará la Plataforma y donde el Usuario podrá acceder a la Plataforma;',
      l3: '"Derechos de autor" significa la propiedad exclusiva de todos los materiales en la Plataforma;',
      l4: '"Individuo" es una Persona capaz de usar la Plataforma y los Servicios de acuerdo con estos Términos;',
      l5: '"Leyes" significa todas las leyes, estatutos, órdenes, reglamentos, normas, tratados y/u obligaciones o requisitos oficiales promulgados, promulgados, emitidos, ratificados, ejecutados o administrados por cualquier Gobierno que se apliquen a Usted en la Plataforma; ',
      l6: '"Marcas" significa todas las marcas comerciales, marcas de servicio y nombres comerciales, incluidas las marcas denominativas y las marcas de diseño relacionadas con WAKAME;',
      l7: '"NFT": significa Token no fungible, que es un tipo de clave electrónica criptográfica (Token) emitida de forma única. La titularidad del NFT se garantiza a través del certificado digital de autenticidad y unicidad, en este caso, el contenido digital vinculado al token criptográfico no fungible acuñado en la Plataforma y distribuido a los usuarios que quieran recogerlo.',
      l8: '"Persona" incluye un individuo, asociación, sociedad, corporación, otra entidad corporativa, fideicomiso, sucesión y cualquier forma de organización, grupo o entidad reconocida como personalidad jurídica;',
      l9: '"Servicios" significa cualquier servicio o producto puesto a su disposición en o a través de la Plataforma o proporcionado por Nosotros',
      l10: '',
      l11: '"Sitio" significa nuestro sitio web donde se ubicará la Plataforma y donde el Usuario podrá acceder a la Plataforma;',
      l12: '"Términos" significa estos términos y condiciones, ya que pueden ser enmendados, cambiados o actualizados de vez en cuando, incluidas las siguientes políticas y páginas de otras plataformas;',
      l13: 'Los "tokens" son un registro encriptado en una red distribuida de computadoras llamada blockchain;',
      l14: '"Usuario" significa la Persona o Entidad que utiliza la Plataforma y los Servicios.',
      l15: '"WAKAME" es una plataforma electrónica accesible a través de su sitio web o aplicación que permite la visualización, acuñación y almacenamiento de NFT creados por Usuarios o Empresas para Usuarios en forma de airdrops gratuitos y promocionales, que contienen Contenido digital, representado en un NFT.',
      t2: "I. CUENTA DE USUARIO",
      p6: "Es posible que deba registrarse en nuestra aplicación o sitio web para acceder y utilizar ciertas funciones de la plataforma. Si elige registrarse en la Plataforma, acepta proporcionar y mantener información verdadera, precisa, actual y completa sobre usted según lo solicitado en nuestro formulario de registro. Los datos de registro y cierta otra información sobre usted se rigen por nuestra Política de privacidad, que usted acepta al iniciar sesión en la Plataforma. Debe tener al menos 16 años de edad para registrarse en una cuenta como Usuario. Usted es el único responsable de la seguridad de su cuenta y de cualquier cosa que ocurra cuando usted (o cualquier persona no autorizada que robe sus datos) inicie sesión en su cuenta, ya sea a través de la aplicación o la plataforma web",
      p7: "Usted es responsable de mantener la confidencialidad, la seguridad y el acceso privado de su cuenta y contraseña, y es completamente responsable de todas y cada una de las actividades que ocurran bajo el uso de su cuenta. Usted acepta expresamente (a) notificar inmediatamente a WAKAME cualquier uso no autorizado o intento de uso de su cuenta o cualquier intento no autorizado identificado de robar su contraseña o cuenta o cualquier otra violación de seguridad que identifique No seremos responsables de ninguna pérdida o daño que surja de su incumplimiento de esta Sección y de cualquier pérdida de cualquier NFT almacenado en su cuenta.",
      p8: "Usted acepta que WAKAME tiene derecho a suspender, pausar o cancelar inmediatamente su cuenta o acceso a la plataforma, o cancelar su cuenta si fomenta, favorece, representa y/o facilita actividades o prácticas indebidas o ilegales, como , discriminación de cualquier tipo, incumplimiento de cualesquiera derechos fundamentales u obligaciones legales, delitos contra el honor, la vida o los bienes, el ocultamiento o suministro de datos falsos y la violación de las presentes Condiciones de Uso.",
      p9: 'Puedes acuñar ("Coleccionar") solo un NFT por colección.',
      t3: "II. COMUNICACIÓN",
      p10: "Usted acepta que podemos enviarle comunicaciones por correo electrónico o mensajes sobre el estado de su recepción de un NFT y otras comunicaciones relacionadas con su interacción con la Plataforma, incluidas, entre otras, notificaciones sobre el estado de un NFT o airdrop campaña a la que le interesa unirse.",
      p11: "Usted acepta que nosotros y los terceros que tienen acceso a sus datos a través de la recopilación de NFT podemos enviarle comunicaciones promocionales por correo electrónico, incluidos, entre otros, boletines, ofertas especiales, encuestas y otras noticias e información que consideramos que esto estar en su mejor interés. Es importante que sepa que puede optar por no recibir comunicaciones promocionales en cualquier momento siguiendo las instrucciones proporcionadas; sin embargo, al optar por no recibir estas comunicaciones, es posible que no sea elegible para participar en algunas campañas promocionales",
      t4: "III. PROPIEDAD INTELECTUAL DE WAKAME",
      p12: "Todos los contenidos, es decir, textos, audiovisuales, obras musicales, marcas, fotografías, software y demás contenidos protegidos por derechos de propiedad intelectual son propiedad exclusiva de terceros que han autorizado su uso en la Plataforma y han desarrollado los contenidos y están protegidos por las leyes y tratados internacionales, negándose su copia, reproducción o cualquier otro uso, los infractores quedan sujetos a las sanciones civiles y penales correspondientes por tí.",
      p13: "Any and all content related to the functioning of WAKAME, including the tool’s source code, the Wakame trademark, developed software, App, and Site are the sole property of WAKAME, and shall not be replicated, or copied, used, or engineered reversed by You.",
      p14: "La propiedad intelectual resultante de la prestación de los Servicios será propiedad exclusiva de la Compañía. La Compañía será el único propietario con respecto a los derechos de autor, dominios, códigos, soluciones raíz, software y codificación no públicos o de código abierto, patentes, marcas registradas, secretos comerciales y cualquier otro derecho de propiedad intelectual sobre los servicios desarrollados bajo el alcance de este Acuerdo.",
      t5: "IV. CREATION OF CONTENT BY THE USER",
      p15: "El Usuario se obliga a utilizar la Plataforma y su(s) contenido(s) de conformidad con el ordenamiento jurídico, debiendo abstenerse de obtener, o intentar obtener, los contenidos por medios distintos de los que, en cada caso, se hayan realizado disponibles para ese propósito.",
      p16: 'Los usuarios que se registren y proporcionen la información adecuada solicitada podrán crear NFT en la Plataforma ("Proveedor de contenido"). Al crear cualquier contenido y NFT en la Plataforma, el Usuario garantiza que todo el contenido cargado o utilizado en relación con el uso de WAKAME no viola ningún derecho de propiedad intelectual de terceros.',
      p17: "The Content Provider is limited to generate collections of a maximum of 100.000 NFTs per collection. If WAKAME identifies that the User is spamming by creating multiple collections of NFTs, it may at its own discretion suspend or exclude the Content Creators’ account without any previous notice.",
      p18: "El NFT generado debe observar los requisitos mínimos para su creación, tales como tipo de documento y tamaño de imagen, el cual debe estar limitado a un tamaño de documento de 20 MB (Megabytes), utilizando el formato .JPEG o .PNG. Las colecciones o NFT individuales deben tener un nombre, una descripción e indicar la fecha de creación.",
      b1: "WAKAME no es responsable ni será responsable de ninguna infracción de los derechos de propiedad intelectual que resulte del contenido cargado o utilizado por el usuario en la plataforma",
      p19: "WAKAME tiene derecho a eliminar cualquier y todo el contenido proporcionado, creado o cargado por el usuario a través de la plataforma a su entera discreción",
      p20: "WAKAME no se hace responsable de ningún daño causado al usuario o a cualquier otra persona física o jurídica tras la retirada de dicho contenido.",
      p21: "Todo el contenido creado o proporcionado por el Proveedor de contenido no puede contener información falsa, sin derechos de autor no acompañada de la referencia adecuada, de naturaleza racista u homófoba, o cualquier contenido que constituya un delito contra un tercero, siendo el Proveedor de contenido el único responsable de cualquier daño causado por cualquier material generado y cargado en la Plataforma y acuñado como NFT, incluso si está publicado en la Plataforma",
      p22: "Si el Proveedor de Contenidos se involucra o practica alguno de los actos o actitudes mencionados en el ítem anterior, que puedan denigrar la imagen de la Plataforma, WAKAME podrá suspender y/o retirar su acceso y los NFT generados, sin necesidad de notificación previa, y no será responsable y no tendrá obligación de indemnizar por cualquier daño que resulte de esta acción.",
      p23: "Si WAKAME es demandado, condenado o sufre algún daño debido a un procedimiento administrativo o judicial relacionado con el contenido generado y proporcionado por el Proveedor de contenido, incluidos los daños a la imagen y la marca, el Proveedor de contenido deberá reembolsar todos los costos y reparar todos los daños causados. a WAKAME, de los daños materiales así como de los que resulten de la denigración de su imagen y Plataforma.",
      t6: "V. QUEJAS DERIVADAS DE VIOLACIÓN DE DERECHOS",
      p24: "WAKAME respeta la propiedad intelectual y los derechos de terceros y pedimos a nuestros usuarios que hagan lo mismo. Si crees que tu trabajo ha sido infringido en términos de derechos de autor y/o propiedad industrial, o que tus derechos han sido infringidos en cualquier de lo contrario, debe notificar a WAKAME su reclamo de infracción de acuerdo con el procedimiento que se establece a continuación.",
      p25: 'WAKAME investigará los avisos que involucren supuestas infracciones y tomará las medidas razonables conforme a las leyes aplicables con respecto a cualquier reclamo de infracción de derechos. La notificación que describe el evento debe enviarse a support@wakame.app (Asunto: "Reclamación de derechos de autor").',
      t7: "VI. DECLARACIÓN O GARANTÍA",
      p26: "Para poder acceder a algunos de los Servicios, los Usuarios pueden necesitar actualizar o actualizar el hardware o software en sus sistemas informáticos o actualizar sus teléfonos móviles. WAKAME no asume ninguna responsabilidad por la falta de acceso a los Servicios, en su totalidad o en parte, ya sea debido al sistema del Usuario, la red de Internet o cualquier otra causa",
      p27: "WAKAME no garantiza que el acceso al Sitio, a la Aplicación o a cualquiera de los Servicios sea ininterrumpido o libre de errores. Sin embargo, WAKAME no será responsable de ninguna función inaccesible o de la falta de disponibilidad de los Servicios",
      p28: "Usted declara y garantiza que:",
      al1: "Nuestro Servicio no le permite vender, comprar, enviar o adquirir NFT. WAKAME no es responsable de ninguna transacción realizada por Usted, directamente en la Blockchain que admite la acuñación de NFT y que almacena su información",
      al2: "Existen riesgos asociados con el uso de un marco basado en Internet, incluidos, entre otros, el riesgo de hardware, software y conexiones a Internet, el riesgo de introducir software malicioso y el riesgo de que terceros obtengan acceso no autorizado a la información almacenada en su billetera. Usted acepta y reconoce que WAKAME no será responsable de ninguna falta de comunicación, interrupciones, errores, distorsiones o demoras que pueda experimentar al usar los NFT, sin importar la causa",
      al3: "El régimen regulatorio que rige las tecnologías blockchain, las criptomonedas y los tokens es incierto, y las nuevas regulaciones o políticas pueden afectar negativamente el desarrollo de la Subasta y/o el Servicio y la utilidad de las NFT",
      al4: "Existen riesgos asociados con la recepción de Contenido generado por el usuario, incluido, entre otros, el riesgo de comprar NFT falsificados y mal etiquetados y activos que son o pueden no ser transferibles o eliminarse en el futuro. Wakame se reserva el derecho de retener colecciones, contratos, y bienes afectados por cualquiera de estas u otras cuestiones.",
      p29: "No puedes:",
      al5: "Usar la Plataforma si alguna Ley aplicable prohíbe, penaliza, sanciona o expone al Sitio, la Aplicación o la Plataforma a cualquier responsabilidad por cualquier Servicio que se le proporcione u ofrezca a Usted;",
      al6: "Usar la Plataforma si reside en países que prohíben la recepción, almacenamiento y uso de cualquier tipo de activos criptográficos o criptomonedas;",
      al7: "Usar la plataforma para interferir o subvertir los derechos u obligaciones de WAKAME o los derechos u obligaciones de cualquier otro sitio web, aplicación, usuario o cualquier otro tercero o causar responsabilidad legal para WAKAME, otro sitio web, aplicación o usuario;" ,
      al8: "Aprovechar cualquier falla técnica, mal funcionamiento, falla, retraso, incumplimiento o violación de seguridad en el Sitio, Aplicación o Plataforma;",
      p30: "El incumplimiento de cualquiera de estos Términos puede resultar en la terminación de la cuenta del Usuario o el acceso a la Plataforma. Cualquier intento por su parte de interrumpir o interferir con los Servicios, incluido, entre otros, socavar o manipular el funcionamiento legítimo del Sitio o App, es un incumplimiento de estos Términos y puede ser un incumplimiento o violación de las leyes penales y civiles",
      t8: "VII. RIESGOS Y LIMITACIONES DE RESPONSABILIDAD",
      p31: "La Compañía ha hecho y seguirá haciendo esfuerzos para incluir información precisa y actualizada en la Plataforma. Sin embargo, WAKAME no puede garantizar que la información publicada en la Plataforma sea precisa, completa o adecuada para cualquier propósito. En ningún caso, WAKAME, sus directores, funcionarios, empleados, contratistas o agentes serán responsables de ningún daño incidental, especial, consecuente o indirecto o de ningún daño directo que resulte del uso o la prestación de los Servicios, o cualquier falla en los servicios",
      p32: "El usuario asume toda la responsabilidad por el uso de los Servicios. La Compañía puede cambiar o eliminar materiales del Sitio, la Aplicación, la Plataforma o estos Términos en cualquier momento",
      p33: "Usted comprende y acepta que nosotros, nuestras subsidiarias, afiliadas y otorgantes de licencias no seremos responsables ante usted o cualquier tercero por cualquier daño indirecto, incidental, especial, consecuente o ejemplar en el que pueda incurrir, que incluye, entre otros, cualquier pérdida de ganancias (ya sea directa o indirectamente), pérdida de buena voluntad o reputación comercial, pérdida de datos, costo de comprar bienes o servicios sustitutos, o cualquier otra pérdida intangible, incluso si se nos ha informado de la posibilidad de dichos daños",
      p34: "Existen riesgos asociados con el uso o el acceso a la Plataforma, incluidos, entre otros, fallas en el hardware, el software y las conexiones a Internet. La Compañía no es responsable de la transmisión adecuada y/o completa de la información contenida en cualquier comunicación electrónica o de la propia Compañía. comunicación electrónica, ni por cualquier interrupción, distorsión o retraso en su entrega o recepción, por cualquier motivo. Usted es el único responsable de realizar copias de seguridad y mantener copias duplicadas de cualquier información que almacene o transfiera a través de la Plataforma",
      p35: "Usted reconoce que la información transmitida a través de Internet (incluidas las comunicaciones por correo electrónico) puede ser susceptible de acceso y control ilegales. Además, reconoce que la transmisión de datos o información a través de Internet puede provocar la pérdida, intercepción o alteración durante el tránsito y que toda esa información no se considerará confidencial, y la Compañía no tiene ninguna obligación fiduciaria con respecto a dicha información transmitida",
      t9: "VIII. RENUNCIA DE GARANTÍAS",
      p36: 'Los Servicios, la Plataforma, el Sitio y la Aplicación se le proporcionan en la mayor medida legalmente permitida "tal cual" y "según disponibilidad" sin garantía de ningún tipo, estatutaria, expresa o implícita, incluidas, entre otras, a, las garantías implícitas de título, no infracción, comerciabilidad e idoneidad para un propósito particular. Usted asume toda la responsabilidad y el riesgo en relación con Su uso de la Plataforma.',
      p37: "La Compañía no representa ni garantiza que la información, el software o los servicios contenidos o proporcionados por la Plataforma cumplan con las leyes aplicables de Su país",
      p38: "La Empresa no garantiza que la Plataforma y los Servicios que presta no puedan ser duplicados ilegítimamente (en parte o en su totalidad) por terceros sin el consentimiento previo por escrito de la Empresa.",
      p39: "Si la ley aplicable no permite que la exclusión de garantías o la exención de responsabilidad de los términos implícitos en los contratos, en su totalidad o en parte, se apliquen a usted, las limitaciones, exclusiones y exenciones de responsabilidad se aplicarán a usted solo en la medida en que lo permita la ley aplicable. ",
      t10: "IX. INDEMNIZACIÓN",
      p40: "Usted acepta indemnizarnos y defendernos, incluidos nuestros afiliados y sus directores, funcionarios y empleados, de y contra todas las pérdidas, costos, daños, gastos y responsabilidades (incluidos los honorarios y desembolsos razonables de abogados) que podamos sufrir que surjan de o como resultado de una reclamación relacionada con:",
      al9: "Su uso del Sitio;",
      al10: "Incumplimiento de estos Términos de uso por su parte;",
      al11: "Cualquier incumplimiento de sus representaciones y garantías establecidas en estos Términos de uso;",
      al12: "Su violación de los derechos de otros, incluidos, entre otros, los derechos de propiedad intelectual",
      p41: "No obstante lo anterior, nos reservamos el derecho, a su cargo, de asumir la defensa y el control exclusivos de cualquier asunto por el cual esté obligado a indemnizarnos, y usted acepta cooperar, a su cargo, con nuestra defensa de tal Haremos todos los esfuerzos razonables para notificarle cualquier reclamación, acción o procedimiento que esté sujeto a esta indemnización al tomar conocimiento de la misma.",
      t11: "X. VARIOS",
      p42: "Sin perjuicio de lo contenido en este documento, la Compañía se reserva el derecho, sin previo aviso y a su exclusivo criterio, de rescindir estos Términos, suspender Su derecho a acceder a la Plataforma o los Servicios y toda la información y los archivos relacionados sin responsabilidad hacia Usted, a su exclusivo discreción, incluso, entre otros, en caso de incumplimiento de estos Términos o si la Compañía cree que ha cometido fraude u otra mala conducta",
      p43: "WAKAME puede, a su sola discreción y sin responsabilidad hacia Usted, con o sin previo aviso, suspender Su acceso a la totalidad o parte de la Plataforma o los Servicios. Si la Compañía rescinde estos Términos, revocará Su derecho a usar o acceder a la Plataforma o Servicios, en la medida permitida por la Legislación Aplicable",
      p44: "Ninguna disposición de estos Términos se considerará renunciada a menos que dicha renuncia esté por escrito y firmada por la Parte que se beneficia de la aplicación de dicha disposición. Sin embargo, ninguna renuncia a ninguna disposición de estos Términos se considerará una renuncia de un incumplimiento de cualquier disposición de este tipo o una renuncia a una disposición similar. Además, una renuncia a cualquier incumplimiento o incumplimiento de cualquier término o condición de estos Términos de ninguna manera afectará, limitará o renunciará a los derechos de una Parte en virtud del presente en cualquier momento para imponer estrictos cumplimiento de todos los términos y condiciones del presente.",
      p45: "La Compañía puede, a su sola discreción, ceder cualquiera de sus derechos y/o delegar sus obligaciones bajo estos Términos (incluidos, entre otros, todos los derechos de propiedad intelectual en o para toda la tecnología, el software y el código relacionado a la Plataforma y los Servicios), sin notificárselo a Usted ni a ningún otro Usuario.",
      p46: "Si algún término, disposición, acuerdo o restricción de estos Términos es declarado inválido, ilegal, nulo o inaplicable por un tribunal de jurisdicción competente, el resto de los términos, disposiciones, acuerdos y restricciones establecidos en este documento permanecerán en pleno vigor y efecto y de ninguna manera se verá afectado, deteriorado o invalidado, y la Compañía hará todos los esfuerzos comercialmente razonables para encontrar y emplear medios alternativos para lograr el mismo o sustancialmente el mismo resultado contemplado por dicho término, disposición, convenio o restricción. intención de las Partes de haber hecho cumplir los restantes términos, disposiciones, convenios y restricciones sin incluir ninguno que pueda ser declarado inválido, ilegal, nulo o inaplicable",
      p47: "La Compañía no se hace responsable de las implicaciones fiscales que surgen de los NFT o su posesión o uso",
      p48: "Estos Términos y todos los asuntos que surjan o se relacionen con estos Términos se regirán e interpretarán de acuerdo con las leyes de las Islas Vírgenes Británicas, sin tener en cuenta las disposiciones sobre conflictos de leyes. Cada parte acepta irrevocablemente que cualquier reclamo presentado por el presente de cualquiera que surja de los Servicios se presentará única y exclusivamente en un tribunal estatal o federal ubicado en las Islas Vírgenes Británicas, y cada parte acepta irrevocablemente y se somete irrevocablemente a la jurisdicción única y exclusiva de cada uno de los tribunales anteriores personalmente, generalmente e incondicionalmente con respecto a cualquier acción, pleito o procedimiento iniciado por o contra ella por la otra parte.",
      p49: "Si no está seguro de si su uso de los Servicios implica o no un uso prohibido, o si tiene preguntas sobre la Plataforma, el Sitio o la Aplicación, comuníquese con nosotros a: contact@wakame.app",
      d: "julio de 2022",
      cancelTermsMessage: "¡Debes aceptar los términos para continuar!",
    },
    faq: {
      items: [
        {
          code: "what-is-nft",
          title: "¿Qué es NFT?",
          text: "El token no fungible (NFT) es un activo criptográfico en una cadena de bloques con un código de identificación y metadatos únicos. Recopile y cree su NFT en Wakame absolutamente gratis :)",
        },
        {
          code: "whats-the-difference-between-collect-and-mint",
          title: "¿Cuál es la diferencia entre COLLECT y MINT?",
          text: "COLLECT: recopila NFT. MINT: crea tu propio NFT",
        },
        {
          code: "how-to-collect-nft",
          title: "¿Cómo cobrar un NFT?",
          text: "Presione el botón verde \"Wakame\", escanee el código QR o ingrese un código de 8 dígitos. Después de que se abra el NFT, haga clic en el botón verde \"Recolectar NFT\" y recójalo",
        },
        {
          code: "how-to-mint-nft",
          title: "Cómo crear un NFT",
          text: "Presione el botón verde "+", complete toda la información requerida y presione el botón verde \"Mint\", ¡Es así de fácil! Su NFT ahora está disponible para compartirse a través de un enlace, código QR o código de 8 dígitos",
        },
        {
          code: "what-is-6-digit-code",
          title: "¿Cuál es el código de validación?",
          text: "El código de 6 dígitos se usa para validar su número de teléfono. El código de 8 dígitos se usa para compartir, mostrar y recopilar NFT",
        },
        {
          code: "how-to-delete-your-account",
          title: "¿Cómo eliminar tu cuenta?",
          text: "Para eliminar su cuenta, vaya a: Configuración > Perfil > Eliminar cuenta > Confirmar",
        },
      ],
    },
  },
  components: {
    save: "Ahorrar",
    cancel: "Cancelar",
  },
  alerts: {
    sendPhoneCodeError: "Demasiados intentos de inicio de sesión, vuelva a intentarlo en unos minutos",
    confirmPhoneCodeError: "Error al confirmar el código del teléfono",
    savePhoneError: "Error al guardar el número de teléfono",
    saveNameError: "Error al guardar el nombre",
    saveEmailError: "Error al guardar el correo electrónico",
    saveProfilePictureError: "Error al guardar la imagen de perfil",
    collectNftError: "Error al recopilar NFT",
    getNftInfoError: "Error al mostrar información de NFT",
    getNftsCollectedError: "Error al recopilar NFT",
    getNftsMintedError: "Error al crear NFT",
    mintKycIncompleteError: "Complete su registro para crear NFT",
    openPhoneCamera: "¡Por favor! Abre la cámara de tu teléfono para recoger tu NFT",
    nftCollected: "NFT recopilado",
    nftAlreadyCollected: "NFT ya recopilado",
    nftImageUploadError: "Error al cargar su imagen en el servidor",
    nftSaveError: "Error al guardar NFT",
    errorOnRequestGeolocation: "Error al solicitar permiso de geolocalización",
    permissionDenied: "¡Permiso denegado!",
    errorShare: "Error al compartir",
    nftNotFound: "NFT no encontrado",
    nftRemoved: "NFT eliminado",
    nftRemovedError: "Error al eliminar NFT",
    getUserPreferencesError: "Error al obtener las preferencias del usuario",
    saveShareLocationError: "Error al guardar la ubicación compartida",
    savePersonalInfoError: "Error al guardar la información personal compartida",
    saveNicknameError: "Error al guardar el nombre de usuario",
    nicknameExist: "El nombre de usuario ya existe, agrega otro",
    errorLocationRequired: "Tu ubicación necesita acceso, revisa los permisos",
    errorUploadImage: "Error al cargar la imagen",
    errorDeleteAccount: "Error al eliminar la cuenta",
  },
};
