import { Center, Image, Spacer, Spinner, Text, VStack } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../../assets/images";
import { auth } from "../../../firebase.config";
import Header from "../../../shared/components/Header";
import { translate } from "../../../shared/i18n/translate";

const CollectCode = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (code.length === 8) {
      checkData();
    }
  }, [code]);

  const checkData = async () => {
    if (code) {
      if (auth.currentUser?.uid) {
        navigate(`/nftpage/${code.toLowerCase()}`, {
          state: {
            collect: true,
          },
        });
      } else {
        navigate("/inputphone", {
          state: {
            from: "COLLECT",
            data: code.toLowerCase(),
          },
        });
      }
    }
  };

  return (
    <VStack
      h="100vh"
      justifyContent="space-between"
      alignItems="center"
      w="100vw"
    >
      <Header
        backAction={() => {
          navigate("/");
        }}
        title={translate.pages.collectPage.title}
      />
      <VStack flexGrow={1} justifyContent="flex-start" alignItems="center" space="50px" mt="50px">
        <Center w="100%" width={"100vw"} maxW="350px">
          <Image
            source={{ uri: Images.logoCircle }}
            w="100px"
            h="100px"
            alt=""
          />
        </Center>
        <Text bold fontSize="25px">
          { translate.pages.collectPage.enterNFTcode}
        </Text>
        <ReactCodeInput
          type="text"
          fields={8}
          name={""}
          inputMode="full-width-latin"
          value={code}
          onChange={(value) => {
            setCode(value);
          }}
          inputStyle={{
            fontSize: "20px",
            width: "20px",
            height: "30px",
            textAlign: "center",
            marginLeft: "5px",
            marginRight: "5px",
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "3px solid black",
            outline: "none",
          }}
        />
      </VStack>
    </VStack>
  );
};

export default CollectCode;
