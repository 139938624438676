import {
  VStack,
  Center,
  Pressable,
  HStack,
  Image,
} from "native-base";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import {
  islogged,
} from "../../shared/services/user.service";
import { useToast } from "native-base";
import { auth } from "../../firebase.config";
import Header from "../../shared/components/Header";
import { Carousel } from "react-responsive-carousel";
import HomeCollect from "./home-collect";
import HomeMint from "./home-mint";
import { translate } from "../../shared/i18n/translate";
import { CollectMintContext } from "../../contexts/collect-mint";
import { UserPreferencesContext } from "../../contexts/user-preferences";

const Home = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const toast = useToast();
  const { state } = useLocation();
  const carouselRef = useRef<any>();
  const collectMintContext = useContext(CollectMintContext);
  const userPreferencesContext = useContext(UserPreferencesContext);
  const [loadingPreferences, setLoadingPreferences] = useState<boolean>(false);

  useEffect(() => {
    if (collectMintContext.active) {
      switch (collectMintContext.active) {
        case "COLLECT":
          if (!carouselRef.current) return;
          setActiveIndex(0);
          carouselRef.current.changeItem(0)();
          break;
        case "MINT":
          if (!carouselRef.current) return;
          setActiveIndex(1);
          carouselRef.current.changeItem(1)();
          break;
      }
    }
  }, []);

  const collectOrMint = () => {
    if (activeIndex === 0) {
      navigate("collectcode");
    } else {
      if (islogged(auth.currentUser)) {
        if (
          userPreferencesContext.preferences === null ||
          userPreferencesContext.preferences?.acceptTerms
        ) {
          navigate("/mint");
        } else {
          navigate("/terms", {
            state: {
              from: "MINT",
            },
          });
        }
      } else {
        navigate("/inputphone", {
          state: {
            from: "MINT",
          },
        });
      }
    }
  };

  return (
    <VStack
      space={0}
      alignItems="center"
      justifyContent="flex-start"
      h="100vh"
      w="100vw"
    >
      <Header
        title={
          activeIndex === 0
            ? translate.pages.home.collect
            : translate.pages.home.mint
        }
        avatar={true}
        fixed={true}
      />
      <VStack w="100vw" h="90vh" alignItems="center">
        <Center minH="70px" w="10px" />
        <HStack space="7px">
          <Pressable
            onPress={() => {
              if (!carouselRef.current) return;
              setActiveIndex(0);
              carouselRef.current.changeItem(0)();
            }}
          >
            <Center
              w={4}
              h={4}
              borderRadius="100"
              backgroundColor={activeIndex === 0 ? "gray.900" : "gray.300"}
            />
          </Pressable>
          <Pressable
            onPress={() => {
              if (!carouselRef.current) return;
              setActiveIndex(1);
              carouselRef.current.changeItem(1)();
            }}
          >
            <Center
              w={4}
              h={4}
              borderRadius="100"
              backgroundColor={activeIndex === 1 ? "gray.900" : "gray.300"}
            />
          </Pressable>
        </HStack>
        <Center flexGrow={1} justifyContent="flex-start">
          <Carousel
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            showIndicators={false}
            showThumbs={false}
            ref={carouselRef}
            width="100vw"
            onChange={(index, item) => {
              setActiveIndex(index);
              if (index === 0) {
                collectMintContext.setActive("COLLECT");
              } else if (index === 1) {
                collectMintContext.setActive("MINT");
              }
            }}
          >
            {[<HomeCollect key="home-collect" />, <HomeMint key="home-mint" />]}
          </Carousel>
        </Center>
      </VStack>
      <Center w="100%" position="fixed" bottom="50px">
        <Pressable onPress={() => collectOrMint()}>
          {({ isHovered, isFocused, isPressed }) => {
            return (
              <Center rounded="100" shadow="3" bg="#57ee49" p="15px">
                {activeIndex === 0 ? (
                  <Image source={{ uri: Images.logo }} size="40px" alt="" />
                ) : (
                  <Image source={{ uri: Images.plus }} size="40px" alt="" />
                )}
              </Center>
            );
          }}
        </Pressable>
      </Center>
    </VStack>
  );
};

export default Home;
