import { onAuthStateChanged } from "firebase/auth";
import {
  Center,
  HStack,
  Image,
  Pressable,
  Spacer,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from "native-base";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images";
import { auth } from "../../firebase.config";
import Header from "../../shared/components/Header";
import SuppAndHelp from "../../shared/components/SuppAndHelp";
import { COOKIE_LANGUAGE_LABEL } from "../../shared/constants/cookie";
import { currentLanguage, translate } from "../../shared/i18n/translate";
import {
  askPermission,
  checkPermission,
} from "../../shared/services/permissions.service";
import {
  userPreferences,
  updatePreferences,
} from "../../shared/services/user.service";

const PrivacyPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [shareLocation, setShareLocation] = useState<boolean>();
  const [hasGeolocation, setHasGeolocation] = useState<boolean>();
  const [shareLocationLoading, setShareLocationLoading] =
    useState<boolean>(false);
  const [sharePersonalInfo, setSharePersonalInfo] = useState<boolean>(false);
  const [sharePersonalInfoLoading, setSharePersonalInfoLoading] =
    useState<boolean>(false);
  const [hasPersonalPermission, setHasPersonalPermission] =
    useState<boolean>(false);

  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        handlePermissions();
        getPreferences();
      }
    });
    return unsubscriber();
  }, []);

  useEffect(() => {
    if (hasGeolocation !== undefined && shareLocation !== undefined) {
      if (!hasGeolocation && shareLocation) {
        saveShareLocation(false);
        setShareLocation(false);
      }
    }
  }, [hasGeolocation, shareLocation]);

  const requestGeolocation = async () => {
    try {
      setShareLocationLoading(true);
      const result: any = await askPermission("geolocation");
      setHasGeolocation(result);
      setShareLocation(result);
      saveShareLocation(result);
      if (!result) {
        toast.show({ title: translate.alerts.permissionDenied });
      }
      setShareLocationLoading(false);
    } catch (error) {
      setShareLocationLoading(false);
      toast.show({ title: translate.alerts.errorOnRequestGeolocation });
    }
  };

  const getPreferences = async () => {
    try {
      setShareLocationLoading(true);
      setSharePersonalInfoLoading(true);
      const response = await userPreferences();
      setShareLocation(response.data.user.shareLocation);
      setSharePersonalInfo(response.data.user.sharePersonalInfo);
      setShareLocationLoading(false);
      setSharePersonalInfoLoading(false);
    } catch (error) {
      toast.show({ title: translate.alerts.getUserPreferencesError });
      setShareLocationLoading(false);
      setSharePersonalInfoLoading(false);
    }
  };

  const handlePermissions = async () => {
    try {
      const hasGeolocation: any = await checkPermission("geolocation");
      setHasGeolocation(hasGeolocation);
    } catch (error) {}
  };

  const saveShareLocation = async (value: boolean) => {
    setShareLocationLoading(true);
    try {
      await updatePreferences({ shareLocation: value });
      setShareLocationLoading(false);
    } catch (error) {
      toast.show({ title: translate.alerts.saveShareLocationError });
      setShareLocationLoading(false);
    }
  };

  const saveSharePersonalInfo = async (value: boolean) => {
    setSharePersonalInfoLoading(true);
    try {
      await updatePreferences({ sharePersonalInfo: value });
      setSharePersonalInfoLoading(false);
    } catch (error) {
      toast.show({ title: translate.alerts.savePersonalInfoError });
      setSharePersonalInfoLoading(false);
    }
  };

  return (
    <VStack
      h="100vh"
      maxH="700px"
      justifyContent="flex-start"
      alignItems="center"
      space="10px"
    >
      <Header backAction={() => navigate("/profile")} />

      <Spacer />
      <Spacer />

      {/* TITLE */}
      <Center>
        <Text bold fontSize="22px">
          {translate.pages.profile.privacy}
        </Text>
      </Center>

      <Spacer />

      {/* PRIVACY OPTIONS */}
      <HStack w="calc(100vw - 50px)" maxW="500px">
        <Text bold fontSize="20px">
          {translate.pages.profile.privacyOptions}
        </Text>
      </HStack>

      {/* SHARE LOCATION */}
      <VStack
        w="calc(100vw - 50px)"
        maxW="500px"
        background="white"
        borderColor="#D9D9D9"
        borderWidth="1px"
        borderRadius="12px"
        paddingY="10px"
        paddingX="15px"
        space="8px"
      >
        <HStack w="100%" alignItems="center" space="20px">
          <VStack w="calc(100% - 70px)">
            <Text fontSize="16px">
              {translate.pages.settings.shareLocalization}
            </Text>
            {/* <Text opacity="0.5" lineHeight="15px" fontSize="13px">
              {translate.pages.settings.shareLocalizationDesc}
            </Text> */}
          </VStack>
          <Center flexGrow="1">
            {shareLocationLoading ? (
              <Spinner />
            ) : (
              <Switch
                size="lg"
                offTrackColor="#e9e9e9"
                onTrackColor="#4CD964"
                onThumbColor="#fafafa"
                offThumbColor="#fafafa"
                isChecked={shareLocation}
                onToggle={(value) => {
                  setShareLocation(value);
                  if (value) {
                    requestGeolocation();
                  } else {
                    saveShareLocation(value);
                  }
                }}
              />
            )}
          </Center>
        </HStack>
      </VStack>

      {/* SHARE PERSONAL INFO */}
      <VStack
        w="calc(100vw - 50px)"
        maxW="500px"
        background="white"
        borderColor="#D9D9D9"
        borderWidth="1px"
        borderRadius="12px"
        paddingY="10px"
        paddingX="15px"
        space="8px"
      >
        <HStack w="100%" alignItems="center" space="20px">
          <VStack w="calc(100% - 70px)">
            <Text fontSize="16px">
              {translate.pages.settings.sharePersonalInfo}
            </Text>
            {/* <Text opacity="0.5" lineHeight="15px" fontSize="13px">
              {translate.pages.settings.sharePersonalInfoDesc}
            </Text> */}
          </VStack>
          <Center flexGrow="1">
            {sharePersonalInfoLoading ? (
              <Spinner />
            ) : (
              <Switch
                size="lg"
                offTrackColor="#e9e9e9"
                onTrackColor="#4CD964"
                onThumbColor="#fafafa"
                offThumbColor="#fafafa"
                isChecked={sharePersonalInfo}
                onToggle={(value) => {
                  setSharePersonalInfo(value);
                  saveSharePersonalInfo(value);
                }}
              />
            )}
          </Center>
        </HStack>
      </VStack>

      <Spacer />

      {/* PRIVACY POLICY */}
      <Pressable onPress={() => window.open("/privacy", "_blank")}>
        <HStack
          w="calc(100vw - 50px)"
          maxW="500px"
          background="white"
          borderColor="#D9D9D9"
          borderWidth="1px"
          borderRadius="12px"
          paddingY="10px"
          paddingX="15px"
          space="8px"
        >
          <Image source={{ uri: Images.privacy }} size="20px" />
          <Text>{translate.pages.profile.privacyPolicy}</Text>
        </HStack>
      </Pressable>

      <Spacer />
      <Spacer />
      <Spacer />
    </VStack>
  );
};

export default PrivacyPage;
